// Chakra imports
import {
  Flex,
  useColorModeValue,
  useToast,
  Text,
  Table,
  Thead,
  Tbody,
  Td,
  Tr,
  Button,
  Stack,
  Center,
  Box,
  Spacer,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { CheckIcon, InfoIcon, QuestionIcon } from '@chakra-ui/icons';
// Custom components
import Card from "components/card/Card.js";
import React, {useState, useEffect} from "react";
import axios from "axios";
import { decryptData, URL_WEB_BACKEND } from "CustomUtil";

export default function JumnosukelsTable({refreshing}) {
  const authdata = decryptData(localStorage.getItem("authinfositu"));
  const cekadmin = decryptData(localStorage.getItem("authrolesitu"));
  const [nosukelData, setNosukelData] = useState([]);
  const [idSelectedData, setIdSelectedData] = useState(0);
  const [kode, setKode] = useState('');
  const [keterangan, setKeterangan] = useState('');
  const [total, setTotal] = useState('');
  const [titleDialog, setTitleDialog] = useState('title');
  const [descDialog, setDescDialog] = useState('desc');
  const [statusInfoDialog, setStatusInfoDialog] = useState(false);
  const [infoDialogIcon, setInfoDialogIcon] = useState(1);
  const [statusConfExeDialog, setStatusConfExeDialog] = useState(false);
  const [actionConfExeDialog, setActionConfExeDialog] = useState(1);
  const [statusEditDialog, setStatusEditDialog] = useState(false);
  const filterNosukelData = nosukelData.filter(nosukel => nosukel.id !== 0);
  const toast = useToast();

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  useEffect(() => {
    if (refreshing || nosukelData.length < 1 || nosukelData === undefined) {
      getNosukel();
    }
  },[refreshing]);

  const getNosukel = async () => {
    await axios.get(URL_WEB_BACKEND+'/jumnosukels', {
      headers: {
        'Authorization': 'Bearer '+authdata
      }
    }).then((response) => {
      setNosukelData(response.data);
    }).catch(err => {
      toast({
        status: 'error',
        title: 'Error status '+err.response.status,
        description: 'Data nomor kosong.',
        position: 'bottom-right',
        isClosable: true,
      })
    });
  }

  const getNosukelwithSearch = async (searchClue) => {
    if(searchClue === ''){
      getNosukel();
    }else{
      setNosukelData([]);
    await axios.get(URL_WEB_BACKEND+'/cari/jumnosukels/kode/'+searchClue, {
      headers: {
        'Authorization': 'Bearer '+authdata
      }
    }).then((response) => {
      setNosukelData(response.data);
    }).catch(err => {
      toast({
        status: 'error',
        title: 'Error status '+err.response.status,
        description: 'Tak ada data yang dicari.',
        position: 'bottom-right',
        isClosable: true,
      })
    });
    }
  }

  const exeEdit = async () =>{
    setStatusEditDialog(false);setStatusConfExeDialog(false);
    if(cekadmin === 'admin'){
      await axios.put(URL_WEB_BACKEND+'/jumnosukels/'+idSelectedData,{
        kode: kode.toUpperCase(),
        keterangan: keterangan.toUpperCase(),
        total: total
      }, {
        headers: {
          'Authorization': 'Bearer '+authdata
        }}).then(res => {
          if(res.status===200){
            getNosukel();
            showInfoDialog('Berhasil', 'Data Berhasil Diperbarui', 0)
          }else{
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error Perbarui dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error Perbarui dengan status '+err.response.status, err.response.data.messages[errkey], 1)
        })
    }else{
      showInfoDialog('Error Pembaruan','Anda tidak memiliki hak akses untuk ini.', 1)
    }
  }

  const exeDelete = async () =>{
    setStatusConfExeDialog(false);
    if(cekadmin === 'admin'){
      await axios.delete(URL_WEB_BACKEND+'/jumnosukels/'+idSelectedData, {
        headers: {
          'Authorization': 'Bearer '+authdata
        }}).then(res => {
          if(res.status===200){
            getNosukel();
            showInfoDialog('Berhasil', 'Data Berhasil Dihapus', 0)
          }else{
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error Hapus dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error Hapus dengan status '+err.response.status, err.response.data.messages[errkey], 1)
        })
    }else{
      showInfoDialog('Error Hapus','Anda tidak memiliki hak akses untuk ini.', 1)
    }
  }

  function showInfoDialog (title, desc, infoicon) {
    setTitleDialog(title);setDescDialog(desc);setInfoDialogIcon(infoicon);setStatusInfoDialog(true);
  }

  function showConfExeDialog (title, desc, action) {
    setTitleDialog(title);setDescDialog(desc);setStatusConfExeDialog(true);setActionConfExeDialog(action);
  }

  function showEditDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusEditDialog(true);
  }

  const viewInfoDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusInfoDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center>
                {infoDialogIcon
                  ? <InfoIcon w={16} h={16} color="blue.500" />
                  : <CheckIcon w={16} h={16} color="green.500" />
                }
              </Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
              <Button onClick = {() => {setStatusInfoDialog(false)}}>OK</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewConfExeDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusConfExeDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center><QuestionIcon w={16} h={16} color="blue.500" /></Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='red' mr={3} onClick = {() => {{
                  if(actionConfExeDialog===1){
                    exeEdit();
                  }else{
                    exeDelete();
                  }
                  }}}>
                    Iya, Lanjutkan
                </Button>
              <Button onClick = {() => {setStatusConfExeDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewEditDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusEditDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                textAlign='start'
                fontSize='2xl'
                mt={{ base: "5px", "2xl": "5px" }}>
                {titleDialog}
              </Text>
              <Text
                color={textColorSecondary}
                fontSize='md'
                my={{ base: "auto", "2xl": "10px" }}
                textAlign='start'>
                {descDialog}
              </Text>  
            </ModalHeader>
            <ModalBody>
              <FormControl id={"nosukel"+idSelectedData} isRequired>
                <FormLabel>Kode</FormLabel>
                <Input id={"nosukel"+idSelectedData+1} placeholder="Masukkan kode nomor surat" borderRadius="16px" value={kode} onChange={ (e) => setKode(e.target.value) } />
                <FormLabel>Keterangan</FormLabel>
                <Input id={"nosukel"+idSelectedData+2} placeholder="Masukkan keterangan" borderRadius="16px" value={keterangan} onChange={ (e) => setKeterangan(e.target.value) } />
                <FormLabel>Total</FormLabel>
                <Input type='number' id={"nosukel"+idSelectedData+3} placeholder="Masukkan total nomor terpakai" borderRadius="16px" value={total} onChange={ (e) => setTotal(e.target.value) } />
              </FormControl>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick = {() => {{if(kode === '' || keterangan === '' || total === ''){toast({
                    status: 'error',
                    title: 'Proses Gagal',
                    description: 'Masih ada kolom yang kosong',
                    position: 'bottom-right',
                    isClosable: true,
                    })}else{showConfExeDialog('Edit Nomor Surat','Simpan pembaruan nomor surat ini?',1)}}}}>
                    Simpan
                </Button>
              <Button onClick = {() => {setStatusEditDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  return (
    <Card align='center' p='20px'>
      <Flex h='100%' direction={{ base: "column", "2xl": "row" }}>
        <Flex direction='column' pe='44px'>
        {viewEditDialog()}{viewInfoDialog()}{viewConfExeDialog()}
        <Text
            color={textColorPrimary}
            fontWeight='bold'
            textAlign='start'
            fontSize='2xl'
            mt={{ base: "5px", "2xl": "5px" }}>
            Total Kode Nomor Surat Terpakai
          </Text>
          <Text
            color={textColorSecondary}
            fontSize='md'
            my={{ base: "auto", "2xl": "10px" }}
            textAlign='start'>
            Kelola total pemakaian setiap kode nomor surat keluar.
          </Text>
          <Table variant='unstyled'>
            <Thead><Tr>
              <Td><Input variant='filled' id={"search"+111} placeholder="Cari berdasar kode" borderRadius="16px" onChange={ (e) => getNosukelwithSearch(e.target.value) } /></Td>
            </Tr></Thead>
            <Box ms='-6' me='-16' overflowX="auto" maxH='300'>
              <Tbody>
                {filterNosukelData.length<1
                ? <Tr><Td><Center mb={6}>Belum ada daftar kode nomor surat keluar.</Center></Td></Tr>
                : <>{filterNosukelData.map((datanosukel, index) => (
                  <Tr key={index+1}>
                      <Td>
                        <Stack me='0' direction="row" p="2" boxShadow="lg" borderRadius="lg">
                          <Stack direction="row" >
                            <Stack direction="column">
                              <Text fontSize={{base: 'xl'}} fontWeight="bold">{datanosukel.kode}</Text>
                              <Text mt={4} ms={8} fontSize={{ base: 'sm' }} textAlign={'start'} maxW={'4xl'}>
                                {datanosukel.keterangan}
                              </Text>
                              <Text mt={4} fontSize={14} as='b'>
                                Total Terpakai : {datanosukel.total}
                              </Text>
                            </Stack>
                          </Stack>
                          <Spacer />
                          <Stack direction={{ base: 'column', md: 'row' }}>
                            <Button variant="outline" size='sm' colorScheme="red" onClick={()=>{
                              setIdSelectedData(datanosukel.id);
                              setTimeout(() => {
                                showConfExeDialog('Hapus Kode Nomor Surat','Yakin ingin menghapus kode '+datanosukel.kode+' ?',2)
                              }, 200);
                              }}>HAPUS</Button>
                            <Button colorScheme="blue" size='sm' onClick={()=>{
                              setIdSelectedData(datanosukel.id);setKode(datanosukel.kode);setKeterangan(datanosukel.keterangan);setTotal(datanosukel.total);
                              setTimeout(() => {
                                showEditDialog('Edit Kode Nomor Surat','Perbarui kode nomor agar sesuai.')
                              }, 200);
                              }}>EDIT</Button>
                          </Stack>
                        </Stack>
                      </Td>
                  </Tr>
                ))}</>
                }
              </Tbody>
            </Box>
          </Table>
        </Flex>
      </Flex>
    </Card>
  );
}
