import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  Stack,
  Center,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { convertDateTime, decryptData, URL_WEB_BACKEND } from "CustomUtil";
import axios from "axios";

function Informations() {
  const authdata = decryptData(localStorage.getItem("authinfositu"));
  const [infoData, setInfoData] = useState([]);
  const toast = useToast();

  useEffect(() => {
    if (infoData.length < 1 || infoData === undefined) {
      getInfos();
    }
  },[]);

  const getInfos = async () => {
    await axios.get(URL_WEB_BACKEND+'/infos', {
      headers: {
        'Authorization': 'Bearer '+authdata
      }
    }).then((response) => {
      setInfoData(response.data.filter(info => info.id != 0));
    }).catch(err => {
      toast({
        status: 'error',
        title: 'Error status '+err.response.status,
        description: 'Data informasi kosong.',
        position: 'bottom-right',
        isClosable: true,
      })
    });
  }

  return (
    <>
      <Flex
        direction='column'
        w='100%'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex
          align={{ sm: "flex-start", lg: "center" }}
          justify='space-between'
          w='100%'
          px='22px'
          pb='20px'
          mb='10px'
          boxShadow='0px 40px 58px -20px rgba(112, 144, 176, 0.26)'>
        </Flex>
        <Table>
          <Tbody>
          {infoData.length<1
            ? <Tr><Td><Center mb={6}>Belum ada daftar informasi.</Center></Td></Tr>
            : <>{infoData.map((datainfo, index) => (
              <Tr key={index+1}>
                  <Td>
                    <Stack direction="row" p="4" boxShadow="lg" m="2" borderRadius="lg">
                      <Stack direction="row" >
                        <Stack direction="column">
                          <Text fontSize={{base: 'xl'}} fontWeight="bold">{datainfo.judul}</Text>
                          <Text mt={4} ms={8} fontSize={{ base: 'md' }} textAlign={'start'} maxW={'4xl'}>
                            {datainfo.isi}
                          </Text>
                          <Text mt={4} fontSize={14} as='b'>
                            {convertDateTime(datainfo.tanggal)}
                          </Text>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Td>
              </Tr>
            ))}</>
            }
          </Tbody>
        </Table>
      </Flex>
    </>
  );
}

export default Informations;
