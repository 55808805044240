/* eslint-disable */
import {
  Text,
  useColorModeValue,
  Stack,
  Box,
  Select,
  Table,
  Tbody,
  Thead,
  TableCaption,
  Td,
  Tr,
  Th,
  Center,
  Button,
  Grid,
  Spinner
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { DownloadIcon, ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import React, { useEffect, useState } from "react";
import { convertDateTime, URL_WEB_BACKEND, decryptData } from "CustomUtil";
import axios from "axios";
import { saveAs } from "file-saver";

export default function TxDetailNonaktif({refreshing, detail}) {
  const authdata = decryptData(localStorage.getItem("authinfositu"));

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const bgGradient = useColorModeValue("linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(217,217,217,1) 23%, rgba(191,186,208,1) 100%)", "linear-gradient(90deg, rgba(33,33,33,1) 0%, rgba(50,49,49,1) 23%, rgba(89,84,106,1) 100%)");

  const [idDetail, setIdDetail] = useState(0);
  const [showTxDetail, setShowTxDetail] = useState([]);

  const [searchJenisTx, setSearchJenisTx] = useState('semua');

  // pagination
  const [isPagination, setIsPagination] = useState(true);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [allPageNumber, setAllPageNumber] = useState(0);

  const [statusLoading, setStatusLoading] = useState(false);
  
  const jenisTxOPtSearch = [
    { value: 'semua', label: 'Semua Jenis Transaksi' },
    { value: 'pemanfaatan', label: 'Pemanfaatan' },
    { value: 'penambahan', label: 'Penambahan' },
    { value: 'transfer_internal', label: 'Transfer Internal' },
    { value: 'transfer_eksternal', label: 'Transfer Eksternal' },
    { value: 'penonaktifan', label: 'Penonaktifan' },
  ];

  useEffect(() => {
    if(!refreshing){
      setIdDetail(detail.id);
      getTxData(detail.id, currentPageNumber);
    }
  },[detail]);

  useEffect(() => {
    getTxData(detail.id, currentPageNumber);
  },[searchJenisTx]);

  const getTxData = async (id, pagesNumber) =>{
    setStatusLoading(true);
    if(searchJenisTx==='semua'){
      await axios.get(URL_WEB_BACKEND+'/getalltxbmns/'+id+'?page='+pagesNumber, {
        headers: {
          'Authorization': 'Bearer '+authdata,
          'content-type': 'application/ x-www-form-urlencoded'
        }}).then(res => {
          setStatusLoading(false);
          if(res.status===200){
            setShowTxDetail(res.data.txbmns);
            setAllPageNumber(res.data.pages);
            setIsPagination(true);
          }else{
            setIsPagination(false);
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error mendapatkan data transaksi bmn dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          setIsPagination(false);setStatusLoading(false);
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error mendapatkan data transaksi bmn dengan status '+err.response.status, err.response.data.messages[errkey], 1)
      })
    }else{
      await axios.get(URL_WEB_BACKEND+'/cari/txbmns/'+idDetail+'/'+searchJenisTx+'?page='+pagesNumber, {
        headers: {
          'Authorization': 'Bearer '+authdata,
          'content-type': 'application/ x-www-form-urlencoded'
        }}).then(res => {
          setStatusLoading(false);
          if(res.status===200){
            setShowTxDetail(res.data.txbmns);
            setAllPageNumber(res.data.pages);
            setIsPagination(true);
          }else{
            setIsPagination(false);
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error mendapatkan data transaksi bmn dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          setIsPagination(false);setStatusLoading(false);
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error mendapatkan data transaksi bmn dengan status '+err.response.status, err.response.data.messages[errkey], 1)
      })
    }
  }

  return (
    <Card
      direction='column'
      w='100%'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Box borderRadius="xl" boxShadow="lg" p="20px" bg={bgGradient}>
        <Stack direction='row'>  
          <Text
            color={textColorPrimary}
            fontSize='2xl'
            fontWeight='700'
            lineHeight='100%'>
            Riwayat Transaksi
          </Text>
        </Stack>
      </Box>
      <Box mt='10px' w='100%'>
      <Stack direction='row'>
        <Text
          as='b'
          ms='10px'
          me='15px'
          alignSelf='center'
          fontSize='md'>
           FILTER
        </Text>
        <Select id={"search"+idDetail+123} aria-label="Default" value={ searchJenisTx } onChange={ (e) => setSearchJenisTx(e.target.value) }>
          {jenisTxOPtSearch.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
        </Select>
      </Stack>
      {showTxDetail.length<1?<Center mb={6}>{statusLoading?<Spinner thickness='4px' speed='0.95s' emptyColor='gray.200' color='blue.500' size='xl'/>:<>Belum ada data transaksi.</>}</Center>:
      <Table variant="striped" colorScheme="gray" >
        <TableCaption>
                {isPagination&&allPageNumber>=1?
                  <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                    {currentPageNumber===1?<Button disabled><ArrowBackIcon me='2'/>Sebelum</Button>:<Button onClick={()=>{setCurrentPageNumber(currentPageNumber - 1);getTxData(detail.id, currentPageNumber - 1);}}><ArrowBackIcon me='2'/>Sebelum</Button>}
                    <Button>{currentPageNumber}</Button>
                    {currentPageNumber===allPageNumber?<Button disabled>Lanjut<ArrowForwardIcon ms='2'/></Button>:<Button onClick={()=>{setCurrentPageNumber(currentPageNumber + 1);getTxData(detail.id, currentPageNumber + 1);}}>Lanjut<ArrowForwardIcon ms='2'/></Button>}
                  </Grid>:<Text as='b'>akhir dari tabel.</Text>
                }
        </TableCaption>
        <Thead>
          <Tr>
            <Th>Jenis Transaksi</Th>
            <Th>Tanggal</Th>
            <Th>Pengoleh</Th>
            <Th>Penerima</Th>
            <Th isNumeric>Jml Sebelum</Th>
            <Th isNumeric>Jml Transaksi</Th>
            <Th isNumeric>Jml Sesudah</Th>
            <Th>Kondisi</Th>
            <Th>Keterangan</Th>
            <Th>File Administratif</Th>
          </Tr>
        </Thead>
        <Tbody>
        {showTxDetail.map((datatx, index) => (
          <Tr key={index+1}>
              <Td>{datatx.jenis}</Td>
              <Td>{convertDateTime(datatx.tanggal)}</Td>
              <Td>{datatx.user_fullname_sebelum}</Td>
              <Td>{datatx.user_fullname_sesudah}</Td>
              <Td isNumeric>{datatx.jumlah_sebelum}</Td>
              <Td isNumeric>{datatx.jumlah_sebelum>datatx.jumlah_sesudah?<> -</>:<>{datatx.jumlah_sebelum===datatx.jumlah_sesudah?<></>:<> +</>}</>}{datatx.jumlah}</Td>
              <Td isNumeric><Text as='b'>{datatx.jumlah_sesudah}</Text></Td>
              <Td>{datatx.kondisi}</Td>
              <Td>{datatx.keterangan}</Td>
              <Td><Button onClick={()=>saveAs(URL_WEB_BACKEND+"/uploads/txbmns/"+detail.verifid+'/'+datatx.namafile, datatx.namafile)}><DownloadIcon/> LIHAT DISINI</Button></Td>
            </Tr>
        ))}
        </Tbody>
        </Table>}
      </Box>
    </Card>
  );
}
