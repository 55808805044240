/* eslint-disable */
import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  Center,
  Stack,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Grid,
  Box,
  Spinner,
  Image,
  Badge,
  Select,
  Textarea
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { HSeparator } from "components/separator/Separator";
import { CheckIcon, InfoIcon, QuestionIcon, ArrowForwardIcon, DownloadIcon } from '@chakra-ui/icons';
import React, { useEffect, useState, useRef } from "react";
import QRCode from "react-qr-code";
import html2canvas from 'html2canvas';
import { convertDate, URL_WEB_BACKEND, decryptData } from "CustomUtil";
import axios from "axios";
import { URL_WEB_FORNTEND } from "CustomUtil";
import { saveAs } from "file-saver";

export default function Detail({onRefresh, refreshing, detail}) {
  const authdata = decryptData(localStorage.getItem("authinfositu"));

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  const textColor = useColorModeValue("secondaryGray.900", "white");

  const [imageHashToRefresh, setImageHashToRefresh] = useState(new Date().getTime());

  const [showDetail, setShowDetail] = useState([]);

  const [titleDialog, setTitleDialog] = useState('title');
  const [descDialog, setDescDialog] = useState('desc');
  const [statusInfoDialog, setStatusInfoDialog] = useState(false);
  const [infoDialogIcon, setInfoDialogIcon] = useState(1);
  const [statusConfExeDialog, setStatusConfExeDialog] = useState(false);
  const [statusEditDialog, setStatusEditDialog] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [statusUploading, setStatusUploading] = useState(false);
  const qrCodeRef = useRef();
  const toast = useToast();

  const [noreg, setNoreg] = useState('');
  const [nama, setNama] = useState('');
  const [satuan, setSatuan] = useState('');
  const [kondisi, setKondisi] = useState('baik');
  const [sumber, setSumber] = useState('');
  const [keterangan, setKeterangan] = useState('');
  const [filenya, setFilenya] = useState(null);
  const [fotonya, setFotonya] = useState(null);

  useEffect(() => {
    setStatusLoading(true);
    if(!refreshing){
      setStatusLoading(false);
      setShowDetail(detail);
      setNoreg(detail.noreg);setNama(detail.nama);setSatuan(detail.satuan);setKondisi(detail.kondisi);setSumber(detail.sumber);setKeterangan(detail.keterangan);
    }
  },[detail]);

  useEffect(() => {
    if(refreshing){
      refreshData();
    }
  },[refreshing]);

  const kondisiOPt = [
    { value: 'baik', label: 'Baik' },
    { value: 'rusak ringan', label: 'Rusak Ringan' },
    { value: 'rusak berat', label: 'Rusak Berat' },
  ];

  const refreshData = async () =>{
    setStatusLoading(true);
    await axios.get(URL_WEB_BACKEND+'/bmns/'+showDetail.id, {
      headers: {
        'Authorization': 'Bearer '+authdata,
        'content-type': 'application/ x-www-form-urlencoded'
      }}).then(res => {
        if(res.status===200){
          setShowDetail(res.data);
          setNoreg(res.data.noreg);setNama(res.data.nama);setSatuan(res.data.satuan);setKondisi(res.data.kondisi);setSumber(res.data.sumber);setKeterangan(res.data.keterangan);
          setStatusLoading(false);
        }else{
          setStatusLoading(true);
          let reskey = Object.keys(res.response.data.messages)[0];
          showInfoDialog('Error mendapatkan data bmn dengan status '+res.response.status, res.response.messages[reskey], 1)
        }
      }).catch(err => {
        setStatusLoading(true);
        let errkey = Object.keys(err.response.data.messages)[0];
        showInfoDialog('Error mendapatkan data bmn dengan status '+err.response.status, err.response.data.messages[errkey], 1)
      })
}

  const refreshDataAfterEdit = async () =>{
      setStatusLoading(true);
      await axios.get(URL_WEB_BACKEND+'/bmns/'+showDetail.id, {
        headers: {
          'Authorization': 'Bearer '+authdata,
          'content-type': 'application/ x-www-form-urlencoded'
        }}).then(res => {
          if(res.status===200){
            setFotonya(null);setFilenya(null);onRefresh();setImageHashToRefresh(new Date().getTime());
            setTimeout(() => {
              setShowDetail(res.data);
              setNoreg(res.data.noreg);setNama(res.data.nama);setSatuan(res.data.satuan);setKondisi(res.data.kondisi);setSumber(res.data.sumber);setKeterangan(res.data.keterangan);
              setStatusLoading(false);
            }, 100);
          }else{
            setStatusLoading(true);
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error mendapatkan data bmn dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          setStatusLoading(true);
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error mendapatkan data bmn dengan status '+err.response.status, err.response.data.messages[errkey], 1)
        })
  }

  const exeEdit = async () =>{
    setStatusUploading(true);setStatusEditDialog(false);setStatusConfExeDialog(false);
    const formDataEdit = new FormData();
    formDataEdit.append('noreg', noreg)
    formDataEdit.append('nama', nama)
    formDataEdit.append('tanggal', showDetail.tanggal)
    formDataEdit.append('jumlah', showDetail.jumlah)
    formDataEdit.append('satuan', satuan)
    formDataEdit.append('jenis', showDetail.jenis)
    formDataEdit.append('tersedia', parseInt(showDetail.tersedia))
    formDataEdit.append('kondisi', kondisi)
    formDataEdit.append('sumber', sumber)
    formDataEdit.append('in_id_user', parseInt(showDetail.in_id_user))
    formDataEdit.append('keterangan', keterangan)
    formDataEdit.append('verifid', showDetail.verifid)
    if(filenya!=null){
      formDataEdit.append('filenya', filenya)
    }
    if(fotonya!=null){
      formDataEdit.append('fotonya', fotonya)
    }
      await axios.post(URL_WEB_BACKEND+'/updatebmns/'+showDetail.id, formDataEdit, {
        headers: {
          'Authorization': 'Bearer '+authdata,
          'content-type': 'application/ x-www-form-urlencoded'
        }}).then(res => {
          if(res.status===200){
            setStatusUploading(false);
            refreshDataAfterEdit();
            showInfoDialog('Edit Data BMN','Data Berhasil Diperbarui.', 0);
          }else{
            setStatusUploading(false);
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error Edit Data BMN dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          setStatusUploading(false);
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error Edit Data BMN dengan status '+err.response.status, err.response.data.messages[errkey], 1)
        })
  }

  const downloadQRCode = async (namafile) => {
    const element = qrCodeRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/jpg');
    const link = document.createElement('a');
    if (typeof link.download === 'string') {
      link.href = data;
      link.download = namafile+'.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  function showInfoDialog (title, desc, infoicon) {
    setTitleDialog(title);setDescDialog(desc);setInfoDialogIcon(infoicon);setStatusInfoDialog(true);
  }

  function showConfExeDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusConfExeDialog(true);
  }

  function showEditDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusEditDialog(true);
  }

  const viewInfoDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusInfoDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center>
                {infoDialogIcon
                  ? <InfoIcon w={16} h={16} color="blue.500" />
                  : <CheckIcon w={16} h={16} color="green.500" />
                }
              </Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
              <Button onClick = {() => {setStatusInfoDialog(false)}}>OK</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewConfExeDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusConfExeDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center><QuestionIcon w={16} h={16} color="blue.500" /></Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='red' mr={3} onClick = {() => exeEdit()}>
                    Iya, Lanjutkan
                </Button>
              <Button onClick = {() => {setStatusConfExeDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewEditDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusEditDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                textAlign='start'
                fontSize='2xl'
                mt={{ base: "5px", "2xl": "5px" }}>
                {titleDialog}
              </Text>
              <Text
                color={textColorSecondary}
                fontSize='md'
                my={{ base: "auto", "2xl": "10px" }}
                textAlign='start'>
                {descDialog}
              </Text>  
            </ModalHeader>
            <ModalBody>
              <FormControl id={"editbmn"+showDetail.id} isRequired>
              <FormLabel>Nama Barang</FormLabel>
                      <Input id={"editbmn"+showDetail.id+3} placeholder="Masukkan nama barang" borderRadius="16px" value={nama} onChange={ (e) => setNama(e.target.value) } />
                      <FormLabel>No. Urut Pendaftaran</FormLabel>
                      <Input id={"editbmn"+showDetail.id+2} placeholder="Masukkan no. registrasi" borderRadius="16px" value={noreg} onChange={ (e) => setNoreg(e.target.value) } />
                      <FormLabel>Jumlah</FormLabel>
                      <Input type='number' id={"editbmn"+showDetail.id+4} placeholder="Masukkan jumlah barang" borderRadius="16px" value={showDetail.jumlah} disabled/>
                      <FormLabel>Satuan</FormLabel>
                      <Input id={"editbmn"+showDetail.id+5}  placeholder="Masukkan satuan perhitungan" borderRadius="16px" value={satuan} onChange={ (e) => setSatuan(e.target.value) } />
                      <FormLabel>Kondisi</FormLabel>
                      <Select id={"editbmn"+showDetail.id+7} aria-label="Default" value={ kondisi } onChange={ (e) => setKondisi(e.target.value) }>
                        {kondisiOPt.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
                      </Select>
                      <FormLabel>Asal Perolehan</FormLabel>
                      <Input id={"editbmn"+showDetail.id+8} placeholder="Masukkan asal aset" borderRadius="16px" value={sumber} onChange={ (e) => setSumber(e.target.value) } />
                      <FormLabel>Keterangan Tambahan</FormLabel>
                      <Textarea minH='80px' id={"editbmn"+showDetail.id+11} placeholder="Masukkan keterangan jika perlu" borderRadius="16px" value={keterangan} onChange={ (e) => setKeterangan(e.target.value) } />
                      <HSeparator my='5'/>
                      <FormControl id={"add"+showDetail.id+9}>
                        <FormLabel>Ganti Foto (Opsional)</FormLabel>
                        <Input variant='main' h='70px' border='2px dashed' type='file' id={"uploadfoto"+showDetail.id+10} placeholder="Pilih foto" borderRadius="16px" onChange={ (e) => setFotonya(e.target.files[0]) } />
                        <FormLabel>Ganti File Administratif (Opsional)</FormLabel>
                        <Input variant='main' h='70px' border='2px dashed' type='file' id={"uploadfile"+showDetail.id+11} placeholder="Pilih file" borderRadius="16px" onChange={ (e) => setFilenya(e.target.files[0]) } />
                    </FormControl>
              </FormControl>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick = {() => {{
                  if(noreg===''||nama===''||noreg===''||satuan===''||kondisi===''||sumber===''){toast({
                    status: 'error',
                    title: 'Proses Gagal',
                    description: 'Masih ada kolom yang kosong',
                    position: 'bottom-right',
                    isClosable: true,
                    })}else{showConfExeDialog('Edit Data BMN','Simpan perubahan?')}}}}>
                    Simpan
                </Button>
              <Button onClick = {() => {setStatusEditDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewUploadingDialog = () => {
    return (
      <Modal closeOnOverlayClick={false} isCentered isOpen={statusUploading}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <Center pb={5} pt={5}>
              <Spinner
                thickness='4px'
                speed='0.95s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
            </Center>
            <Center pb={5} pt={5}>
            <Text>Mengunggah...</Text>
            </Center>
          </ModalContent>
      </Modal>
    )
  }

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' align='center'>
      {viewInfoDialog()}{viewConfExeDialog()}{viewEditDialog()}{viewUploadingDialog()}
      <Text
        color={textColor}
        fontSize='2xl'
        fontWeight='700'
        lineHeight='100%'>
          Detail Barang <ArrowForwardIcon ms='1'/> {showDetail.nama}
        {showDetail.tersedia==='1'?<Badge ms='2' textAlign='start' fontSize="0.7em" colorScheme="green">Aktif</Badge>:<Badge ms='2' textAlign='start' fontSize="0.7em" colorScheme="red">Non-aktif</Badge>}
        {showDetail.jenis==='tetap'?<Badge ms='2' textAlign='start' fontSize="0.7em" colorScheme="purple">Aset Tetap</Badge>:<Badge ms='2' textAlign='start' fontSize="0.7em" variant="outline" colorScheme="purple">Persediaan</Badge>}
      </Text>
      <Stack direction='row'>
      <Button
        variant='action'
        onClick={()=>showEditDialog('Edit Detail Aset BMN','Perbarui data aset agar sesuai.')}>
        <Text
          fontSize='lg'
          as='b'>
            Edit Detail
        </Text>
      </Button>
      </Stack>
      </Flex>
      {statusLoading.length?<Center mb={6}><Spinner thickness='4px' speed='0.95s' emptyColor='gray.200' color='blue.500' size='xl'/></Center>:
      <Box mt='10px' px='25px' w='100%'>
        <Stack direction="row">
          <Image me='5px' boxShadow="lg" borderRadius='10px' alt='Gambar Aset BMN' maxH='450' src={URL_WEB_BACKEND+"/uploads/bmns/foto/"+showDetail.verifid+'.'+showDetail.extfoto+'?t='+imageHashToRefresh} />
          <Box w='100%'>
            <Grid templateColumns="repeat(2, 1fr)" gap={1}>
                <Box w='100%'>
                  <Text as='b'>LETAK</Text><HSeparator mb='1'/>{showDetail.fullname}<br/><br/>
                </Box>
                <Box w='100%'>
                  <Text as='b'>NOMOR URUT PENDAFTARAN</Text><HSeparator mb='1'/>{showDetail.noreg}<br/><br/>
                </Box>
              </Grid>
            <Grid templateColumns="repeat(2, 1fr)" gap={1}>
              <Box w='100%'>
                <Text as='b'>TANGGAL</Text><HSeparator mb='1'/>{convertDate(showDetail.tanggal)}<br/><br/>
              </Box>
              <Box w='100%'>
                <Text as='b'>JUMLAH</Text><HSeparator mb='1'/>{showDetail.jumlah} {showDetail.satuan}<br/><br/>
              </Box>
            </Grid>
            <Grid templateColumns="repeat(2, 1fr)" gap={1}>
              <Box w='100%'>
                <Text as='b'>KONDISI</Text><HSeparator mb='1'/>{showDetail.kondisi}<br/><br/>
              </Box>
              <Box w='100%'>
                <Text as='b'>ASAL PEROLEHAN</Text><HSeparator mb='1'/>{showDetail.sumber}<br/><br/>
              </Box>
            </Grid>
            <Grid templateColumns="repeat(2, 1fr)" gap={1}>
              <Box w='100%'>
                <Text as='b'>LINK VERIFIKASI</Text><HSeparator mb='1'/><Box onClick={()=>downloadQRCode('qrcode verifikasi bmn '+showDetail.nama)} as="button" ref={qrCodeRef} borderRadius="16px" bg="white" color="white" p={4} h={200}><QRCode size={160} value={URL_WEB_FORNTEND+'/#/verifikasi/bmn/'+showDetail.verifid} /></Box><br/><br/>
              </Box>
              <Box w='100%'>
                <Text as='b'>FILE ADMINISTRATIF</Text><HSeparator mb='1'/><Button onClick={()=>saveAs(URL_WEB_BACKEND+"/uploads/bmns/file/"+showDetail.verifid+'.'+showDetail.extfile, 'BMN-'+showDetail.noreg+'.'+showDetail.extfile)}><DownloadIcon/> LIHAT DISINI</Button><br/><br/>
              </Box>
            </Grid>
          </Box>
        </Stack>
      </Box>}
    </Card>
  );
}
