// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalFooter,
  useColorModeValue,
  Center,
  Grid,
  Box,
  Select,
  useToast,
  GridItem,
} from "@chakra-ui/react";
import { CheckIcon, InfoIcon, QuestionIcon } from '@chakra-ui/icons';
// Custom components
import Card from "components/card/Card.js";
import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import SignaturePad from 'react-signature-pad-wrapper';
import { decryptData, URL_WEB_BACKEND } from "CustomUtil";

export default function AddNosukel({onRefresh}) {
  const authdata = decryptData(localStorage.getItem("authinfositu"));
  const namanya = decryptData(localStorage.getItem("authfullnamesitu"));
  const userid = decryptData(localStorage.getItem("authidsitu"));
  const [titleDialog, setTitleDialog] = useState('title');
  const [descDialog, setDescDialog] = useState('desc');
  const [statusInfoDialog, setStatusInfoDialog] = useState(false);
  const [infoDialogIcon, setInfoDialogIcon] = useState(1);
  const [statusConfExeDialog, setStatusConfExeDialog] = useState(false);
  const signaturePadRef = useRef();
  const toast = useToast();

  // add
  const [isisurat, setIsisurat] = useState('');
  const [lampiran, setLampiran] = useState(0);
  const [isSuratBiasa, setIsSuratBiasa] = useState(true);
  const [ambilberapa, setAmbilberapa] = useState(1);
  const [tanggalsurat, setTanggalsurat] = useState(new Date());
  const [dari, setDari] = useState('Lapas Kelas I Semarang');
  const [kepada, setKepada] = useState('');
  const [pengoleh, setPengoleh] = useState('');
  const [filesurat, setFilesurat] = useState(null);
  const [nosurat, setNosurat] = useState('');
  const [disabledModif, setDisabledModif] = useState(true);
  const [kodeUPT, setKodeUPT] = useState('');
  const [kode, setKode] = useState('');
  const [kodelist, setKodelist] = useState([]);
  const [subkode1, setSubkode1] = useState('');
  const [subkode2, setSubkode2] = useState('');
  const [subkode3, setSubkode3] = useState('');
  const [subkode1list, setSubkode1list] = useState([]);
  const [subkode2list, setSubkode2list] = useState([]);
  const [subkode3list, setSubkode3list] = useState([]);

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  useEffect(() => {
    getSetKodeList();getKodeUPTNya();
  },[]);
  
  const getSetKodeList = async () => {
      await axios.get(URL_WEB_BACKEND+'/jumnosukels', {
        headers: {
          'Authorization': 'Bearer '+authdata
        }
      }).then((response) => {
        setKodelist(response.data.filter(nosukel => nosukel.id != 0).map(subnosukel => ({label: subnosukel.kode+' - '+subnosukel.keterangan, value: subnosukel.kode})));
      }).catch(err => {
        toast({
          status: 'error',
          title: 'Error status '+err.response.status,
          description: 'Data kode surat kosong.',
          position: 'bottom-right',
          isClosable: true,
        })
      });
  };

  const getKodeUPTNya = () => {
    axios.get(URL_WEB_BACKEND+'/jumnosukels/0', {
      headers: {
        'Authorization': 'Bearer '+authdata
      }
    }).then((response) => {
      setKodeUPT(response.data.kode);
    }).catch(err => {
      toast({
        status: 'error',
        title: 'Error status '+err.response.status,
        description: 'Data kode UPT kosong.',
        position: 'bottom-right',
        isClosable: true,
      });
      setKodeUPT('');
    });
  };

  const getSetSubkodeList = async (kodeinduk, listke) => {
      await axios.get(URL_WEB_BACKEND+'/specificsubnosukelsbykodeinduk/'+kodeinduk, {
        headers: {
          'Authorization': 'Bearer '+authdata
        }
      }).then((response) => {
        if(listke===1){
          if(response.status===200){
            setSubkode1list(response.data.map(subnosukel => ({label: subnosukel.nomor+' - '+subnosukel.keterangan, value: subnosukel.nomor})));
          }else{
            setSubkode1('');
            setSubkode1list([]);
          }
        }else if(listke===2){
          if(response.status===200){
            setSubkode2list(response.data.map(subnosukel => ({label: subnosukel.nomor+' - '+subnosukel.keterangan, value: subnosukel.nomor})));
          }else{
            setSubkode2('');
            setSubkode2list([]);
          }
        }else if(listke===3){
          if(response.status===200){
            setSubkode3list(response.data.map(subnosukel => ({label: subnosukel.nomor+' - '+subnosukel.keterangan, value: subnosukel.nomor})));
          }else{
            setSubkode3('');
            setSubkode3list([]);
          }
        }
      }).catch(()=> {
        if(listke===1){
          setSubkode1list([]);
        }else if(listke===2){
          setSubkode2list([]);
        }else if(listke===3){
          setSubkode3list([]);
        }
      });
  };

  useEffect(() => {
    let displaysubno1='';let displaysubno2='';let displaysubno3='';
    if(subkode1!=''){
      displaysubno1 = '.'+subkode1;
    };
    if(subkode2!=''){
      displaysubno2 = '.'+subkode2;
    };
    if(subkode3!=''){
      displaysubno3 = '.'+subkode3;
    };
    if(isSuratBiasa){
      setNosurat(kodeUPT+'-'+kode+displaysubno1+displaysubno2+displaysubno3);
    }else{
      setNosurat(kodeUPT+'-[NO_URUT_SURAT].'+kode+displaysubno1+displaysubno2+displaysubno3);
    }
  },[kodeUPT, kode, subkode1, subkode2, subkode3, isSuratBiasa]);

  const confAdd = () => {
    if(nosurat === '' || isisurat === '' || lampiran === '' || dari === '' || kepada === ''|| pengoleh === '' || signaturePadRef.current.isEmpty() || kode.length<1){toast({
      status: 'error',
      title: 'Proses Gagal',
      description: 'Masih ada kolom yang kosong',
      position: 'bottom-right',
      isClosable: true,
      })}else{showConfExeDialog('Buat Surat Keluar','Simpan data dan dapatkan nomor surat keluar?')}
  }

  function makeVerifId(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const exeAdd = async () => {
    setStatusConfExeDialog(false);
    const formData = new FormData();
        formData.append('tanggal', new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' '))
        formData.append('kode', kode)
        formData.append('ambilberapa', ambilberapa)
        formData.append('nosurat', nosurat.toUpperCase().replace(/\s+/g,""))
        formData.append('tanggalsurat', new Date(tanggalsurat.getTime() - tanggalsurat.getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' '))
        formData.append('isisurat', isisurat)
        formData.append('lampiran', lampiran)
        formData.append('dari', dari)
        formData.append('kepada', kepada)
        formData.append('id_user', parseInt(userid))
        formData.append('akun', namanya)
        formData.append('pengoleh', namanya+' - '+pengoleh)
        formData.append('paraf', signaturePadRef.current.toDataURL())
        formData.append('verifid', makeVerifId(30)+userid)
    if(filesurat!=null){
        formData.append('filesurat', filesurat)
    }
    await axios.post(URL_WEB_BACKEND+'/sukels', formData, {
      headers: {
        'Authorization': 'Bearer '+authdata
      }}).then(res => {
        if(res.status===201){
          if(isSuratBiasa){
            showInfoDialog('Surat Keluar Anda Berhasil Dibuat','Nomor Surat : '+nosurat+'-'+res.data.nourut, 0);
          }else{
            let newNomorSurat = nosurat.replace("[NO_URUT_SURAT]", res.data.nourut)
            showInfoDialog('Surat Keluar Anda Berhasil Dibuat','Nomor Surat : '+newNomorSurat, 0);
          }
        }else{
          let reskey = Object.keys(res.response.data.messages)[0];
          showInfoDialog('Error Membuat surat keluar dengan status '+res.response.status, res.response.messages[reskey], 1)
        }
      }).catch(err => {
        let errkey = Object.keys(err.response.data.messages)[0];
        showInfoDialog('Error Membuat surat keluar dengan status '+err.response.status, err.response.data.messages[errkey], 1)
      })
  }

  function showInfoDialog (title, desc, infoicon) {
    setTitleDialog(title);setDescDialog(desc);setInfoDialogIcon(infoicon);setStatusInfoDialog(true);
  }

  function showConfExeDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusConfExeDialog(true);
  }
  
  const viewInfoDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusInfoDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center>
                {infoDialogIcon
                  ? <InfoIcon w={16} h={16} color="blue.500" />
                  : <CheckIcon w={16} h={16} color="green.500" />
                }
              </Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
              <Button onClick = {() => {signaturePadRef.current.clear();setStatusInfoDialog(false);onRefresh();}}>OK</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewConfExeDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusConfExeDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center><QuestionIcon w={16} h={16} color="blue.500" /></Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='red' mr={3} onClick = {() => exeAdd()}>
                    Iya, Lanjutkan
                </Button>
              <Button onClick = {() => {setStatusConfExeDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  return (
    <Card align='center' p='20px'>
      <Flex h='100%' direction={{ base: "column", "2xl": "row" }}>
        <Flex direction='column' pe='44px'>
        {viewInfoDialog()}{viewConfExeDialog()}
          <Text
            color={textColorPrimary}
            fontWeight='bold'
            textAlign='start'
            fontSize='2xl'
            mt={{ base: "5px", "2xl": "5px" }}>
            Buat Nomor Surat Keluar
          </Text>
          <Text
            color={textColorSecondary}
            fontSize='md'
            my={{ base: "auto", "2xl": "10px" }}
            textAlign='start'>
            Nomor surat keluar resmi dari LP Semarang.
          </Text>
          <Flex w='100%' mt={{ base: "20px", "2xl": "20px" }} >
          <FormControl id="profil" isRequired>
          <Grid templateColumns="repeat(5, 1fr)" gap={6}>
                  <GridItem colSpan='3'>
                    <Box w='100%'>
                      <FormLabel>Nomor Surat</FormLabel>
                      <Grid templateColumns="repeat(5, 1fr)" gap={1} mb='4'>
                        <Input id={"add"+0+9} borderRadius="16px" value={kodeUPT} onChange={ (e) => setKodeUPT(e.target.value) } />
                        <Select borderRadius="16px" placeholder="- pilih -" value={ kode }
                          onChange={ (e) => {setKode(e.target.value); if(e.target.value.length>0){setDisabledModif(false)}; if(e.target.value.length<1){setDisabledModif(true)}; setSubkode1(''); setSubkode2(''); setSubkode3(''); getSetSubkodeList(e.target.value, 1);} }>
                            {kodelist.map((option) => (
                              <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </Select>
                        { subkode1list!=''&&kode!=''&&
                              <Select borderRadius="16px" placeholder="- pilih -" value={ subkode1 }
                                onChange={ (e) => {setSubkode1(e.target.value); setSubkode2(''); setSubkode3(''); getSetSubkodeList(kode+e.target.value, 2);}}>
                                  {subkode1list.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                  ))}
                              </Select>
                        }
                        { subkode2list!=''&&subkode1!=''&&
                              <Select borderRadius="16px" placeholder="- pilih -" value={ subkode2 }
                                onChange={ (e) => {setSubkode2(e.target.value); setSubkode3(''); getSetSubkodeList(kode+subkode1+e.target.value, 3);}}>
                                  {subkode2list.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                  ))}
                              </Select>
                        }
                        { subkode3list!=''&&subkode2!=''&&
                              <Select borderRadius="16px" placeholder="- pilih -" value={ subkode3 }
                                onChange={ (e) => setSubkode3(e.target.value)}>
                                  {subkode3list.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                  ))}
                              </Select>
                        }
                      </Grid>
                      <FormControl id={"add"+0+10}>
                        <FormLabel>Modifikasi Nomor Surat (Opsional)</FormLabel>
                        <Input variant='filled' id={"add"+0+8} borderRadius="16px" value={nosurat} onChange={ (e) => setNosurat(e.target.value) } disabled={disabledModif}  mb='5' />
                      </FormControl>
                      <Grid templateColumns="repeat(2, 1fr)" gap={1}>
                        <Box w='100%'>
                          <FormLabel>Total Pembuatan Nomor</FormLabel>
                          <Input type='number' min={1} max={100} id={"add"+0+1} placeholder="Masukkan total nomor yang ingin dibuat" borderRadius="16px" value={ambilberapa} onChange={ (e) => setAmbilberapa(e.target.value) } />
                        </Box>
                        <Box w='100%'>
                          <FormLabel>Tipe Surat</FormLabel>
                          <Select borderRadius="16px" value={isSuratBiasa} onChange={ (e) => {let current = true; if(e.target.value!=current){setIsSuratBiasa(!isSuratBiasa);current=e.target.value}}}>
                            <option value={true}>Surat Biasa</option>
                            <option value={false}>Surat Keputusan / Surat Perintah / Surat Edaran / dll.</option>
                          </Select>
                        </Box>
                      </Grid>
                      <FormLabel>Isi Ringkas</FormLabel>
                      <Input id={"add"+0+1} placeholder="Masukkan isi surat secara ringkas" borderRadius="16px" value={isisurat} onChange={ (e) => setIsisurat(e.target.value) } />
                      <FormLabel>Lampiran</FormLabel>
                      <Input type='number' min={0} id={"add"+0+2} placeholder="Masukkan jumlah lampiran" borderRadius="16px" value={lampiran} onChange={ (e) => setLampiran(e.target.value) } />
                      <FormLabel>Tanggal Surat</FormLabel>
                      <SingleDatepicker
                        name="date-input"
                        date={tanggalsurat}
                        onDateChange={setTanggalsurat}
                      />
                      <FormLabel>Dari</FormLabel>
                      <Input id={"add"+0+3} placeholder="Masukkan asal surat" borderRadius="16px" value={dari} onChange={ (e) => setDari(e.target.value) } />
                      <FormLabel>Kepada</FormLabel>
                      <Input id={"add"+0+4} placeholder="Masukkan tujuan surat" borderRadius="16px" value={kepada} onChange={ (e) => setKepada(e.target.value) } />
                      <FormControl id={"add"+0+2}>
                        <FormLabel>File Surat (Opsional)</FormLabel>
                        <Input variant='main' h='100' mb='5' border='2px dashed' type='file' id={"uploadfile"+0+5} placeholder="Pilih file surat" borderRadius="16px" onChange={ (e) => setFilesurat(e.target.files[0]) } />
                      </FormControl>
                    </Box>
                  </GridItem>
                  <GridItem colSpan='2'>
                    <Box w='100%'>
                      <FormLabel>Akun</FormLabel>
                      <Input id={"add"+0+6} borderRadius="16px" value={namanya} disabled />
                      <FormLabel>Pengoleh</FormLabel>
                      <Input id={"add"+0+7} placeholder="Masukkan nama pengoleh" borderRadius="16px" value={pengoleh} onChange={ (e) => setPengoleh(e.target.value) } />
                      <FormLabel>Paraf</FormLabel>
                      <Box border='2px dashed' borderRadius="16px" borderColor='gray.300'>
                        <SignaturePad ref={signaturePadRef} />
                        <Button mb='2' onClick = {() => signaturePadRef.current.clear()}>Ulangi Paraf</Button>
                      </Box>
                      <Button w='100%' colorScheme='red' mt={4} onClick = {() => {setKode('');setSubkode1('');setSubkode2('');setSubkode3('');setDisabledModif(true);
                                                                  setIsisurat('');setAmbilberapa(1);setLampiran(0);setTanggalsurat(new Date());setDari('Lapas Kelas I Semarang');
                                                                  setKepada('');setPengoleh('');signaturePadRef.current.clear();}}>RESET FORM</Button>
                      <Button w='100%' colorScheme='blue' mt={4} onClick = {() => confAdd()}>BUAT SURAT KELUAR</Button>
                    </Box>
                  </GridItem>
                </Grid>
          </FormControl>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
