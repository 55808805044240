/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect} from "react";
// Chakra imports
import { Box, Text, Center, useColorModeValue, useToast } from "@chakra-ui/react";
import TxDetail from "views/verif/bmn/components/TxDetail";
import Detail from "views/verif/bmn/components/Detail";
import { URL_WEB_BACKEND } from "CustomUtil";
import axios from "axios";
import { useParams } from "react-router-dom";

export default function Bmn() {
  const { verifid } = useParams();

  const [detail, setDetail] = useState([]);
  const [showData, setShowData] = useState(false);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const toast = useToast();

  const [statusLoading, setStatusLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    getBmn(verifid);
  },[]);

  const getBmn = async (id) => {
    setStatusLoading(true);
    await axios.get(URL_WEB_BACKEND+'/getbmnbyverifid/'+id, {
      headers: {}
    }).then((response) => {
      console.log(response.data)
      if(response.data===undefined||response.data.lenght<1){
        setStatusLoading(false);
        setShowData(false);
      }else{
        setDetail(response.data);
        setShowData(true);
        setStatusLoading(false);
        setRefreshing(true);
        setTimeout(() => {
          setRefreshing(false);
        }, 1000);
      }
    }).catch(err => {
      setStatusLoading(false);
      toast({
        status: 'error',
        title: 'Error status '+err.response.status,
        description: 'Data tidak ditemukan.',
        position: 'bottom-right',
        isClosable: true,
      })
    });
  }

  return (
    <Box mt='30'>
      {showData?<><Text color={textColor} ms='5' fontSize='30px' fontWeight='700' lineHeight='100%'>LP Semarang - Verifikasi Data BMN</Text><br/><Detail refreshing={refreshing} detail={detail}/><br/><TxDetail refreshing={refreshing} detail={detail}/></>:<>{statusLoading?<Center mb={10} mt={10}><Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>Mohon Tunggu...</Text></Center>:<Center mb={10} mt={10}><Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>BMN TIDAK TERDAFTAR</Text></Center>}</>}
    </Box>
  );
}
