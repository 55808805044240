/* eslint-disable */
import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Tr,
    useColorModeValue,
    Center,
    useToast,
    Grid,
    Box,
    Image,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/card/Card";
  import { HSeparator } from "components/separator/Separator";
  import { DownloadIcon } from '@chakra-ui/icons';
  import React, { useEffect, useState, useRef } from "react";
  import QRCode from "react-qr-code";
  import { convertDate, URL_WEB_BACKEND, URL_WEB_FORNTEND } from "CustomUtil";
  import axios from "axios";
  import { useParams } from "react-router-dom";
  
  export default function Verifikasi() {
    const { verifid } = useParams();
  
  
    const textColor = useColorModeValue("secondaryGray.900", "white");

    const [statusLoading, setStatusLoading] = useState(true);
    const [sukels, setSukels] = useState({});
    const qrCodeRef = useRef();
    const toast = useToast();
  
    useEffect(() => {
      getSukels(verifid);
    },[]);
  
    const getSukels = async (id) => {
        setStatusLoading(true);
        await axios.get(URL_WEB_BACKEND+'/getsukelbyverifid/'+id, {
          headers: {}
        }).then((response) => {
          setSukels(response.data);
          setStatusLoading(false);
        }).catch(err => {
          setStatusLoading(false);
          toast({
            status: 'error',
            title: 'Error status '+err.response.status,
            description: 'Data tidak ditemukan.',
            position: 'bottom-right',
            isClosable: true,
          })
        });
    }
  
    return (
      <Card
        direction='column'
        w='100%'
        px='0px'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
          <Text
            color={textColor}
            fontSize='22px'
            fontWeight='700'
            lineHeight='100%'>
            LP Semarang - Verifikasi Keaslian Surat Keluar
          </Text>
        </Flex>
        {statusLoading?<Center mb={10} mt={10}><Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>Mohon Tunggu...</Text></Center>:<Table variant='unstyled'>
          <Tbody>
          {!sukels.id
            ? <Tr><Td><Center mb={10} mt={10}><Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>SURAT TIDAK TERDAFTAR</Text></Center></Td></Tr>
            : <>
                  <Tr key={sukels.id} >
                    <Td>
                      <Card direction="row" p='6' boxShadow="md" m='2' borderRadius="lg">
                        <Grid templateColumns="repeat(2, 1fr)" gap={8}>
                          <Box w='100%'>
                            <Text as='b'>ISI RINGKAS</Text><HSeparator mb='1'/>{sukels.isisurat}<br/><br/>
                            <Text as='b'>NOMOR SURAT</Text><HSeparator mb='1'/>{sukels.nosurat} - {sukels.nourut}<br/><br/>
                            <Grid templateColumns="repeat(2, 1fr)" gap={1}>
                              <Box w='100%'>
                                <Text as='b'>TANGGAL SURAT</Text><HSeparator mb='1'/>{convertDate(sukels.tanggalsurat)}<br/><br/>
                              </Box>
                              <Box w='100%'>
                                <Text as='b'>LAMPIRAN</Text><HSeparator mb='1'/>{sukels.lampiran}<br/><br/>
                              </Box>
                            </Grid>
                            <Grid templateColumns="repeat(2, 1fr)" gap={1}>
                              <Box w='100%'>
                                <Text as='b'>DARI</Text><HSeparator mb='1'/>{sukels.dari}<br/><br/>
                              </Box>
                              <Box w='100%'>
                                <Text as='b'>KEPADA</Text><HSeparator mb='1'/>{sukels.kepada}<br/><br/>
                              </Box>
                            </Grid>
                            <Text as='b'>FILE SURAT</Text><HSeparator mb='1'/>{sukels.extfile==='null'?(<>-</>):(<Button onClick={()=>saveAs(URL_WEB_BACKEND+"/uploads/sukels/file/"+datasukels.id_user+"/"+datasukels.verifid+'.'+datasukels.extfile, 'SUKEL-'+datasukels.tanggal+'-'+datasukels.nosurat+'-'+datasukels.nourut+'.'+sukels.extfile)}><DownloadIcon/> LIHAT DISINI</Button>)}<br/><br/>
                          </Box>
  
                          <Box w='100%'>
                            <Text as='b'>TANGGAL PEMBUATAN NOMOR</Text><HSeparator mb='1'/>{convertDate(sukels.tanggal)}<br/><br/>
                            <Text as='b'>PENGOLEH</Text><HSeparator mb='1'/>{sukels.pengoleh}<br/><br/>
                            <Grid templateColumns="repeat(2, 1fr)" gap={1}>
                              <Box w='100%'>
                                <Text as='b'>PARAF</Text><HSeparator mb='2'/><Image src={sukels.paraf} bgColor='white' borderRadius='16' boxSize='200' alt='paraf' draggable='false'/><br/><br/>
                              </Box>
                              <Box w='100%'>
                                <Text as='b'>LINK VERIFIKASI</Text><HSeparator mb='2'/><Box onClick={()=>downloadQRCode('qrcode verifikasi surat '+sukels.isisurat)} as="button" ref={qrCodeRef} borderRadius="16px" bg="white" color="white" p={4} h={200}><QRCode size={160} value={URL_WEB_FORNTEND+'/#/verifikasi/sukel/'+sukels.verifid} /></Box><br/><br/>
                              </Box>
                            </Grid>
                          </Box>
                        </Grid>
                      </Card>
                    </Td>
                  </Tr>
              </>
            }
          </Tbody>
        </Table>}
      </Card>
    );
  }
  