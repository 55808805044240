/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, {useState, useCallback} from "react";

// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import Sukelstable from "views/main/sukeladmin/components/SukelsTable";
import Addjumnosukel from "views/main/sukeladmin/components/Addjumnosukel";
import AddSubnosukel from "views/main/sukeladmin/components/AddSubnosukel";
import JumnosukelsTable from "views/main/sukeladmin/components/JumnosukelsTable";
import SubnosukelsTable from "views/main/sukeladmin/components/SubnosukelsTable";

export default function Settings() {
  // variable sukellist
  const [refreshing, setRefreshing] = useState(false);

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, [refreshing]);
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 2 }}
        spacing={{ base: "20px", xl: "20px" }}>
          <SimpleGrid
            mb='20px'
            columns={{ sm: 1, md: 2 }}
            spacing={{ base: "20px", xl: "20px" }}>
            <Addjumnosukel onRefresh={onRefresh}/>
            <AddSubnosukel onRefresh={onRefresh}/>
          </SimpleGrid>
          <SimpleGrid
            mb='20px'
            columns={{ sm: 1, md: 2 }}
            spacing={{ base: "20px", xl: "20px" }}>
            <JumnosukelsTable refreshing={refreshing}/>
            <SubnosukelsTable refreshing={refreshing}/>
          </SimpleGrid>
      </SimpleGrid>
      <Sukelstable onRefresh={onRefresh}/>
    </Box>
  );
}
