
import CryptoJS from 'crypto-js';

const salt = '56ac1423';
export const encryptData = (text) =>{
    return CryptoJS.AES.encrypt(text, salt).toString();
}
    
export const decryptData = (ciphertext) => {
    const decrypted = CryptoJS.AES.decrypt(ciphertext, salt);
    if (decrypted) {
      try {
        const str = decrypted.toString(CryptoJS.enc.Utf8);
        if (str.length > 0) {
          return str;
        } else {
          return 'no text';
        } 
      } catch (e) {
        return 'wrong salt';
      }
    }
    return 'unknow error';
}

export const convertDateTime = (date) => {
  var converteddate = new Date(date).toLocaleDateString("id-ID")+' '+new Date(date).toLocaleTimeString("id-ID");
  return converteddate;
}

export const convertDate = (date) => {
  var converteddate = new Date(date).toLocaleDateString("id-ID");
  return converteddate;
}

export const URL_WEB_BACKEND = 'https://besitu.lpsemarang.or.id';
export const URL_WEB_FORNTEND = 'https://situ.lpsemarang.or.id';