/* eslint-disable */
import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  TableCaption,
  Center,
  Stack,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
  Spinner
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { HSeparator } from "components/separator/Separator";
import { CheckIcon, InfoIcon, QuestionIcon } from '@chakra-ui/icons';
import React, { useEffect, useState } from "react";
import { URL_WEB_BACKEND, decryptData } from "CustomUtil";
import axios from "axios";

export default function AdminsTable() {
  const authdata = decryptData(localStorage.getItem("authinfositu"));
  const cekadmin = decryptData(localStorage.getItem("authrolesitu"));
  const namanya = decryptData(localStorage.getItem("authfullnamesitu"));
  const userid = decryptData(localStorage.getItem("authidsitu"));
  const [admins, setAdmins] = useState([]);
  const [idSelectedData, setIdSelectedData] = useState(0);
  const [titleDialog, setTitleDialog] = useState('title');
  const [descDialog, setDescDialog] = useState('desc');
  const [statusInfoDialog, setStatusInfoDialog] = useState(false);
  const [infoDialogIcon, setInfoDialogIcon] = useState(1);
  const [statusConfExeDialog, setStatusConfExeDialog] = useState(false);
  const [actionConfExeDialog, setActionConfExeDialog] = useState(1);
  const [statusAddDialog, setStatusAddDialog] = useState(false);
  const [statusEditDialog, setStatusEditDialog] = useState(false);
  const [statusEditPassDialog, setStatusEditPassDialog] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const toast = useToast();

  const [username, setUsername] = useState('');
  const [fullname, setFullname] = useState('');
  const [pass, setPass] = useState('');
  const [confpass, setConfPass] = useState('');
  const [role, setRole] = useState('admin');

  const [searchClue, setSearchClue] = useState('');
  const filterdAdmins = admins.filter(admin => admin.id != parseInt(userid));

  const roleOpt = [
    { value: 'admin', label: 'Admin' },
    { value: 'tu', label: 'Tata Usaha' },
    { value: 'nontu', label: 'Non Tata Usaha' },
  ];

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  const textColor = useColorModeValue("secondaryGray.900", "white");

  useEffect(() => {
    getAdmins();
  },[searchClue]);

  const getAdmins = async () => {
    setStatusLoading(true);
    if(searchClue===''){
      setAdmins([]);
      await axios.get(URL_WEB_BACKEND+'/users', {
        headers: {
          'Authorization': 'Bearer '+authdata
        }
      }).then((response) => {
        setAdmins(response.data);
        setStatusLoading(false);
      }).catch(err => {
        setStatusLoading(false);
        toast({
          status: 'error',
          title: 'Error status '+err.response.status,
          description: 'Data kosong.',
          position: 'bottom-right',
          isClosable: true,
        })
      });
    }else{
      setAdmins([]);
      await axios.get(URL_WEB_BACKEND+'/cari/profile/fullname/'+searchClue, {
        headers: {
          'Authorization': 'Bearer '+authdata
        }
      }).then((response) => {
        setAdmins(response.data);
        setStatusLoading(false);
      }).catch(err => {
        setStatusLoading(false);
        toast({
          status: 'error',
          title: 'Error status '+err.response.status,
          description: 'Tak ada data yang dicari.',
          position: 'bottom-right',
          isClosable: true,
        })
      });
    }
  }

  const exeSendnotif = async (title, desc) => {
    await axios.post(URL_WEB_BACKEND+'/notifs',{
      id_user : idSelectedData,
      judul: title,
      isi: desc,
      tanggal : new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' '),
      read : 0
    }, {
      headers: {
        'Authorization': 'Bearer '+authdata
      }}).then(res => {
        if(res.status===201){
          getAdmins();
          showInfoDialog('Berhasil','Data berhasil diperbarui', 0);
        }else{
          let reskey = Object.keys(res.response.data.messages)[0];
          showInfoDialog('Error Mengirim Notifikasi dengan status '+res.response.status, res.response.messages[reskey], 1)
        }
      }).catch(err => {
        let errkey = Object.keys(err.response.data.messages)[0];
        showInfoDialog('Error Mengirim Notifikasi dengan status '+err.response.status, err.response.data.messages[errkey], 1)
      })
  }

  const exeAdd = async () => {
    setStatusAddDialog(false);setStatusConfExeDialog(false);
    if(cekadmin === 'admin'){
    await axios.post(URL_WEB_BACKEND+'/register', {
      username : username,
      password : pass,
      confpassword : confpass,
      fullname : fullname,
      role : role
    }, {
      headers: {
        'Authorization': 'Bearer '+authdata
      }}).then(res => {
        if(res.status===201){
          getAdmins();
          showInfoDialog('Berhasil','Data berhasil disimpan', 0);
        }else{
          let reskey = Object.keys(res.response.data.messages)[0];
          showInfoDialog('Error Mengirim Informasi dengan status '+res.response.status, res.response.messages[reskey], 1)
        }
      }).catch(err => {
        let errkey = Object.keys(err.response.data.messages)[0];
        showInfoDialog('Error Mengirim Informasi dengan status '+err.response.status, err.response.data.messages[errkey], 1)
      })
    }else{
      showInfoDialog('Error Hapus','Anda tidak memiliki hak akses untuk ini.', 1)
    }
  }

  const exeEdit = async () =>{
    setStatusEditDialog(false);setStatusConfExeDialog(false);
    if(cekadmin === 'admin'){
      await axios.put(URL_WEB_BACKEND+'/profile/'+idSelectedData,{
        username: username,
        fullname: fullname,
        role: role
      }, {
        headers: {
          'Authorization': 'Bearer '+authdata
        }}).then(res => {
          if(res.status===200){
            exeSendnotif('Profil Diubah', 'Profil Diubah oleh admin : '+namanya);
          }else{
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error Perbarui Profil dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error Perbarui Profil dengan status '+err.response.status, err.response.data.messages[errkey], 1)
        })
    }else{
      showInfoDialog('Error Pembaruan Profil','Anda tidak memiliki hak akses untuk ini.', 1)
    }
  }

  const exeEditPass = async () =>{
    setStatusEditPassDialog(false);setStatusConfExeDialog(false);
    if(cekadmin === 'admin'){
      await axios.put(URL_WEB_BACKEND+'/profile/'+idSelectedData,{
        password: pass,
        confpassword: confpass
      }, {
        headers: {
          'Authorization': 'Bearer '+authdata
        }}).then(res => {
          if(res.status===200){
            exeSendnotif('Password Diubah', 'Password Diubah oleh admin : '+namanya);
          }else{
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error Perbarui Password dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error Perbarui Password dengan status '+err.response.status, err.response.data.messages[errkey], 1)
        })
    }else{
      showInfoDialog('Error Pembaruan Password','Anda tidak memiliki hak akses untuk ini.', 1)
    }
  }
  
  const exeDelete = async () =>{
    setStatusConfExeDialog(false);
    if(cekadmin === 'admin'){
      await axios.delete(URL_WEB_BACKEND+'/profile/'+idSelectedData, {
        headers: {
          'Authorization': 'Bearer '+authdata
        }}).then(res => {
          if(res.status===200){
            getAdmins();
            showInfoDialog('Berhasil', 'Data Berhasil Dihapus', 0)
          }else{
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error Hapus dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error Hapus dengan status '+err.response.status, err.response.data.messages[errkey], 1)
        })
    }else{
      showInfoDialog('Error Hapus','Anda tidak memiliki hak akses untuk ini.', 1)
    }
  }

  function showInfoDialog (title, desc, infoicon) {
    setTitleDialog(title);setDescDialog(desc);setInfoDialogIcon(infoicon);setStatusInfoDialog(true);
  }

  function showConfExeDialog (title, desc, action) {
    setTitleDialog(title);setDescDialog(desc);setStatusConfExeDialog(true);setActionConfExeDialog(action);
  }

  function showEditDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusEditDialog(true);
  }

  function showEditPassDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusEditPassDialog(true);
  }

  function showAddDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusAddDialog(true);
  }

  const viewInfoDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusInfoDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center>
                {infoDialogIcon
                  ? <InfoIcon w={16} h={16} color="blue.500" />
                  : <CheckIcon w={16} h={16} color="green.500" />
                }
              </Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
              <Button onClick = {() => {setStatusInfoDialog(false)}}>OK</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewConfExeDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusConfExeDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center><QuestionIcon w={16} h={16} color="blue.500" /></Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='red' mr={3} onClick = {() => {{
                  if(actionConfExeDialog===1){
                    exeEdit();
                  }else if(actionConfExeDialog===2){
                    exeEditPass();
                  }else if(actionConfExeDialog===3){
                    exeDelete();
                  }else{
                    exeAdd();
                  }
                  }}}>
                    Iya, Lanjutkan
                </Button>
              <Button onClick = {() => {setStatusConfExeDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewEditDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusEditDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                textAlign='start'
                fontSize='2xl'
                mt={{ base: "5px", "2xl": "5px" }}>
                {titleDialog}
              </Text>
              <Text
                color={textColorSecondary}
                fontSize='md'
                my={{ base: "auto", "2xl": "10px" }}
                textAlign='start'>
                {descDialog}
              </Text>  
            </ModalHeader>
            <ModalBody>
              <FormControl id={"profil"+idSelectedData} isRequired>
                <FormLabel>Username</FormLabel>
                <Input id={"profil"+idSelectedData+1} placeholder="Masukkan username" borderRadius="16px" value={username} onChange={ (e) => setUsername(e.target.value) } />
                <FormLabel>Fullname</FormLabel>
                <Input id={"profil"+idSelectedData+2} placeholder="Masukkan fulname" borderRadius="16px" value={fullname} onChange={ (e) => setFullname(e.target.value) } />
                <FormLabel>Akses Keanggotaan</FormLabel>
                <Select borderRadius='16px' aria-label="Default" value={role} onChange={ (e) => setRole(e.target.value) }>
                  {roleOpt.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
                </Select>
              </FormControl>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick = {() => {{
                  if(username === '' || fullname === '' || role === ''){toast({
                    status: 'error',
                    title: 'Proses Gagal',
                    description: 'Masih ada kolom yang kosong',
                    position: 'bottom-right',
                    isClosable: true,
                    })}else{showConfExeDialog('Edit Data Pengguna','Simpan pembaruan pengguna ini?',1)}}}}>
                    Simpan
                </Button>
              <Button onClick = {() => {setUsername('');setFullname('');setStatusEditDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewEditPassDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusEditPassDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                textAlign='start'
                fontSize='2xl'
                mt={{ base: "5px", "2xl": "5px" }}>
                {titleDialog}
              </Text>
              <Text
                color={textColorSecondary}
                fontSize='md'
                my={{ base: "auto", "2xl": "10px" }}
                textAlign='start'>
                {descDialog}
              </Text>  
            </ModalHeader>
            <ModalBody>
              <FormControl id={"pass"+idSelectedData} isRequired>
                <FormLabel>Password Baru</FormLabel>
                <Input type='password' id={"pass"+idSelectedData+2} placeholder="Masukkan password baru" borderRadius="16px" value={pass} onChange={ (e) => setPass(e.target.value) } />
                <FormLabel>Konfirmasi Password</FormLabel>
                <Input type='password' id={"pass"+idSelectedData+3} placeholder="Konfirmasi password baru" borderRadius="16px" value={confpass} onChange={ (e) => setConfPass(e.target.value) } />
              </FormControl>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick = {() => {{if(pass === '' || confpass === ''){toast({
                    status: 'error',
                    title: 'Proses Gagal',
                    description: 'Masih ada kolom yang kosong',
                    position: 'bottom-right',
                    isClosable: true,
                    })}else{if(pass === confpass){showConfExeDialog('Edit Password','Simpan pembaruan password pengguna ini?',2)}else{toast({
                      status: 'error',
                      title: 'Proses Gagal',
                      description: 'Password baru tidak sama',
                      position: 'bottom-right',
                      isClosable: true,
                    })}}}}}>
                    Simpan
                </Button>
              <Button onClick = {() => {setPass('');setConfPass('');setStatusEditPassDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewAddDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusAddDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                textAlign='start'
                fontSize='2xl'
                mt={{ base: "5px", "2xl": "5px" }}>
                {titleDialog}
              </Text>
              <Text
                color={textColorSecondary}
                fontSize='md'
                my={{ base: "auto", "2xl": "10px" }}
                textAlign='start'>
                {descDialog}
              </Text>  
            </ModalHeader>
            <ModalBody>
              <FormControl id={"add"+idSelectedData} isRequired>
                <FormLabel>Username</FormLabel>
                <Input id={"add"+idSelectedData+1} placeholder="Masukkan username" borderRadius="16px" value={username} onChange={ (e) => setUsername(e.target.value) } />
                <FormLabel>Fullname</FormLabel>
                <Input id={"add"+idSelectedData+2} placeholder="Masukkan fulname" borderRadius="16px" value={fullname} onChange={ (e) => setFullname(e.target.value) } />
                <FormLabel>Akses Keanggotaan</FormLabel>
                <Select id={"add"+idSelectedData+3} aria-label="Default" value={ role } onChange={ (e) => setRole(e.target.value) }>
                  {roleOpt.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
                </Select>
                <FormLabel>Password</FormLabel>
                <Input type='password' id={"add"+idSelectedData+4} placeholder="Masukkan password" borderRadius="16px" value={pass} onChange={ (e) => setPass(e.target.value) } />
                <FormLabel>Konfirmasi Password</FormLabel>
                <Input type='password' id={"add"+idSelectedData+5} placeholder="Konfirmasi password" borderRadius="16px" value={confpass} onChange={ (e) => setConfPass(e.target.value) } />
              </FormControl>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick = {() => {{if(username === '' || fullname === '' || role === '' || pass === '' || confpass === ''){toast({
                    status: 'error',
                    title: 'Proses Gagal',
                    description: 'Masih ada kolom yang kosong',
                    position: 'bottom-right',
                    isClosable: true,
                    });}else{if(pass === confpass){showConfExeDialog('Tambah Data Penggguna','Simpan data pengguna ini?',4)}else{toast({
                      status: 'error',
                      title: 'Proses Gagal',
                      description: 'Password tidak sama',
                      position: 'bottom-right',
                      isClosable: true,
                    });}}}}}>
                    Simpan
                </Button>
              <Button onClick = {() => {setUsername('');setFullname('');setPass('');setStatusAddDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        {viewInfoDialog()}{viewConfExeDialog()}{viewEditDialog()}{viewEditPassDialog()}{viewAddDialog()}
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Daftar Pengguna
        </Text>
        <Button
          variant='action'
          onClick={()=>{
            setIdSelectedData('');setUsername('');setFullname('');setRole('tu');setPass('');setConfPass('');
            setTimeout(() => {
              showAddDialog('Tambahkan Pengguna','Untuk Aplikasi SITU.')
            }, 200);
          }}>
          <Text
            fontSize='lg'
            as='b'>
            +  Tambah Pengguna
          </Text>
        </Button>
      </Flex>
      <Table minH='600' variant='unstyled'>
        <TableCaption>Daftar di atas tidak termasuk akun Anda</TableCaption>
        <Thead><Tr>
          <Td><Input variant='filled' id={"search"+searchClue} placeholder="Cari Berdasarkan Nama Lengkap" borderRadius="16px" value={searchClue} onChange={ (e) => setSearchClue(e.target.value) } /></Td>
        </Tr></Thead>
        <Tbody>
        {filterdAdmins.length<1
          ? <Tr><Td><Center mb={6}>{statusLoading?<Spinner thickness='4px' speed='0.95s' emptyColor='gray.200' color='blue.500' size='xl'/>:<>Belum ada pengguna lain selain Anda.</>}</Center></Td></Tr>
          : <>
              {filterdAdmins.map((dataadmin, index) => (
                <Tr key={index} >
                  <Td>
                  <Card direction="row" p="4" boxShadow="md" m="2" borderRadius="lg">
                    <Text as='b'>Username</Text><HSeparator/>{dataadmin.username}<br/><br/>
                    <Text as='b'>Nama Lengkap</Text><HSeparator/>{dataadmin.fullname}<br/><br/>
                    <Text as='b'>Akses Keanggotaan</Text><HSeparator/>{dataadmin.role}<br/><br/>
                    <Stack direction={{ base: 'column', md: 'row' }}>
                        <Button variant="outline" colorScheme="red" onClick={()=>{
                          setIdSelectedData(dataadmin.id);
                          setTimeout(() => {
                            showConfExeDialog('Hapus Akun Pengguna','Yakin ingin menghapus '+dataadmin.fullname+' ?',3)
                          }, 200);
                          }}>HAPUS</Button>
                        <Button colorScheme="blue" onClick={()=>{
                          setIdSelectedData(dataadmin.id);setUsername(dataadmin.username);setFullname(dataadmin.fullname);setRole(dataadmin.role);
                          setTimeout(() => {
                            showEditDialog('Edit Data Pengguna','Perbarui data pengguna agar sesuai.')
                          }, 200);
                          }}>EDIT DATA</Button>
                        <Button colorScheme="purple" onClick={()=>{
                          setIdSelectedData(dataadmin.id);
                          setTimeout(() => {
                            showEditPassDialog('Edit Password Pengguna','Perbarui password milik '+dataadmin.fullname+'.')
                          }, 200);
                          }}>EDIT PASSWORD</Button>
                      </Stack>
                      </Card>
                  </Td>
                </Tr>
              ))}
            </>
        }
        </Tbody>
      </Table>
    </Card>
  );
}
