import React from "react";

// Chakra imports
import { Flex, Text } from "@chakra-ui/react";

// Assets
import banner from "assets/img/dashboards/ViewLapas.png";

import { decryptData } from "CustomUtil";

export default function Banner() {
  const namanya = decryptData(localStorage.getItem("authfullnamesitu"));
  // Chakra Color Mode
  return (
    <Flex
      direction='column'
      bgImage={banner}
      bgSize='cover'
      py={{ base: "30px", md: "56px" }}
      px={{ base: "30px", md: "64px" }}
      borderRadius='30px'>
        <Text
        fontSize={{ base: "24px", md: "34px" }}
        color='white'
        mb='14px'
        maxW={{
          base: "100%",
          md: "64%",
          lg: "46%",
          xl: "70%",
          "2xl": "50%",
          "3xl": "42%",
        }}
        fontWeight='700'
        lineHeight={{ base: "32px", md: "42px" }}>
        Selamat Datang {namanya},
      </Text>
      <Text
        fontSize='md'
        color='white'
        maxW={{
          base: "100%",
          md: "64%",
          lg: "40%",
          xl: "56%",
          "2xl": "46%",
          "3xl": "34%",
        }}
        fontWeight='500'
        mb='40px'
        lineHeight='28px'>
        Semoga hari ini menyenangkan !
      </Text>
    </Flex>
  );
}
