import React, { useState, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import axios from 'axios';
import { URL_WEB_BACKEND, decryptData } from "./CustomUtil"

import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/main";
import VerifSukelView from "views/verif/sukel";
import VerifBmnView from "views/verif/bmn";


const AuthRouter  = () => {

  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("authinfositu") !== null) {
      const authdata = decryptData(localStorage.getItem("authinfositu"))
      axios.get(URL_WEB_BACKEND+'/profile', {
      headers: {
           'Authorization': 'Bearer '+authdata
         }
       }).then(res => {
         if(res.data !== null){
          setIsLogged(true)
         }else{
          window.location.replace('/auth')
         }
       }).catch(error => {
          console.log(error)
          window.location.replace('/main')
       })
    } else {
      setIsLogged(false)
    }
  }, [isLogged]);
  
  const UnauthenticatedRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      !isLogged
        ? <Component {...props} />
        : <Redirect to='/main' />
    )} />
  );
  
  const AuthenticatedRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      isLogged
        ? <Component {...props} />
        : <Redirect to='/auth' />
    )} />
  );

  return (
    <>
      <UnauthenticatedRoute path={`/auth`} component={AuthLayout} />
      <AuthenticatedRoute path={`/main`} component={AdminLayout} />
      <Route exact path="/">
        <Redirect to="/auth" />
      </Route>
      <Route exact path={`/verifikasi/sukel/:verifid`} component={VerifSukelView} />
      <Route exact path={`/verifikasi/bmn/:verifid`} component={VerifBmnView} />
    </>
  )

}

export default AuthRouter