/* eslint-disable */
import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  TableCaption,
  Center,
  Stack,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
  Grid,
  GridItem,
  Box,
  Spinner,
  Image,
} from "@chakra-ui/react";
// Custom components
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import SignaturePad from 'react-signature-pad-wrapper';
import Card from "components/card/Card";
import { HSeparator } from "components/separator/Separator";
import { CheckIcon, InfoIcon, QuestionIcon, DownloadIcon, ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import React, { useEffect, useState, useRef } from "react";
import { convertDate, URL_WEB_BACKEND, decryptData } from "CustomUtil";
import axios from "axios";
import QRCode from "react-qr-code";
import html2canvas from 'html2canvas';
import { URL_WEB_FORNTEND } from "CustomUtil";
import {utils as xlxUtils, writeFile as xlxWriteFile, write as xlxWrite} from "xlsx";
import { saveAs } from "file-saver";

export default function SukelsTable({onRefresh}) {
  const authdata = decryptData(localStorage.getItem("authinfositu"));
  const namanya = decryptData(localStorage.getItem("authfullnamesitu"));
  const userid = decryptData(localStorage.getItem("authidsitu"));

  const [sukels, setSukels] = useState([]);
  const [idSelectedData, setIdSelectedData] = useState(0);
  const [titleDialog, setTitleDialog] = useState('title');
  const [descDialog, setDescDialog] = useState('desc');
  const [statusInfoDialog, setStatusInfoDialog] = useState(false);
  const [infoDialogIcon, setInfoDialogIcon] = useState(1);
  const [statusConfExeDialog, setStatusConfExeDialog] = useState(false);
  const [actionConfExeDialog, setActionConfExeDialog] = useState(1);
  const [statusAddDialog, setStatusAddDialog] = useState(false);
  const [statusEditDialog, setStatusEditDialog] = useState(false);
  const [statusUploading, setStatusUploading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const signaturePadRef = useRef();
  const qrCodeRef = useRef();
  const toast = useToast();

  // add
  const [isisurat, setIsisurat] = useState('');
  const [lampiran, setLampiran] = useState(0);
  const [ambilberapa, setAmbilberapa] = useState(1);
  const [isSuratBiasa, setIsSuratBiasa] = useState(true);
  const [tanggalsurat, setTanggalsurat] = useState(new Date());
  const [dari, setDari] = useState('Lapas Kelas I Semarang');
  const [kepada, setKepada] = useState('');
  const [pengoleh, setPengoleh] = useState('');
  const [tanggalBuatNomor, setTanggalBuatNomor] = useState(new Date());
  const [filesurat, setFilesurat] = useState(null);
  const [idPembuatSurat, setIdPembuatSurat] = useState(0);
  const [verifIdSurat, setVerifIdSurat] = useState('');
  const [nosurat, setNosurat] = useState('');
  const [disabledModif, setDisabledModif] = useState(true);
  const [kodeUPT, setKodeUPT] = useState('');
  const [kode, setKode] = useState('');
  const [kodelist, setKodelist] = useState([]);
  const [subkode1, setSubkode1] = useState('');
  const [subkode2, setSubkode2] = useState('');
  const [subkode3, setSubkode3] = useState('');
  const [subkode1list, setSubkode1list] = useState([]);
  const [subkode2list, setSubkode2list] = useState([]);
  const [subkode3list, setSubkode3list] = useState([]);

  // pagination
  const [isPagination, setIsPagination] = useState(true);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [allPageNumber, setAllPageNumber] = useState(0);

  // search
  const [searchClue, setSearchClue] = useState('');
  const [searchCat, setSearchCat] = useState('isisurat');

  const catOpt = [
    { value: 'isisurat', label: 'Isi Ringkas' },
    { value: 'nosurat', label: 'Nomor Surat' },
    { value: 'kepada', label: 'Penerima Surat' },
    { value: 'pengoleh', label: 'Pengoleh' },
  ];

  const tipeSuratList = [
    { value: true, label: 'Surat Biasa' },
    { value: false, label: 'Surat Keputusan / Surat Perintah / Surat Edaran / dll.' },
  ];


  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  const textColor = useColorModeValue("secondaryGray.900", "white");
  
  useEffect(() => {
    getSetKodeList();getKodeUPTNya();
  },[]);
  
  const getSetKodeList = async () => {
      await axios.get(URL_WEB_BACKEND+'/jumnosukels', {
        headers: {
          'Authorization': 'Bearer '+authdata
        }
      }).then((response) => {
        setKodelist(response.data.filter(nosukel => nosukel.id != 0).map(subnosukel => ({label: subnosukel.kode+' - '+subnosukel.keterangan, value: subnosukel.kode})));
      }).catch(err => {
        toast({
          status: 'error',
          title: 'Error status '+err.response.status,
          description: 'Data kode surat kosong.',
          position: 'bottom-right',
          isClosable: true,
        })
      });
  };

  const getKodeUPTNya = () => {
    axios.get(URL_WEB_BACKEND+'/jumnosukels/0', {
      headers: {
        'Authorization': 'Bearer '+authdata
      }
    }).then((response) => {
      setKodeUPT(response.data.kode);
    }).catch(err => {
      toast({
        status: 'error',
        title: 'Error status '+err.response.status,
        description: 'Data kode UPT kosong.',
        position: 'bottom-right',
        isClosable: true,
      });
      setKodeUPT('');
    });
  };

  const getSetSubkodeList = async (kodeinduk, listke) => {
      await axios.get(URL_WEB_BACKEND+'/specificsubnosukelsbykodeinduk/'+kodeinduk, {
        headers: {
          'Authorization': 'Bearer '+authdata
        }
      }).then((response) => {
        if(listke===1){
          if(response.status===200){
            setSubkode1list(response.data.map(subnosukel => ({label: subnosukel.nomor+' - '+subnosukel.keterangan, value: subnosukel.nomor})));
          }else{
            setSubkode1('');
            setSubkode1list([]);
          }
        }else if(listke===2){
          if(response.status===200){
            setSubkode2list(response.data.map(subnosukel => ({label: subnosukel.nomor+' - '+subnosukel.keterangan, value: subnosukel.nomor})));
          }else{
            setSubkode2('');
            setSubkode2list([]);
          }
        }else if(listke===3){
          if(response.status===200){
            setSubkode3list(response.data.map(subnosukel => ({label: subnosukel.nomor+' - '+subnosukel.keterangan, value: subnosukel.nomor})));
          }else{
            setSubkode3('');
            setSubkode3list([]);
          }
        }
      }).catch(()=> {
        if(listke===1){
          setSubkode1list([]);
        }else if(listke===2){
          setSubkode2list([]);
        }else if(listke===3){
          setSubkode3list([]);
        }
      });
  };

  useEffect(() => {
    let displaysubno1='';let displaysubno2='';let displaysubno3='';
    if(subkode1!=''){
      displaysubno1 = '.'+subkode1;
    };
    if(subkode2!=''){
      displaysubno2 = '.'+subkode2;
    };
    if(subkode3!=''){
      displaysubno3 = '.'+subkode3;
    };
    if(isSuratBiasa){
      setNosurat(kodeUPT+'-'+kode+displaysubno1+displaysubno2+displaysubno3);
    }else{
      setNosurat(kodeUPT+'-[NO_URUT_SURAT].'+kode+displaysubno1+displaysubno2+displaysubno3);
    }
  },[kodeUPT, kode, subkode1, subkode2, subkode3, isSuratBiasa]);

  useEffect(() => {
    getSukels(1);
  },[searchClue]);

  const getSukels = async (pagesNumber) => {
    setStatusLoading(true);
    setSukels([]);
    if(searchClue===''){
      setIsPagination(true);
      await axios.get(URL_WEB_BACKEND+'/sukels?page='+pagesNumber, {
        headers: {
          'Authorization': 'Bearer '+authdata
        }
      }).then((response) => {
        setSukels(response.data.sukels);
        setAllPageNumber(response.data.pages);
        setStatusLoading(false);
      }).catch(err => {
        setStatusLoading(false);
        toast({
          status: 'error',
          title: 'Error status '+err.response.status,
          description: 'Data kosong.',
          position: 'bottom-right',
          isClosable: true,
        })
      });
    }else{
      setIsPagination(false);
      await axios.get(URL_WEB_BACKEND+'/cari/sukels/'+searchCat+'/'+searchClue+'?page='+pagesNumber, {
        headers: {
          'Authorization': 'Bearer '+authdata
        }
      }).then((response) => {
        setIsPagination(true);
        setSukels(response.data.sukels);
        setAllPageNumber(response.data.pages);
        setStatusLoading(false);
      }).catch(err => {
        setStatusLoading(false);
        toast({
          status: 'error',
          title: 'Error status '+err.response.status,
          description: 'Tak ada data yang dicari.',
          position: 'bottom-right',
          isClosable: true,
        })
      });
    }
  }

  const exeSendnotif = async (title, desc) => {
    await axios.post(URL_WEB_BACKEND+'/notifs',{
      id_user : idPembuatSurat,
      judul: title,
      isi: desc,
      tanggal : new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' '),
      read : 0
    }, {
      headers: {
        'Authorization': 'Bearer '+authdata
      }}).then(res => {
        if(res.status===201){
          setStatusUploading(false);
          getSukels(1);
          showInfoDialog('Berhasil','Data berhasil diperbarui', 0);
        }else{
          let reskey = Object.keys(res.response.data.messages)[0];
          showInfoDialog('Error Mengirim Notifikasi dengan status '+res.response.status, res.response.messages[reskey], 1)
        }
      }).catch(err => {
        let errkey = Object.keys(err.response.data.messages)[0];
        showInfoDialog('Error Mengirim Notifikasi dengan status '+err.response.status, err.response.data.messages[errkey], 1)
      })
  }

  function makeVerifId(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const exeAdd = async () => {
    setStatusAddDialog(false);setStatusConfExeDialog(false);
    const formData = new FormData();
        formData.append('tanggal', new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' '))
        formData.append('kode', kode)
        formData.append('ambilberapa', ambilberapa)
        formData.append('nosurat', nosurat.toUpperCase().replace(/\s+/g,""))
        formData.append('tanggalsurat', new Date(tanggalsurat.getTime() - tanggalsurat.getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' '))
        formData.append('isisurat', isisurat)
        formData.append('lampiran', lampiran)
        formData.append('dari', dari)
        formData.append('kepada', kepada)
        formData.append('id_user', parseInt(userid))
        formData.append('akun', namanya)
        formData.append('pengoleh', namanya+' - '+pengoleh)
        formData.append('paraf', signaturePadRef.current.toDataURL())
        formData.append('verifid', makeVerifId(30)+userid)
    if(filesurat!=null){
        formData.append('filesurat', filesurat)
    }
    await axios.post(URL_WEB_BACKEND+'/sukels', formData, {
      headers: {
        'Authorization': 'Bearer '+authdata
      }}).then(res => {
        if(res.status===201){
          onRefresh();getSukels(1);
          if(isSuratBiasa){
            showInfoDialog('Surat Keluar Anda Berhasil Dibuat','Nomor Surat : '+nosurat+'-'+res.data.nourut, 0);
          }else{
            let newNomorSurat = nosurat.replace("[NO_URUT_SURAT]", res.data.nourut)
            showInfoDialog('Surat Keluar Anda Berhasil Dibuat','Nomor Surat : '+newNomorSurat, 0);
          }
        }else{
          let reskey = Object.keys(res.response.data.messages)[0];
          showInfoDialog('Error Membuat surat keluar dengan status '+res.response.status, res.response.messages[reskey], 1)
        }
      }).catch(err => {
        let errkey = Object.keys(err.response.data.messages)[0];
        showInfoDialog('Error Membuat surat keluar dengan status '+err.response.status, err.response.data.messages[errkey], 1)
      })
  }

  const exeEdit = async () =>{
    setStatusUploading(true);setStatusEditDialog(false);setStatusConfExeDialog(false);
    const formDataEdit = new FormData();
    formDataEdit.append('tanggal', new Date(tanggalBuatNomor.getTime() - tanggalBuatNomor.getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' '))
    formDataEdit.append('nosurat', nosurat.toUpperCase().replace(/\s+/g,""))
    formDataEdit.append('tanggalsurat', new Date(tanggalsurat.getTime() - tanggalsurat.getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' '))
    formDataEdit.append('isisurat', isisurat)
    formDataEdit.append('lampiran', lampiran)
    formDataEdit.append('dari', dari)
    formDataEdit.append('kepada', kepada)
    formDataEdit.append('id_user', idPembuatSurat)
    formDataEdit.append('pengoleh', pengoleh)
    formDataEdit.append('verifid', verifIdSurat)
    if(filesurat!=null){
      formDataEdit.append('filesurat', filesurat)
    }
      await axios.post(URL_WEB_BACKEND+'/updatesukels/'+idSelectedData, formDataEdit, {
        headers: {
          'Authorization': 'Bearer '+authdata,
          'content-type': 'application/ x-www-form-urlencoded'
        }}).then(res => {
          if(res.status===200){
            exeSendnotif('Surat Keluar Diperbarui', 'Surat keluar Diperbarui oleh admin : '+namanya);
          }else{
            setStatusUploading(false);
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error Perbarui Surat keluar dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          setStatusUploading(false);
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error Perbarui Surat keluar dengan status '+err.response.status, err.response.data.messages[errkey], 1)
        })
  }

  const exeDelete = async () =>{
    setStatusConfExeDialog(false);
      await axios.delete(URL_WEB_BACKEND+'/sukels/'+idSelectedData, {
        headers: {
          'Authorization': 'Bearer '+authdata
        }}).then(res => {
          if(res.status===200){
            getSukels(currentPageNumber);
            showInfoDialog('Berhasil', 'Data Berhasil Dihapus', 0)
          }else{
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error Hapus dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error Hapus dengan status '+err.response.status, err.response.data.messages[errkey], 1)
        })
  }

  const downloadQRCode = async (namafile) => {
    const element = qrCodeRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/jpg');
    const link = document.createElement('a');
    if (typeof link.download === 'string') {
      link.href = data;
      link.download = namafile+'.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  const downloadToExcel = async () => {
    await axios.get(URL_WEB_BACKEND+'/getallsukels', {
      headers: {
        'Authorization': 'Bearer '+authdata
      }
    }).then((response) => {
      const worksheet = xlxUtils.json_to_sheet(response.data);
      const workbook = xlxUtils.book_new();
      xlxUtils.book_append_sheet(workbook, worksheet, "Sheet1");
      // let buffer = xlxWrite(workbook, { bookType: "xlsx", type: "buffer" });
      // xlxWrite(workbook, { bookType: "xlsx", type: "binary" });
      xlxWriteFile(workbook, "Semua_Surat_Keluar_LP_Semarang_Pertanggal_"+new Date().toISOString().slice(0, 10)+".xlsx");
    }).catch(err => {
      toast({
        status: 'error',
        title: 'Error status '+err.response.status,
        description: 'Data Semua Surat Keluar kosong.',
        position: 'bottom-right',
        isClosable: true,
      })
    });
  };

  function showInfoDialog (title, desc, infoicon) {
    setTitleDialog(title);setDescDialog(desc);setInfoDialogIcon(infoicon);setStatusInfoDialog(true);
  }

  function showConfExeDialog (title, desc, action) {
    setTitleDialog(title);setDescDialog(desc);setStatusConfExeDialog(true);setActionConfExeDialog(action);
  }

  function showEditDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusEditDialog(true);
  }

  function showAddDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusAddDialog(true);
  }

  const viewInfoDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusInfoDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center>
                {infoDialogIcon
                  ? <InfoIcon w={16} h={16} color="blue.500" />
                  : <CheckIcon w={16} h={16} color="green.500" />
                }
              </Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
              <Button onClick = {() => {setStatusInfoDialog(false)}}>OK</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewConfExeDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusConfExeDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center><QuestionIcon w={16} h={16} color="blue.500" /></Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='red' mr={3} onClick = {() => {{
                  if(actionConfExeDialog===1){
                    exeEdit();
                  }else if(actionConfExeDialog===2){
                    exeAdd();
                  }else{
                    exeDelete();
                  }
                  }}}>
                    Iya, Lanjutkan
                </Button>
              <Button onClick = {() => {setStatusConfExeDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewEditDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusEditDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                textAlign='start'
                fontSize='2xl'
                mt={{ base: "5px", "2xl": "5px" }}>
                {titleDialog}
              </Text>
              <Text
                color={textColorSecondary}
                fontSize='md'
                my={{ base: "auto", "2xl": "10px" }}
                textAlign='start'>
                {descDialog}
              </Text>  
            </ModalHeader>
            <ModalBody>
              <FormControl id={"editsukel"+idSelectedData} isRequired>
                    <FormControl>
                      <FormLabel>Modifikasi Nomor Surat (Opsional)</FormLabel>
                        <Input variant='filled' id={"add"+idSelectedData+8} borderRadius="16px" value={nosurat} onChange={ (e) => setNosurat(e.target.value) }/>
                    </FormControl>
                    <FormLabel>Isi Ringkas</FormLabel>
                    <Input id={"add"+idSelectedData+1} placeholder="Masukkan isi surat secara ringkas" borderRadius="16px" value={isisurat} onChange={ (e) => setIsisurat(e.target.value) } />
                    <FormLabel>Lampiran</FormLabel>
                    <Input type='number' id={"add"+idSelectedData+2} placeholder="Masukkan jumlah lampiran" borderRadius="16px" value={lampiran} onChange={ (e) => setLampiran(e.target.value) } />
                    <FormLabel>Tanggal Surat</FormLabel>
                    <SingleDatepicker
                      name="date-input-tglsurat"
                      date={tanggalsurat}
                      onDateChange={setTanggalsurat}
                    />
                    <FormLabel>Tanggal Buat Nomor</FormLabel>
                    <SingleDatepicker
                      name="date-input-tgl"
                      date={tanggalBuatNomor}
                      onDateChange={setTanggalBuatNomor}
                    />
                    <FormLabel>Dari</FormLabel>
                    <Input id={"add"+idSelectedData+3} placeholder="Masukkan asal surat" borderRadius="16px" value={dari} onChange={ (e) => setDari(e.target.value) } />
                    <FormLabel>Kepada</FormLabel>
                    <Input id={"add"+idSelectedData+4} placeholder="Masukkan tujuan surat" borderRadius="16px" value={kepada} onChange={ (e) => setKepada(e.target.value) } />
                    <FormLabel>Pengoleh</FormLabel>
                    <Input id={"add"+idSelectedData+7} placeholder="Masukkan nama pengoleh" borderRadius="16px" value={pengoleh} onChange={ (e) => setPengoleh(e.target.value) } />
                    <FormControl id={"add"+idSelectedData+2}>
                      <FormLabel>Ganti File Surat (Opsional)</FormLabel>
                      <Input variant='main' h='100' mb='5' border='2px dashed' type='file' id={"uploadfile"+idSelectedData+5} placeholder="Pilih file surat" borderRadius="16px" onChange={ (e) => setFilesurat(e.target.files[0]) } />
                    </FormControl>
              </FormControl>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick = {() => {{
                  if(nosurat === '' || isisurat === '' || lampiran === '' || dari === '' || kepada === ''|| pengoleh === ''){toast({
                    status: 'error',
                    title: 'Proses Gagal',
                    description: 'Masih ada kolom yang kosong',
                    position: 'bottom-right',
                    isClosable: true,
                    })}else{showConfExeDialog('Edit Data Surat Keluar','Simpan pembaruan surat keluar ini?',1)}}}}>
                    Simpan
                </Button>
              <Button onClick = {() => {setStatusEditDialog(false);setKode('');setSubkode1('');setSubkode2('');setSubkode3('');setDisabledModif(true);
                                        setIsisurat('');setLampiran(0);setTanggalsurat(new Date());setDari('Lapas Kelas I Semarang');
                                        setKepada('');setPengoleh('');signaturePadRef.current.clear();}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewAddDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusAddDialog} size='full'>
          <ModalContent>
            <ModalHeader>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                textAlign='start'
                fontSize='2xl'
                mt={{ base: "5px", "2xl": "5px" }}>
                {titleDialog}
              </Text>
              <Text
                color={textColorSecondary}
                fontSize='md'
                my={{ base: "auto", "2xl": "10px" }}
                textAlign='start'>
                {descDialog}
              </Text>
            </ModalHeader>
            <ModalBody>
              <FormControl id={"add"+idSelectedData} isRequired>
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  <Box w='100%'>
                    <FormLabel>Nomor Surat</FormLabel>
                    <Grid templateColumns="repeat(5, 1fr)" gap={1} mb='4'>
                      <Input id={"add"+idSelectedData+9} borderRadius="16px" value={kodeUPT} onChange={ (e) => setKodeUPT(e.target.value) } />
                      <Select borderRadius="16px" placeholder="- pilih -" value={ kode }
                        onChange={ (e) => {setKode(e.target.value); if(e.target.value.length>0){setDisabledModif(false)}; if(e.target.value.length<1){setDisabledModif(true)}; setSubkode1(''); setSubkode2(''); setSubkode3(''); getSetSubkodeList(e.target.value, 1);} }>
                          {kodelist.map((option) => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                          ))}
                      </Select>
                      { subkode1list!=''&&kode!=''&&
                            <Select borderRadius="16px" placeholder="- pilih -" value={ subkode1 }
                              onChange={ (e) => {setSubkode1(e.target.value); setSubkode2(''); setSubkode3(''); getSetSubkodeList(kode+e.target.value, 2);}}>
                                {subkode1list.map((option) => (
                                  <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </Select>
                      }
                      { subkode2list!=''&&subkode1!=''&&
                            <Select borderRadius="16px" placeholder="- pilih -" value={ subkode2 }
                              onChange={ (e) => {setSubkode2(e.target.value); setSubkode3(''); getSetSubkodeList(kode+subkode1+e.target.value, 3);}}>
                                {subkode2list.map((option) => (
                                  <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </Select>
                      }
                      { subkode3list!=''&&subkode2!=''&&
                            <Select borderRadius="16px" placeholder="- pilih -" value={ subkode3 }
                              onChange={ (e) => setSubkode3(e.target.value)}>
                                {subkode3list.map((option) => (
                                  <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </Select>
                      }
                    </Grid>
                    <FormControl id={"add"+idSelectedData+10}>
                      <FormLabel>Modifikasi Nomor Surat (Opsional)</FormLabel>
                      <Input variant='filled' id={"add"+idSelectedData+8} borderRadius="16px" value={nosurat} onChange={ (e) => setNosurat(e.target.value) } disabled={disabledModif}  mb='3' />
                    </FormControl>
                    <Grid templateColumns="repeat(2, 1fr)" gap={1}>
                      <Box w='100%'>
                        <FormLabel>Total Pembuatan Nomor</FormLabel>
                        <Input type='number' min={1} max={100} id={"add"+idSelectedData+1} placeholder="Masukkan total nomor yang ingin dibuat" borderRadius="16px" value={ambilberapa} onChange={ (e) => setAmbilberapa(e.target.value) } />
                      </Box>
                      <Box w='100%'>
                        <FormLabel>Tipe Surat</FormLabel>
                        <Select borderRadius="16px" value={isSuratBiasa} onChange={ (e) => {let current = true; if(e.target.value!=current){setIsSuratBiasa(!isSuratBiasa);current=e.target.value}}}>
                          <option value={true}>Surat Biasa</option>
                          <option value={false}>Surat Keputusan / Surat Perintah / Surat Edaran / dll.</option>
                        </Select>
                      </Box>
                    </Grid>
                    <FormLabel>Isi Ringkas</FormLabel>
                    <Input id={"add"+idSelectedData+2} placeholder="Masukkan isi surat secara ringkas" borderRadius="16px" value={isisurat} onChange={ (e) => setIsisurat(e.target.value) } />
                    <FormLabel>Lampiran</FormLabel>
                    <Input type='number' min={0} id={"add"+idSelectedData+3} placeholder="Masukkan jumlah lampiran" borderRadius="16px" value={lampiran} onChange={ (e) => setLampiran(e.target.value) } />
                    <FormLabel>Tanggal Surat</FormLabel>
                    <SingleDatepicker
                      name="date-input"
                      date={tanggalsurat}
                      onDateChange={setTanggalsurat}
                    />
                    <FormLabel>Dari</FormLabel>
                    <Input id={"add"+idSelectedData+4} placeholder="Masukkan asal surat" borderRadius="16px" value={dari} onChange={ (e) => setDari(e.target.value) } />
                    <FormLabel>Kepada</FormLabel>
                    <Input id={"add"+idSelectedData+5} placeholder="Masukkan tujuan surat" borderRadius="16px" value={kepada} onChange={ (e) => setKepada(e.target.value) } />
                    <FormControl id={"add"+idSelectedData+6}>
                      <FormLabel>File Surat (Opsional)</FormLabel>
                      <Input variant='main' h='100' mb='5' border='2px dashed' type='file' id={"uploadfile"+idSelectedData+7} placeholder="Pilih file surat" borderRadius="16px" onChange={ (e) => setFilesurat(e.target.files[0]) } />
                    </FormControl>
                  </Box>  
                  <Box w='100%'>
                    <FormLabel>Akun</FormLabel>
                    <Input id={"add"+idSelectedData+8} borderRadius="16px" value={namanya} disabled />
                    <FormLabel>Pengoleh</FormLabel>
                    <Input id={"add"+idSelectedData+9} placeholder="Masukkan nama pengoleh" borderRadius="16px" value={pengoleh} onChange={ (e) => setPengoleh(e.target.value) } />
                    <FormLabel>Paraf</FormLabel>
                    <Box border='2px dashed' borderRadius="16px" borderColor='gray.300'>
                      <SignaturePad ref={signaturePadRef} />
                      <Button m='5' mt='8' onClick = {() => signaturePadRef.current.clear()}>Ulangi Paraf</Button>
                    </Box>
                  </Box>
                </Grid>
              </FormControl>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='red' mr={3} onClick = {() => {setKode('');setSubkode1('');setSubkode2('');setSubkode3('');setDisabledModif(true);
                                                                  setIsisurat('');setAmbilberapa(1);setLampiran(0);setTanggalsurat(new Date());setDari('Lapas Kelas I Semarang');
                                                                  setKepada('');setPengoleh('');signaturePadRef.current.clear();}}>Reset Form</Button>
                <Button colorScheme='blue' mr={3} onClick = {() => {{
                    if(nosurat === '' || isisurat === '' || ambilberapa === '' || lampiran === '' || dari === '' || kepada === ''|| pengoleh === '' || signaturePadRef.current.isEmpty() || kode.length<1){
                      toast({
                      status: 'error',
                      title: 'Proses Gagal',
                      description: 'Masih ada kolom yang kosong',
                      position: 'bottom-right',
                      isClosable: true,
                      });
                    }else{
                      showConfExeDialog('Buat Surat Keluar','Simpan data dan dapatkan nomor surat keluar?',2)
                    }}}}>
                    Buat Surat Keluar
                </Button>
              <Button onClick = {() => {setKodeUPT('');setKode('');setSubkode1('');setSubkode2('');setSubkode3('');setDisabledModif(true);setStatusAddDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewUploadingDialog = () => {
    return (
      <Modal closeOnOverlayClick={false} isCentered isOpen={statusUploading}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <Center pb={5} pt={5}>
              <Spinner
                thickness='4px'
                speed='0.95s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
            </Center>
            <Center pb={5} pt={5}>
            <Text>Mengunggah...</Text>
            </Center>
          </ModalContent>
      </Modal>
    )
  }

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        {viewInfoDialog()}{viewConfExeDialog()}{viewEditDialog()}{viewAddDialog()}{viewUploadingDialog()}
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Daftar Surat Keluar
        </Text>
        <Flex justify='space-between' align='end'>
        <Button
          variant='action'
          onClick={()=>downloadToExcel()}>
          <Text
            fontSize='lg'
            as='b'>
              Ekspor Semua Data ke Excel
          </Text>
        </Button>
        <Button
          variant='action'
          onClick={()=>{
            getKodeUPTNya();
            setTimeout(() => {
              showAddDialog('Buat Surat Keluar','isi seluruh kolom dan pastikan semuanya benar karena setelah dibuat Anda tidak bisa merubah detailnya lagi.')
            }, 200);
          }}>
          <Text
            fontSize='lg'
            as='b'>
            +  Buat Surat Keluar
          </Text>
        </Button>
        </Flex>
      </Flex>
      <Table minH='600' variant='unstyled'>
        <TableCaption>
          {isPagination&&allPageNumber>=1?
            <Grid templateColumns="repeat(3, 1fr)" gap={2}>
              {currentPageNumber===1?<Button disabled><ArrowBackIcon me='2'/>Sebelum</Button>:<Button onClick={()=>{setCurrentPageNumber(currentPageNumber-1);setTimeout(() => getSukels(currentPageNumber-1), 1000);}}><ArrowBackIcon me='2'/>Sebelum</Button>}
              <Button>{currentPageNumber}</Button>
              {currentPageNumber===allPageNumber?<Button disabled>Lanjut<ArrowForwardIcon ms='2'/></Button>:<Button onClick={()=>{setCurrentPageNumber(currentPageNumber+1);setTimeout(() => getSukels(currentPageNumber+1), 1000);}}>Lanjut<ArrowForwardIcon ms='2'/></Button>}
            </Grid>
            :<Text as='b'>akhir dari tabel.</Text>
          }
        </TableCaption>
        <Thead><Tr>
          <Td>
            <Grid templateColumns="repeat(5, 1fr)" gap={2}>
              <Select borderRadius='16px' aria-label="Default" value={searchCat} onChange={ (e) => setSearchCat(e.target.value) }>
                {catOpt.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
              </Select>
              <GridItem colSpan='4'>
                <Input variant='filled' id={"search"+searchClue} placeholder="Cari Surat Keluar" borderRadius="16px" value={searchClue} onChange={ (e) => setSearchClue(e.target.value) } />
              </GridItem>
            </Grid>
            </Td>
        </Tr></Thead>
        <Tbody>
        {sukels.length<1
          ? <Tr><Td><Center mb={6}>{statusLoading?<Spinner thickness='4px' speed='0.95s' emptyColor='gray.200' color='blue.500' size='xl'/>:<>Belum ada surat keluar.</>}</Center></Td></Tr>
          : <>
              {sukels.map((datasukels, index) => (
                <Tr key={index} >
                  <Td>
                    <Card direction="row" p='6' boxShadow="md" m='2' borderRadius="lg">
                      <Grid templateColumns="repeat(2, 1fr)" gap={8}>
                        <Box w='100%'>
                          <Text as='b'>ISI RINGKAS</Text><HSeparator mb='1'/>{datasukels.isisurat}<br/><br/>
                          <Text as='b'>NOMOR SURAT</Text><HSeparator mb='1'/>{datasukels.nosurat.includes("[NO_URUT_SURAT]",0)?<>{datasukels.nosurat.replace("[NO_URUT_SURAT]",datasukels.nourut)}</>:<>{datasukels.nosurat}-{datasukels.nourut}</>}<br/><br/>
                          <Grid templateColumns="repeat(2, 1fr)" gap={1}>
                            <Box w='100%'>
                              <Text as='b'>TANGGAL SURAT</Text><HSeparator mb='1'/>{convertDate(datasukels.tanggalsurat)}<br/><br/>
                            </Box>
                            <Box w='100%'>
                              <Text as='b'>LAMPIRAN</Text><HSeparator mb='1'/>{datasukels.lampiran}<br/><br/>
                            </Box>
                          </Grid>
                          <Grid templateColumns="repeat(2, 1fr)" gap={1}>
                            <Box w='100%'>
                              <Text as='b'>DARI</Text><HSeparator mb='1'/>{datasukels.dari}<br/><br/>
                            </Box>
                            <Box w='100%'>
                              <Text as='b'>KEPADA</Text><HSeparator mb='1'/>{datasukels.kepada}<br/><br/>
                            </Box>
                          </Grid>
                          <Text as='b'>FILE SURAT</Text><HSeparator mb='1'/>{datasukels.extfile==='null'?(<>-</>):(<Button onClick={()=>saveAs(URL_WEB_BACKEND+"/uploads/sukels/file/"+datasukels.id_user+"/"+datasukels.verifid+'.'+datasukels.extfile, 'SUKEL-'+datasukels.tanggal+'-'+datasukels.nosurat+'-'+datasukels.nourut+'.'+datasukels.extfile)}><DownloadIcon/> LIHAT DISINI</Button>)}<br/><br/>
                          <Stack direction={{ base: 'column', md: 'row' }}>
                              <Button colorScheme="blue" onClick={()=>{
                                setIdSelectedData(datasukels.id);setIsisurat(datasukels.isisurat);setNosurat(datasukels.nosurat);setTanggalsurat(new Date(datasukels.tanggalsurat+' 01:00:00'));setLampiran(datasukels.lampiran);setDari(datasukels.dari);setKepada(datasukels.kepada);setTanggalBuatNomor(new Date(datasukels.tanggal+' 01:00:00'));setIdPembuatSurat(parseInt(datasukels.id_user));setPengoleh(datasukels.pengoleh);setVerifIdSurat(datasukels.verifid);
                                setTimeout(() => {
                                  showEditDialog('Edit Surat Keluar','Perbarui detail surat keluar agar sesuai.')
                                }, 200);
                                }}>EDIT</Button>
                                <Button variant="outline" colorScheme="red" onClick={()=>{
                                    setIdSelectedData(datasukels.id);
                                    setTimeout(() => {
                                      showConfExeDialog('Hapus Surat Keluar','Pastikan data yang dihapus adalah data yang tidak digunakan! Karena data yang dihapus tidak dapat dikembalikan, dan dapat membuat urutan nomor surat keluar menjadi rancu!. Yakin hapus surat '+datasukels.isisurat+' ?',3)
                                    }, 200);
                                }}>HAPUS</Button>
                          </Stack>
                        </Box>

                        <Box w='100%'>
                          <Text as='b'>TANGGAL PEMBUATAN NOMOR</Text><HSeparator mb='1'/>{convertDate(datasukels.tanggal)}<br/><br/>
                          <Text as='b'>PENGOLEH</Text><HSeparator mb='1'/>{datasukels.pengoleh}<br/><br/>
                          <Grid templateColumns="repeat(2, 1fr)" gap={1}>
                            <Box w='100%'>
                              <Text as='b'>PARAF</Text><HSeparator mb='2'/><Image src={datasukels.paraf} bgColor='white' borderRadius='16' boxSize='200' alt='paraf' draggable='false'/><br/><br/>
                            </Box>
                            <Box w='100%'>
                              <Text as='b'>LINK VERIFIKASI</Text><HSeparator mb='2'/><Box onClick={()=>downloadQRCode('qrcode verifikasi surat '+datasukels.isisurat)} as="button" ref={qrCodeRef} borderRadius="16px" bg="white" color="white" p={4} h={200}><QRCode size={160} value={URL_WEB_FORNTEND+'/#/verifikasi/sukel/'+datasukels.verifid} /></Box><br/><br/>
                            </Box>
                          </Grid>
                        </Box>
                      </Grid>
                    </Card>
                  </Td>
                </Tr>
              ))}
            </>
        }
        </Tbody>
      </Table>
    </Card>
  );
}
