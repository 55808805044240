import React, { useState, useEffect } from "react";

// Chakra imports
import { Flex, Text, Stack } from "@chakra-ui/react";

import axios from "axios";

// Assets
import banner from "assets/img/dashboards/Debit.png";

import { decryptData, URL_WEB_BACKEND } from "CustomUtil";

export default function Banner() {
  const authdata = decryptData(localStorage.getItem("authinfositu"));
  const [statusKalapasValue, setStatusKalapasValue] = useState('');
  const [namaPejabat, setNamaPejabat] = useState('');

  const getStatusKalapas = () => {
    axios.get(URL_WEB_BACKEND+'/infos/0', {
      headers: {
        'Authorization': 'Bearer '+authdata
      }
    }).then((response) => {
      setNamaPejabat(response.data.isi);
      setStatusKalapasValue(response.data.judul);
    }).catch(err => {
      console.log('Error Mendapatkan Data Status Kalapas '+err.response.status)
    });
  };

  useEffect(() => {
    getStatusKalapas();
  },[]);

  return (
    <Flex
      direction='column'
      bgImage={banner}
      bgSize='cover'
      py={{ base: "30px", md: "56px" }}
      px={{ base: "30px", md: "64px" }}
      borderRadius='30px'>
        <Text
        fontSize={{ base: "24px", md: "34px" }}
        color='white'
        mb='14px'
        fontWeight='700'
        lineHeight={{ base: "32px", md: "42px" }}>
        Status Kalapas : 
        </Text>
        <Stack>
          <Text
            fontSize={{ base: "24px", md: "34px" }}
            color='white'
            fontWeight='700'
            lineHeight={{ base: "32px", md: "42px" }}>
            {statusKalapasValue}
          </Text>
          <Text
            fontSize={{ base: "14px", md: "24px" }}
            color='white'
            mb='14px'
            fontWeight='300'
            lineHeight={{ base: "32px", md: "42px" }}>
            Bapak {namaPejabat}
          </Text>
        </Stack>
    </Flex>
  );
}
