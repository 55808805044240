import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useColorModeValue,
  Button,
  Stack,
  Spacer,
  Center,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalFooter,
} from "@chakra-ui/react";
import { InfoIcon } from '@chakra-ui/icons';
import React, { useState, useEffect } from "react";
import Card from "components/card/Card.js";
import { convertDateTime, decryptData, URL_WEB_BACKEND } from "CustomUtil";
import axios from "axios";

function Notifications() {
  
  const textColor = useColorModeValue("navy.700", "white");
  const authid = decryptData(localStorage.getItem("authidsitu"));
  const authdata = decryptData(localStorage.getItem("authinfositu"));
  const [notifData, setNotifData] = useState([]);
  const [titleDialog, setTitleDialog] = useState('title');
  const [descDialog, setDescDialog] = useState('desc');
  const [statusInfoDialog, setStatusInfoDialog] = useState(false);

  useEffect(() => {
    if (notifData.length < 1 || notifData === undefined) {
      getNotifs();
    }
  },[]);

  const getNotifs = async () => {
    const getnotif = await axios.get(URL_WEB_BACKEND+'/notifs/'+authid, {
      headers: {
        'Authorization': 'Bearer '+authdata
      }
    });
    setNotifData(getnotif.data);
  }

  const exeDelete = async (idData) =>{
      await axios.delete(URL_WEB_BACKEND+'/notifs/'+idData, {
        headers: {
          'Authorization': 'Bearer '+authdata
        }}).then(res => {
          if(res.status===200){
            setNotifData([]);
            getNotifs();
          }else{
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error Hapus dengan status '+res.response.status, res.response.messages[reskey])
          }
        }).catch(err => {
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error Hapus dengan status '+err.response.status, err.response.data.messages[errkey])
        })
  }

  function showInfoDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusInfoDialog(true);
  }
  
  const viewInfoDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusInfoDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center>
                <InfoIcon w={16} h={16} color="blue.500" />
              </Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
              <Button onClick = {() => {setStatusInfoDialog(false)}}>OK</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  return (
    <>
      <Flex
        direction='column'
        w='100%'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        {viewInfoDialog()}
        <Flex
          align={{ sm: "flex-start", lg: "center" }}
          justify='space-between'
          w='100%'
          px='22px'>
          <Text color={textColor} fontSize='xl' fontWeight='600'>
            Semua Notifikasi
          </Text>
        </Flex>
        <Table variant='unstyled'>
          <Tbody>
            {notifData.length<1
            ? <Tr><Td><Center mb={6}>Tidak ada notifikasi untuk Anda.</Center></Td></Tr>
            : <>{notifData.map((datanotif, index) => (
              <Tr key={index+1}>
                  <Td>
                    <Card direction="row" p="4" boxShadow="lg" m="2" borderRadius="lg">
                      <Stack direction="column">
                        <Stack direction="row" mb={1}>
                          <Text fontWeight="semibold" fontSize={{base: 'lg'}} mt={2}>{datanotif.judul}</Text>
                          <Spacer />
                          <Button variant="outline" colorScheme="red" borderRadius={180} onClick={()=>{exeDelete(datanotif.id);}}>x</Button>
                        </Stack>
                        <Text fontSize={{ base: 'sm' }} textAlign={'left'} maxW={'4xl'}>
                        {datanotif.isi}
                        </Text>
                        <Text fontSize={12} as='b'>
                        {convertDateTime(datanotif.tanggal)}
                        </Text>
                      </Stack>
                    </Card>
                  </Td>
                </Tr> 
            ))}</>
            }
          </Tbody>
        </Table>
      </Flex>
    </>
  );
}

export default Notifications;
