/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useCallback} from "react";
// Chakra imports
import { Box } from "@chakra-ui/react";
import TxDetail from "views/main/bmn/components/TxDetail";
import Detail from "views/main/bmn/components/Detail";
import List from "views/main/bmn/components/List";
import Banner from "views/main/bmnadmin/components/Banner";

export default function Bmn() {
  const [detail, setDetail] = useState([]);
  const [showData, setShowData] = useState(false);
  const [showDataTx, setShowDataTx] = useState(false);

  const [refreshing, setRefreshing] = useState(false);

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, [refreshing]);

  const onSetDetail = useCallback(async (data) => {
    if(data===undefined){
      setShowData(false);
    }else{
      setDetail(data);
      setShowData(true);
      if(data.tersedia==='1'){setShowDataTx(true)}else{setShowDataTx(false)}
    }
  }, [detail]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Banner/><br/><List refreshing={refreshing} onSetDetail={onSetDetail}/><br/>
      {showData?<><Detail onRefresh={onRefresh} refreshing={refreshing} detail={detail}/><br/>{showDataTx?<TxDetail onRefresh={onRefresh} refreshing={refreshing} detail={detail}/>:<TxDetail onRefresh={onRefresh} refreshing={refreshing} detail={detail}/>}</>:<></>}
    </Box>
  );
}
