/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Icon,
  SimpleGrid,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React from "react";
import {
  MdSettingsEthernet,
  MdMail,
  MdArchive,
  MdFolderShared,
  MdFactCheck,
} from "react-icons/md";
import MiniCalendar from "components/calendar/MiniCalendar";
import KodeSuratKeluarStatistik from "views/main/default/components/KodeSuratKeluarStatistik";
import SukelStatistik from "views/main/default/components/SukelStatistik";
import Banner from "views/main/default/components/Banner";
import StatusKalapas from "views/main/default/components/StatusKalapas";

import { useState, useEffect } from 'react';
import axios from "axios";
import { URL_WEB_BACKEND, decryptData } from "CustomUtil";

export default function UserReports() {
  const authdata = decryptData(localStorage.getItem("authinfositu"));
  const userid = decryptData(localStorage.getItem("authidsitu"));

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [dataInfo, setDataInfo] = useState([]);

  const [seed, setSeed] = useState(1);
  const [dataSukel, setDataSukel] = useState([]);
  const [kategoriSukel, setKategoriSukel] = useState([]);
  const [dataKodeSukel, setDataKodeSukel] = useState([]);
  const [kategoriKodeSukel, setKategoriKodeSukel] = useState([]);

  const toast = useToast();
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };
    window.addEventListener('online', handleStatusChange);
    window.addEventListener('offline', handleStatusChange);
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);

  useEffect(() => {
    getDataInfo();
  }, []);

  const getDataInfo = async () =>{
    await axios.get(URL_WEB_BACKEND+'/getallhomeinfo/'+userid, {
      headers: {
        'Authorization': 'Bearer '+authdata
      }
    }).then((response) => {
      setDataInfo(response.data);
      setDataSukel(response.data[2].map(a => parseInt(a.total_sukel)));
      setKategoriSukel(response.data[2].map(a => a.bulan));
      setDataKodeSukel(response.data[3].map(a => parseInt(a.total_kode_terpakai)));
      setKategoriKodeSukel(response.data[3].map(a => a.kode_surat_keluar));
      setSeed(Math.random());
    }).catch(err => {
      toast({
        status: 'error',
        title: 'Error status '+err.response.status,
        description: 'Data kosong.',
        position: 'bottom-right',
        isClosable: true,
      })
    });
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>

      <SimpleGrid
        columns={{ base: 2, md: 2, lg: 3, "2xl": 5 }}
        gap='20px'
        mb='20px'>

        {isOnline ? (
          <MiniStatistics
          startContent={
            <IconBox
            w='46px'
            h='46px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={MdSettingsEthernet} color='white' />}
            />
          }
          name='Koneksi Internet'
          value='Online'
        />
        ) : (
          <MiniStatistics
          startContent={
            <IconBox
            w='46px'
            h='46px'
              bg='linear-gradient(90deg, #FF6464 0%, #FF7D7D 100%)'
              icon={<Icon w='28px' h='28px' as={MdSettingsEthernet} color='white' />}
            />
          }
          name='Koneksi Internet'
          value='Offline'
        />
        )}

      <MiniStatistics
        startContent={
          <IconBox
            w='46px'
            h='46px'
            bg={boxBg}
            icon={
              <Icon w='32px' h='32px' as={MdMail} color={brandColor} />
            }
          />
        }
        growth={dataInfo[1]} name='Total Surat Keluar' value={dataInfo[0]} />
        
        <MiniStatistics
          startContent={
            <IconBox
              w='46px'
              h='46px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdArchive} color={brandColor} />
              }
            />
          }
          name='Arsip Dokumen Anda'
          value={dataInfo[5]}
        />

        <MiniStatistics
          startContent={
            <IconBox
              w='46px'
              h='46px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdFolderShared} color={brandColor} />
              }
            />
          }
          name='Dokumen yang Anda Bagikan'
          value={dataInfo[6]}
        />

      <MiniStatistics
          startContent={
            <IconBox
              w='46px'
              h='46px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdFactCheck} color={brandColor} />
              }
            />
          }
          name='BMN di bagian Anda'
          value={dataInfo[8]}
      />

      </SimpleGrid>
      
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <Banner />
        <StatusKalapas />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <SukelStatistik key={seed} dataSukel={dataSukel} kategoriSukel={kategoriSukel} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <KodeSuratKeluarStatistik key={seed} dataKodeSukel={dataKodeSukel} kategoriKodeSukel={kategoriKodeSukel}/>
          <MiniCalendar />
        </SimpleGrid>
      </SimpleGrid>
      
    </Box>
  );
}
