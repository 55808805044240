import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  //MdBarChart,
  MdPerson,
  MdGroup,
  MdHome,
  MdNoCell,
  MdBallot,
  MdNotifications,
  MdFolderShared,
  MdArchive,
  MdOutgoingMail,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/main/default";
import ProfileSetting from "views/main/profilesetting";
import ManageAdmin from "views/main/manageadmin";
import NotifInfoAdmin from "views/main/notifinfoadmin";
import ShareDoc from "views/main/sharedoc";
import SukelAdmin from "views/main/sukeladmin";
import ArchiveDoc from "views/main/archivedoc";
import BmnAdmin from "views/main/bmnadmin";

// TU & Non TU Imports
import MainDashboardTu from "views/main/defaulttu";
import MainDashboardNonTu from "views/main/defaultnontu";
import NotifInfo from "views/main/notifinfo";
import Sukel from "views/main/sukel";
import Bmn from "views/main/bmn";

// Auth Import
import SignInCentered from "views/auth/signIn";

export const routesAdmin = [
  {
    name: "Beranda",
    layout: "/main",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  {
    name: "Profil & Pengaturan",
    layout: "/main",
    path: "/profilesetting",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: ProfileSetting,
    secondary: true,
  },
  {
    name: "Notifikasi & Informasi",
    layout: "/main",
    path: "/notifinfo",
    icon: <Icon as={MdNotifications} width='20px' height='20px' color='inherit' />,
    component: NotifInfoAdmin,
  },
  {
    name: "Kelola Pengguna",
    layout: "/main",
    path: "/manageadmin",
    icon: <Icon as={MdGroup} width='20px' height='20px' color='inherit' />,
    component: ManageAdmin,
  },
  {
    name: "Surat Keluar",
    layout: "/main",
    path: "/sukel",
    icon: <Icon as={MdOutgoingMail} width='20px' height='20px' color='inherit' />,
    component: SukelAdmin,
  },
  {
    name: "Arsip Dokumen",
    layout: "/main",
    path: "/archivedoc",
    icon: <Icon as={MdArchive} width='20px' height='20px' color='inherit' />,
    component: ArchiveDoc,
  },
  {
    name: "Berbagi Dokumen",
    layout: "/main",
    path: "/sharingdoc",
    icon: <Icon as={MdFolderShared} width='20px' height='20px' color='inherit' />,
    component: ShareDoc,
  },
  {
    name: "Barang Milik Negara",
    layout: "/main",
    path: "/bmn",
    icon: <Icon as={MdBallot} width='20px' height='20px' color='inherit' />,
    component: BmnAdmin,
  },
  {
    name: "",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdNoCell} width='0px' height='0px' color='inherit' />,
    component: SignInCentered,
  },
];

export const routesTu = [
  {
    name: "Beranda",
    layout: "/main",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboardTu,
  },
  {
    name: "Profil & Pengaturan",
    layout: "/main",
    path: "/profilesetting",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: ProfileSetting,
    secondary: true,
  },
  {
    name: "Notifikasi & Informasi",
    layout: "/main",
    path: "/notifinfo",
    icon: <Icon as={MdNotifications} width='20px' height='20px' color='inherit' />,
    component: NotifInfo,
  },
  {
    name: "Surat Keluar",
    layout: "/main",
    path: "/sukel",
    icon: <Icon as={MdOutgoingMail} width='20px' height='20px' color='inherit' />,
    component: Sukel,
  },
  {
    name: "Arsip Dokumen",
    layout: "/main",
    path: "/archivedoc",
    icon: <Icon as={MdArchive} width='20px' height='20px' color='inherit' />,
    component: ArchiveDoc,
  },
  {
    name: "Berbagi Dokumen",
    layout: "/main",
    path: "/sharingdoc",
    icon: <Icon as={MdFolderShared} width='20px' height='20px' color='inherit' />,
    component: ShareDoc,
  },
  {
    name: "Barang Milik Negara",
    layout: "/main",
    path: "/bmn",
    icon: <Icon as={MdBallot} width='20px' height='20px' color='inherit' />,
    component: Bmn,
  },
];

export const routesNonTu = [
  {
    name: "Beranda",
    layout: "/main",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboardNonTu,
  },
  {
    name: "Profil & Pengaturan",
    layout: "/main",
    path: "/profilesetting",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: ProfileSetting,
    secondary: true,
  },
  {
    name: "Notifikasi & Informasi",
    layout: "/main",
    path: "/notifinfo",
    icon: <Icon as={MdNotifications} width='20px' height='20px' color='inherit' />,
    component: NotifInfo,
  },
  {
    name: "Surat Keluar",
    layout: "/main",
    path: "/sukel",
    icon: <Icon as={MdOutgoingMail} width='20px' height='20px' color='inherit' />,
    component: Sukel,
  },
  {
    name: "Arsip Dokumen",
    layout: "/main",
    path: "/archivedoc",
    icon: <Icon as={MdArchive} width='20px' height='20px' color='inherit' />,
    component: ArchiveDoc,
  },
  {
    name: "Berbagi Dokumen",
    layout: "/main",
    path: "/sharingdoc",
    icon: <Icon as={MdFolderShared} width='20px' height='20px' color='inherit' />,
    component: ShareDoc,
  },
  {
    name: "Barang Milik Negara",
    layout: "/main",
    path: "/bmn",
    icon: <Icon as={MdBallot} width='20px' height='20px' color='inherit' />,
    component: Bmn,
  },
];