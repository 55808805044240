// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Center,
  Badge,
  Stack,
  Table,
  Tbody,
  Td,
  Tr,
  Box,
} from "@chakra-ui/react";
// Custom Components
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// Assets
import navImage from "assets/img/layout/Navbar.png";
import { MdNotificationsNone, MdInfoOutline } from "react-icons/md";
import { IoMdMoon, IoMdSunny } from "react-icons/io";
import { BsGlobe } from "react-icons/bs";
import { routesAdmin, routesTu, routesNonTu } from "RouterSetter";
import { URL_WEB_BACKEND, decryptData, convertDateTime } from "CustomUtil";
import axios from "axios";
export default function HeaderLinks(props) {
  const [routes, setRoutes] = useState(routesAdmin);
  const history = useHistory();

  const setTheRoutes = () => {
    const data = decryptData(localStorage.getItem("authrolesitu"));
        if(data !== null){
          if(data==='admin'){
            setRoutes(routesAdmin);
          }else if(data==='tu'){
            setRoutes(routesTu);
          }else{
            setRoutes(routesNonTu);
          }
        }else{
          setRoutes(routesAdmin);
        }
  };

  useEffect(() => {
    setTheRoutes();
  }, []);
  const [ip, setIP] = useState('-.-.-.-');
  const authdata = decryptData(localStorage.getItem("authinfositu"));
  const namanya = decryptData(localStorage.getItem("authfullnamesitu"));
  const userid = decryptData(localStorage.getItem("authidsitu"));
  const { secondary } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode
  const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.700", "brand.400");
  const ipColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const ipBg = useColorModeValue("secondaryGray.300", "navy.900");
  const ipBox = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const borderButton = useColorModeValue("secondaryGray.500", "whiteAlpha.200");
  // mine
  const logOutFunc = () =>{
    setStatusConfLogout(false)
    setStatusLoading(true)
    localStorage.clear()
    setTimeout(() => {  window.location.reload() }, 1000)
  }
  const [statusLoading, setStatusLoading] = useState(false);
  const [statusConfLogout, setStatusConfLogout] = useState(false);
  const [notifs, setNotifs] = useState([]);
  const [countNotif, setCountNotif] = useState(0);
  const filterNotifs = notifs.filter(notif => notif.read === '0');
  const [infos, setInfos] = useState([]);
  const [countInfo, setCountInfo] = useState(0);

  const getDataIP = async () => {
    await axios.get('https://geolocation-db.com/json/').then(res=>setIP(res.data.IPv4))
  }

  const getNotifs = async () => {
    const getnotif = await axios.get(URL_WEB_BACKEND+'/notifs/'+userid, {
      headers: {
        'Authorization': 'Bearer '+authdata
      }
    }).then( resp => {
      setNotifs(resp.data);setCountNotif(resp.data.filter(notif => notif.read != 1).length);
    });
  }

  const clearNotifs = async () => {
    await axios.get(URL_WEB_BACKEND+'/readallnotif/'+userid, {
      headers: {
        'Authorization': 'Bearer '+authdata
      }
    }).then(() => {
      getNotifs();setCountNotif(0);
    });
  }

  const getInfos = async () => {
    await axios.get(URL_WEB_BACKEND+'/infos', {
      headers: {
        'Authorization': 'Bearer '+authdata
      }
    }).then( resp => {
      setInfos(resp.data.filter(info => info.id != 0));setCountInfo(resp.data.filter(info => info.id != 0).length);
    });
  }
  
  useEffect( () => {
    getDataIP();getNotifs();getInfos();
  }, [])

  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems='center'
      flexDirection='row'
      bg={menuBg}
      flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
      p='10px'
      borderRadius='30px'
      boxShadow={shadow}>
      <Modal closeOnOverlayClick={false} isCentered isOpen={statusLoading}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <Center pb={5} pt={5}>
              <Spinner
                thickness='4px'
                speed='0.95s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
            </Center>
          </ModalContent>
      </Modal>
      <Modal closeOnOverlayClick={false} isCentered isOpen={statusConfLogout}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>Keluar</ModalHeader>
            <ModalBody>
              <Text fontSize='large'>Yakin ingin keluar dari aplikasi?</Text>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme='red' mr={3} onClick = {() => {logOutFunc()}}>
                Iya, Keluar
              </Button>
              <Button onClick = {() => {setStatusConfLogout(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
      <SearchBar
        mb={secondary ? { base: "10px", md: "unset" } : "unset"}
        me='6px'
        borderRadius='30px'
      />
      <Flex
        bg={ipBg}
        display={secondary ? "flex" : "none"}
        borderRadius='30px'
        ms='auto'
        p='6px'
        align='center'
        me='6px'>
        <Flex
          align='center'
          justify='center'
          bg={ipBox}
          h='29px'
          w='29px'
          borderRadius='30px'
          me='7px'>
          <Icon color={ipColor} w='15px' h='15px' as={BsGlobe} />
        </Flex>
        <Text
          w='max-content'
          color={ipColor}
          fontSize='sm'
          fontWeight='500'
          me='6px'>
          IP : {ip}
        </Text>
      </Flex>
      <SidebarResponsive routes={routes} />
      <Menu>
        <MenuButton me='10px'>
          <Icon
            mt='6px'
            as={MdNotificationsNone}
            color={navbarIcon}
            w='20px'
            h='20px'
          />
          {countNotif>0 ? 
          <Badge alignContent='center' fontSize='0.6em' variant="subtle" colorScheme="blue">
            {countNotif}
          </Badge> : <></> }
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p='20px'
          borderRadius='20px'
          bg={menuBg}
          border='none'
          mt='22px'
          me={{ base: "30px", md: "unset" }}
          minW={{ base: "unset", md: "400px", xl: "450px" }}
          maxW={{ base: "360px", md: "unset" }}>
          <Flex jusitfy='space-between' w='100%' mb='20px'>
            <Text fontSize='md' fontWeight='600' color={textColor}>
              Notifikasi Terbaru
            </Text>
            {filterNotifs.length>0? <Text
              fontSize='sm'
              fontWeight='500'
              color={textColorBrand}
              ms='auto'
              cursor='pointer'
              onClick={()=>{clearNotifs()}}>
                Tandai terbaca
            </Text> : <></>}
          </Flex>
          <Flex flexDirection='column'>
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              px='0'
              borderRadius='8px'
              mb='10px'>
              <Box overflowX="auto" maxH='400' ms='-6' me='-4'>
                <Table variant='unstyled'>
                  <Tbody>
                    {filterNotifs.length<1
                      ? <Tr><Td><Center mb={6}>Semua notifikasi sudah dibaca.</Center></Td></Tr>
                      : <>{filterNotifs.map((datanotif, index) => (
                        <Tr key={index+1}>
                            <Td>
                              <Stack direction="column" p="2" boxShadow="md" borderRadius="lg">
                                    <Text fontSize={{base: 'xl'}} fontWeight="bold">{datanotif.judul}</Text>
                                    <Text mt={4} ms={8} fontSize={{ base: 'md' }} textAlign={'start'} maxW={'4xl'}>
                                      {datanotif.isi}
                                    </Text>
                                    <Text mt={4} fontSize={12} as='b'>
                                      {convertDateTime(datanotif.tanggal)}
                                    </Text>
                              </Stack>
                            </Td>
                        </Tr>
                      ))}</>
                      }
                  </Tbody>
                </Table>
              </Box>
            </MenuItem>
            <Button
                w='100%'
                h='44px'
                mb='10px'
                border='1px solid'
                bg='transparent'
                borderColor={borderButton}
                onClick={()=>{history.push("/main/notifinfo")}}>
                Lihat Semua Notifikasi
              </Button>
          </Flex>
        </MenuList>
      </Menu>

      <Menu>
        <MenuButton me='10px'>
          <Icon
            mt='6px'
            as={MdInfoOutline}
            color={navbarIcon}
            w='20px'
            h='20px'
          />
          {countInfo>0 ? 
          <Badge alignContent='center' fontSize='0.6em' variant="subtle" colorScheme="blue">
            {countInfo}
          </Badge> : <></> }
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p='20px'
          me={{ base: "30px", md: "unset" }}
          borderRadius='20px'
          bg={menuBg}
          border='none'
          mt='22px'
          minW={{ base: "unset" }}
          maxW={{ base: "360px", md: "unset" }}>
          <Image src={navImage} borderRadius='16px' w='100%' draggable='false' />
          <MenuItem
            _hover={{ bg: "none" }}
            _focus={{ bg: "none" }}
            px='0'
            borderRadius='8px'
            mb='10px'></MenuItem>
            <Flex flexDirection='column'>
              <Box overflowX="auto" maxH='200' ms='-6' me='-4'>
                <Table variant='unstyled'>
                  <Tbody>
                    {infos.length<1
                      ? <Tr><Td><Center mb={6}>Tidak ada informasi.</Center></Td></Tr>
                      : <>{infos.map((datainfo, index) => (
                        <Tr key={index+1}>
                            <Td>
                              <Stack direction="column" p="2" boxShadow="md" borderRadius="lg" maxW='300'>
                                    <Text fontSize={{base: 'xl'}} fontWeight="bold">{datainfo.judul}</Text>
                                    <Text mt={4} ms={8} fontSize={{ base: 'md' }} textAlign={'start'} maxW={'4xl'}>
                                      {datainfo.isi}
                                    </Text>
                                    <Text mt={4} fontSize={12} as='b'>
                                      {convertDateTime(datainfo.tanggal)}
                                    </Text>
                              </Stack>
                            </Td>
                        </Tr>
                      ))}</>
                      }
                  </Tbody>
                </Table>
                </Box>
                <Button
                  w='100%'
                  h='44px'
                  mb='10px'
                  border='1px solid'
                  bg='transparent'
                  borderColor={borderButton}
                  onClick={()=>{history.push("/main/notifinfo")}}>
                  Lihat Semua Informasi
                </Button>
            </Flex>
        </MenuList>
      </Menu>

      <Button
        variant='no-hover'
        bg='transparent'
        p='0px'
        minW='unset'
        minH='unset'
        h='18px'
        w='max-content'
        onClick={toggleColorMode}>
        <Icon
          me='10px'
          h='18px'
          w='18px'
          color={navbarIcon}
          as={colorMode === "light" ? IoMdMoon : IoMdSunny}
        />
      </Button>
      <Menu>
        <MenuButton p='0px'>
          <Avatar
            _hover={{ cursor: "pointer" }}
            color='white'
            name={namanya}
            bg='#11047A'
            size='sm'
            w='40px'
            h='40px'
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p='0px'
          mt='10px'
          borderRadius='20px'
          bg={menuBg}
          border='none'>
          <Flex w='100%' mb='0px'>
            <Text
              ps='20px'
              pt='16px'
              pb='10px'
              w='100%'
              borderBottom='1px solid'
              borderColor={borderColor}
              fontSize='sm'
              fontWeight='700'
              color={textColor}>
              👋&nbsp; Halo, {namanya}
            </Text>
          </Flex>
          <Flex flexDirection='column' p='10px'>
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              color='red.400'
              borderRadius='8px'
              px='14px'
              onClick = {() => {setStatusConfLogout(true)}}>
              <Text fontSize='sm'>KELUAR</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
