/* eslint-disable */
import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  TableCaption,
  Center,
  Stack,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
  Grid,
  GridItem,
  Box,
  Spinner,
  Image,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { HSeparator } from "components/separator/Separator";
import { CheckIcon, InfoIcon, QuestionIcon, DownloadIcon, ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import React, { useEffect, useState, useRef } from "react";
import QRCode from "react-qr-code";
import html2canvas from 'html2canvas';
import { convertDate, URL_WEB_BACKEND, decryptData } from "CustomUtil";
import axios from "axios";
import { URL_WEB_FORNTEND } from "CustomUtil";
import {utils as xlxUtils, writeFile as xlxWriteFile, write as xlxWrite} from "xlsx";

export default function SukelsTable({refreshing}) {
  const authdata = decryptData(localStorage.getItem("authinfositu"));
  const userfullname = decryptData(localStorage.getItem("authfullnamesitu"));
  const userid = decryptData(localStorage.getItem("authidsitu"));

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  const textColor = useColorModeValue("secondaryGray.900", "white");

  const [sukels, setSukels] = useState([]);
  const [idSelectedData, setIdSelectedData] = useState(0);
  const [titleDialog, setTitleDialog] = useState('title');
  const [descDialog, setDescDialog] = useState('desc');
  const [statusInfoDialog, setStatusInfoDialog] = useState(false);
  const [infoDialogIcon, setInfoDialogIcon] = useState(1);
  const [statusConfExeDialog, setStatusConfExeDialog] = useState(false);
  const [statusEditDialog, setStatusEditDialog] = useState(false);
  const [statusUploading, setStatusUploading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const qrCodeRef = useRef();
  const toast = useToast();

  const [isisurat, setIsisurat] = useState('');
  const [lampiran, setLampiran] = useState('');
  const [tanggalsurat, setTanggalsurat] = useState(new Date());
  const [dari, setDari] = useState('Lapas Kelas I Semarang');
  const [kepada, setKepada] = useState('');
  const [pengoleh, setPengoleh] = useState('');
  const [tanggalBuatNomor, setTanggalBuatNomor] = useState(new Date());
  const [filesurat, setFilesurat] = useState(null);
  const [idPembuatSurat, setIdPembuatSurat] = useState(0);
  const [verifIdSurat, setVerifIdSurat] = useState('');
  const [nosurat, setNosurat] = useState('');

  // pagination
  const [isPagination, setIsPagination] = useState(true);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [allPageNumber, setAllPageNumber] = useState(0);

  // search
  const [searchClue, setSearchClue] = useState('');
  const [searchCat, setSearchCat] = useState('isisurat');

  const catOpt = [
    { value: 'isisurat', label: 'Isi Ringkas' },
    { value: 'nosurat', label: 'Nomor Surat' },
    { value: 'kepada', label: 'Penerima Surat' },
    { value: 'pengoleh', label: 'Pengoleh' },
  ];

  useEffect(() => {
    if (refreshing || sukels.length < 1 || sukels === undefined) {
      getSukels(1);
    }
  },[refreshing]);

  useEffect(() => {
    getSukels(1);
  },[searchClue]);

  const getSukels = async (pagesNumber) => {
    setStatusLoading(true);
    setSukels([]);
    if(searchClue===''){
      setIsPagination(true);
      await axios.get(URL_WEB_BACKEND+'/sukelsfilteruserid/'+userid+'?page='+pagesNumber, {
        headers: {
          'Authorization': 'Bearer '+authdata
        }
      }).then((response) => {
        setSukels(response.data.sukels);
        setAllPageNumber(response.data.pages);
        setStatusLoading(false);
      }).catch(err => {
        setStatusLoading(false);
        toast({
          status: 'error',
          title: 'Error status '+err.response.status,
          description: 'Data kosong.',
          position: 'bottom-right',
          isClosable: true,
        })
      });
    }else{
      await axios.get(URL_WEB_BACKEND+'/carifilteruserid/sukels/'+searchCat+'/'+searchClue+'/'+userid+'?page='+pagesNumber, {
        headers: {
          'Authorization': 'Bearer '+authdata
        }
      }).then((response) => {
        setIsPagination(true);
        setSukels(response.data.sukels);
        setAllPageNumber(response.data.pages);
        setStatusLoading(false);
      }).catch(err => {
        setStatusLoading(false);
        toast({
          status: 'error',
          title: 'Error status '+err.response.status,
          description: 'Tak ada data yang dicari.',
          position: 'bottom-right',
          isClosable: true,
        })
      });
    }
  }

  const exeSendnotif = async (title, desc) => {
    await axios.post(URL_WEB_BACKEND+'/notifs',{
      id_user : userid,
      judul: title,
      isi: desc,
      tanggal : new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' '),
      read : 0
    }, {
      headers: {
        'Authorization': 'Bearer '+authdata
      }}).then(res => {
        if(res.status===201){
          setStatusLoading(false);
          getSukels(currentPageNumber);
          showInfoDialog('File Surat Diunggah','File surat berhasil diunggah / diganti', 0);
        }else{
          let reskey = Object.keys(res.response.data.messages)[0];
          showInfoDialog('Error Mengirim Notifikasi dengan status '+res.response.status, res.response.messages[reskey], 1)
        }
      }).catch(err => {
        let errkey = Object.keys(err.response.data.messages)[0];
        showInfoDialog('Error Mengirim Notifikasi dengan status '+err.response.status, err.response.data.messages[errkey], 1)
      })
  }

  const exeEdit = async () =>{
    setStatusUploading(true);setStatusEditDialog(false);setStatusConfExeDialog(false);
    const formDataEdit = new FormData();
    formDataEdit.append('tanggal', new Date(tanggalBuatNomor.getTime() - tanggalBuatNomor.getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' '))
    formDataEdit.append('nosurat', nosurat.toUpperCase().replace(/\s+/g,""))
    formDataEdit.append('tanggalsurat', new Date(tanggalsurat.getTime() - tanggalsurat.getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' '))
    formDataEdit.append('isisurat', isisurat)
    formDataEdit.append('lampiran', lampiran)
    formDataEdit.append('dari', dari)
    formDataEdit.append('kepada', kepada)
    formDataEdit.append('id_user', idPembuatSurat)
    formDataEdit.append('pengoleh', pengoleh)
    formDataEdit.append('verifid', verifIdSurat)
    if(filesurat!=null){
      formDataEdit.append('filesurat', filesurat)
    }
      await axios.post(URL_WEB_BACKEND+'/updatesukels/'+idSelectedData, formDataEdit, {
        headers: {
          'Authorization': 'Bearer '+authdata,
          'content-type': 'application/ x-www-form-urlencoded'
        }}).then(res => {
          if(res.status===200){
            exeSendnotif('File Surat Berhasil Diunggah', 'Surat Anda perihal '+isisurat+' telah diunggah / diganti file suratnya.');
          }else{
            setStatusUploading(false);
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error Perbarui Surat keluar dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          setStatusUploading(false);
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error Perbarui Surat keluar dengan status '+err.response.status, err.response.data.messages[errkey], 1)
        })
  }

  const downloadQRCode = async (namafile) => {
    const element = qrCodeRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/jpg');
    const link = document.createElement('a');
    if (typeof link.download === 'string') {
      link.href = data;
      link.download = namafile+'.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  const downloadToExcel = async () => {
    await axios.get(URL_WEB_BACKEND+'/getallsukelsbyuserid/'+userid, {
      headers: {
        'Authorization': 'Bearer '+authdata
      }
    }).then((response) => {
      const worksheet = xlxUtils.json_to_sheet(response.data);
      const workbook = xlxUtils.book_new();
      xlxUtils.book_append_sheet(workbook, worksheet, "Sheet1");
      // let buffer = xlxWrite(workbook, { bookType: "xlsx", type: "buffer" });
      // xlxWrite(workbook, { bookType: "xlsx", type: "binary" });
      xlxWriteFile(workbook, "Semua Surat Keluar Oleh "+userfullname+" Pertanggal "+new Date().toISOString().slice(0, 10)+".xlsx");
    }).catch(err => {
      toast({
        status: 'error',
        title: 'Error status '+err.response.status,
        description: 'Data Semua Surat Keluar kosong.',
        position: 'bottom-right',
        isClosable: true,
      })
    });
  };

  function showInfoDialog (title, desc, infoicon) {
    setTitleDialog(title);setDescDialog(desc);setInfoDialogIcon(infoicon);setStatusInfoDialog(true);
  }

  function showConfExeDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusConfExeDialog(true);
  }

  function showEditDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusEditDialog(true);
  }

  const viewInfoDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusInfoDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center>
                {infoDialogIcon
                  ? <InfoIcon w={16} h={16} color="blue.500" />
                  : <CheckIcon w={16} h={16} color="green.500" />
                }
              </Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
              <Button onClick = {() => {setStatusInfoDialog(false)}}>OK</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewConfExeDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusConfExeDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center><QuestionIcon w={16} h={16} color="blue.500" /></Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='red' mr={3} onClick = {() => exeEdit()}>
                    Iya, Lanjutkan
                </Button>
              <Button onClick = {() => {setStatusConfExeDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewEditDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusEditDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                textAlign='start'
                fontSize='2xl'
                mt={{ base: "5px", "2xl": "5px" }}>
                {titleDialog}
              </Text>
              <Text
                color={textColorSecondary}
                fontSize='md'
                my={{ base: "auto", "2xl": "10px" }}
                textAlign='start'>
                {descDialog}
              </Text>  
            </ModalHeader>
            <ModalBody>
              <FormControl id={"editsukel"+idSelectedData}>
                <FormLabel>File Surat (pdf, doc, docx)</FormLabel>
                  <Input variant='main' h='100' mb='5' border='2px dashed' type='file' id={"uploadfile"+idSelectedData+5} placeholder="Pilih file surat" borderRadius="16px" onChange={ (e) => setFilesurat(e.target.files[0]) } />
                </FormControl>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick = {() => {{
                  if(nosurat === '' || isisurat === '' || lampiran === '' || dari === '' || kepada === ''|| pengoleh === '' || filesurat===null){toast({
                    status: 'error',
                    title: 'Proses Gagal',
                    description: 'Masih ada kolom yang kosong',
                    position: 'bottom-right',
                    isClosable: true,
                    })}else{showConfExeDialog('Unggah File Surat','Simpan file surat?')}}}}>
                    Simpan
                </Button>
              <Button onClick = {() => {setStatusEditDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewUploadingDialog = () => {
    return (
      <Modal closeOnOverlayClick={false} isCentered isOpen={statusUploading}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <Center pb={5} pt={5}>
              <Spinner
                thickness='4px'
                speed='0.95s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
            </Center>
            <Center pb={5} pt={5}>
            <Text>Mengunggah...</Text>
            </Center>
          </ModalContent>
      </Modal>
    )
  }

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        {viewInfoDialog()}{viewConfExeDialog()}{viewEditDialog()}{viewUploadingDialog()}
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Daftar Surat Keluar Milik Anda
        </Text>
        <Button
          variant='action'
          onClick={()=>downloadToExcel()}>
          <Text
            fontSize='lg'
            as='b'>
              Ekspor Semua Data ke Excel
          </Text>
        </Button>
      </Flex>
      <Table minH='600' variant='unstyled'>
        <TableCaption>
          {isPagination&&allPageNumber>=1?
            <Grid templateColumns="repeat(3, 1fr)" gap={2}>
              {currentPageNumber===1?<Button disabled><ArrowBackIcon me='2'/>Sebelum</Button>:<Button onClick={()=>{setCurrentPageNumber(currentPageNumber - 1);getSukels(currentPageNumber - 1);}}><ArrowBackIcon me='2'/>Sebelum</Button>}
              <Button>{currentPageNumber}</Button>
              {currentPageNumber===allPageNumber?<Button disabled>Lanjut<ArrowForwardIcon ms='2'/></Button>:<Button onClick={()=>{setCurrentPageNumber(currentPageNumber + 1);getSukels(currentPageNumber + 1);}}>Lanjut<ArrowForwardIcon ms='2'/></Button>}
            </Grid>
            :<Text as='b'>akhir dari tabel.</Text>
          }
        </TableCaption>
        <Thead><Tr>
          <Td>
          <Grid templateColumns="repeat(5, 1fr)" gap={2}>
              <Select borderRadius='16px' aria-label="Default" value={searchCat} onChange={ (e) => setSearchCat(e.target.value) }>
                {catOpt.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
              </Select>
              <GridItem colSpan='4'>
                <Input variant='filled' id={"search"+searchClue} placeholder="Cari Surat Keluar" borderRadius="16px" value={searchClue} onChange={ (e) => setSearchClue(e.target.value) } />
              </GridItem>
            </Grid>  
          </Td>
        </Tr></Thead>
        <Tbody>
        {sukels.length<1
          ? <Tr><Td><Center mb={6}>{statusLoading?<Spinner thickness='4px' speed='0.95s' emptyColor='gray.200' color='blue.500' size='xl'/>:<>Belum ada surat keluar.</>}</Center></Td></Tr>
          : <>
              {sukels.map((datasukels, index) => (
                <Tr key={index} >
                  <Td>
                    <Card direction="row" p='6' boxShadow="md" m='2' borderRadius="lg">
                      <Grid templateColumns="repeat(2, 1fr)" gap={8}>
                        <Box w='100%'>
                          <Text as='b'>ISI RINGKAS</Text><HSeparator mb='1'/>{datasukels.isisurat}<br/><br/>
                          <Text as='b'>NOMOR SURAT</Text><HSeparator mb='1'/>{datasukels.nosurat.includes("[NO_URUT_SURAT]",0)?<>{datasukels.nosurat.replace("[NO_URUT_SURAT]",datasukels.nourut)}</>:<>{datasukels.nosurat}-{datasukels.nourut}</>}<br/><br/>
                          <Grid templateColumns="repeat(2, 1fr)" gap={1}>
                            <Box w='100%'>
                              <Text as='b'>TANGGAL SURAT</Text><HSeparator mb='1'/>{convertDate(datasukels.tanggalsurat)}<br/><br/>
                            </Box>
                            <Box w='100%'>
                              <Text as='b'>LAMPIRAN</Text><HSeparator mb='1'/>{datasukels.lampiran}<br/><br/>
                            </Box>
                          </Grid>
                          <Grid templateColumns="repeat(2, 1fr)" gap={1}>
                            <Box w='100%'>
                              <Text as='b'>DARI</Text><HSeparator mb='1'/>{datasukels.dari}<br/><br/>
                            </Box>
                            <Box w='100%'>
                              <Text as='b'>KEPADA</Text><HSeparator mb='1'/>{datasukels.kepada}<br/><br/>
                            </Box>
                          </Grid>
                          <Text as='b'>FILE SURAT</Text><HSeparator mb='1'/>{datasukels.extfile==='null'?(<>-</>):(<Button onClick={()=>saveAs(URL_WEB_BACKEND+"/uploads/sukels/file/"+datasukels.id_user+"/"+datasukels.verifid+'.'+datasukels.extfile, 'SUKEL-'+datasukels.tanggal+'-'+datasukels.nosurat+'-'+datasukels.nourut+'.'+datasukels.extfile)}><DownloadIcon/> LIHAT DISINI</Button>)}<br/><br/>
                          <Stack direction={{ base: 'column', md: 'row' }}>
                              {datasukels.id_user===userid?<Button colorScheme="blue" onClick={()=>{
                                setIdSelectedData(datasukels.id);setIsisurat(datasukels.isisurat);setNosurat(datasukels.nosurat);setTanggalsurat(new Date(datasukels.tanggalsurat+' 01:00:00'));setLampiran(datasukels.lampiran);setDari(datasukels.dari);setKepada(datasukels.kepada);setTanggalBuatNomor(new Date(datasukels.tanggal+' 01:00:00'));setIdPembuatSurat(parseInt(datasukels.id_user));setPengoleh(datasukels.pengoleh);setVerifIdSurat(datasukels.verifid);
                                setTimeout(() => {
                                  showEditDialog('Unggah File Surat','Unggah / Ganti File Surat jika diperlukan.')
                                }, 200);
                                }}>UNGGAH FILE SURAT</Button>
                              :<></>}
                          </Stack>
                        </Box>

                        <Box w='100%'>
                          <Text as='b'>TANGGAL PEMBUATAN NOMOR</Text><HSeparator mb='1'/>{convertDate(datasukels.tanggal)}<br/><br/>
                          <Text as='b'>PENGOLEH</Text><HSeparator mb='1'/>{datasukels.pengoleh}<br/><br/>
                          <Grid templateColumns="repeat(2, 1fr)" gap={1}>
                            <Box w='100%'>
                              <Text as='b'>PARAF</Text><HSeparator mb='2'/><Image src={datasukels.paraf} bgColor='white' borderRadius='16' boxSize='200' alt='paraf' draggable='false'/><br/><br/>
                            </Box>
                            <Box w='100%'>
                              <Text as='b'>LINK VERIFIKASI</Text><HSeparator mb='2'/><Box onClick={()=>downloadQRCode('qrcode verifikasi surat '+datasukels.isisurat)} as="button" ref={qrCodeRef} borderRadius="16px" bg="white" color="white" p={4} h={200}><QRCode size={160} value={URL_WEB_FORNTEND+'/#/verifikasi/sukel/'+datasukels.verifid} /></Box><br/><br/>
                            </Box>
                          </Grid>
                        </Box>
                      </Grid>
                    </Card>
                  </Td>
                </Tr>
              ))}
            </>
          }
        </Tbody>
      </Table>
    </Card>
  );
}
