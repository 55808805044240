// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
  Center,
  Grid,
  Box,
  Select,
  Badge,
  useToast,
  Table,
  Tr,
  Stack,
  Td,
  Tbody,
  Spinner,
  Image,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalFooter,
  Textarea,
  SimpleGrid,
  Spacer
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React, {useState, useEffect} from "react";
import { ArrowBackIcon, ArrowForwardIcon, CheckIcon, InfoIcon, QuestionIcon } from '@chakra-ui/icons';
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import axios from "axios";
import { decryptData, URL_WEB_BACKEND } from "CustomUtil";

export default function ListAnAddBmn({refreshing, onSetDetail}) {
  const authdata = decryptData(localStorage.getItem("authinfositu"));
  const rolenya = decryptData(localStorage.getItem("authrolesitu"));
  const toast = useToast();

  const [titleDialog, setTitleDialog] = useState('title');
  const [descDialog, setDescDialog] = useState('desc');

  const [filterBmnDataAktif, setFilterBmnDataAktif] = useState([]);
  const [filterBmnDataNonaktif, setFilterBmnDataNonaktif] = useState([]);

  const [statusAddDialog, setStatusAddDialog] = useState(false);
  const [infoDialogIcon, setInfoDialogIcon] = useState(1);
  const [statusInfoDialog, setStatusInfoDialog] = useState(false);
  const [statusConfExeDialog, setStatusConfExeDialog] = useState(false);
  const [actionConfExeDialog, setActionConfExeDialog] = useState(1);
  const [idSelectedData, setIdSelectedData] = useState(0);
  const [statusLoading, setStatusLoading] = useState(false);
  const [statusUploading, setStatusUploading] = useState(false);
  const [imageHashToRefresh, setImageHashToRefresh] = useState(new Date().getTime());

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  // pagination
  const [isPagination, setIsPagination] = useState(true);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [allPageNumber, setAllPageNumber] = useState(0);

  // add
  const [noreg, setNoreg] = useState('');
  const [nama, setNama] = useState('');
  const [tanggal, setTanggal] = useState(new Date());
  const [jumlah, setJumlah] = useState(1);
  const [satuan, setSatuan] = useState('');
  const [jenis, setJenis] = useState('tetap');
  const [kondisi, setKondisi] = useState('baik');
  const [sumber, setSumber] = useState('');
  const [in_id_user, setIn_id_user] = useState();
  const [keterangan, setKeterangan] = useState('');
  const [filenya, setFilenya] = useState(null);
  const [fotonya, setFotonya] = useState(null);

  const [userList, setuserList] = useState([]);

  useEffect(() => {
    getBmnwithSearch(currentPageNumber, '');
    getUserList();
  },[]);

  useEffect(() => {
    if(refreshing){
      getBmnwithSearch(currentPageNumber, '');setImageHashToRefresh(new Date().getTime());
    }
  },[refreshing]);

  const getUserList = () => {
    axios.get(URL_WEB_BACKEND+'/users', {
      headers: {
        'Authorization': 'Bearer '+authdata
      }
    }).then((response) => {
      setuserList(response.data.map(user => ({label: user.fullname, value: user.id})));
      setIn_id_user(response.data[0].id);
    }).catch(err => {
      toast({
        status: 'error',
        title: 'Error status '+err.response.status,
        description: 'Data daftar user kosong.',
        position: 'bottom-right',
        isClosable: true,
      });
      setuserList([]);
    });
  };

  const kondisiOPt = [
    { value: 'baik', label: 'Baik' },
    { value: 'rusak ringan', label: 'Rusak Ringan' },
    { value: 'rusak berat', label: 'Rusak Berat' },
  ];

  const jenisOPt = [
    { value: 'tetap', label: 'Tetap' },
    { value: 'persediaan', label: 'Persediaan' }
  ];

  const getBmnwithSearch = async (pagesNumber, searchClue) => {
    if(searchClue === ''){
      getBmn();
    }else{
    setStatusLoading(true);setFilterBmnDataAktif([]);setFilterBmnDataNonaktif([]);
    await axios.get(URL_WEB_BACKEND+'/cari/bmns/nama/'+searchClue+'?page='+pagesNumber, {
      headers: {
        'Authorization': 'Bearer '+authdata
      }
    }).then((response) => {
      setFilterBmnDataAktif(response.data.bmns.filter(bmn => bmn.tersedia === '1'));
      setFilterBmnDataNonaktif(response.data.bmns.filter(bmn => bmn.tersedia === '0'));
      setAllPageNumber(response.data.pages);
      setIsPagination(true);
      setStatusLoading(false);
    }).catch(err => {
      setStatusLoading(false);
      onSetDetail([]);
      toast({
        status: 'error',
        title: 'Error status '+err.response.status,
        description: 'Tak ada data yang dicari.',
        position: 'bottom-right',
        isClosable: true,
      })
    });
    }
  };

  const getBmn = async (pagesNumber) => {
    setStatusLoading(true);
    await axios.get(URL_WEB_BACKEND+'/bmns'+'?page='+pagesNumber, {
      headers: {
        'Authorization': 'Bearer '+authdata
      }
    }).then((response) => {
      onSetDetail(response.data.bmns[0]);
      setFilterBmnDataAktif(response.data.bmns.filter(bmn => bmn.tersedia === '1'));
      setFilterBmnDataNonaktif(response.data.bmns.filter(bmn => bmn.tersedia === '0'));
      setAllPageNumber(response.data.pages);
      setIsPagination(true);
      setStatusLoading(false);
    }).catch(err => {
      setStatusLoading(false);
      onSetDetail([]);
      setIsPagination(false);
      toast({
        status: 'error',
        title: 'Error status '+err.response.status,
        description: 'Data BMN kosong.',
        position: 'bottom-right',
        isClosable: true,
      })
    });
  }

  function makeVerifId(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const exeAdd = async () => {
    setStatusUploading(true);setStatusAddDialog(false);setStatusConfExeDialog(false);
    const formData = new FormData();
      formData.append('noreg', noreg)
      formData.append('nama', nama)
      formData.append('jumlah', jumlah)
      formData.append('satuan', satuan)
      formData.append('jenis', jenis)
      formData.append('tersedia', 1)
      formData.append('kondisi', kondisi)
      formData.append('sumber', sumber)
      formData.append('in_id_user', in_id_user)
      formData.append('keterangan', keterangan)
      formData.append('tanggal', new Date(tanggal.getTime() - tanggal.getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' '))
      formData.append('verifid', makeVerifId(30))
      formData.append('fotonya', fotonya)
      formData.append('filenya', filenya)
    await axios.post(URL_WEB_BACKEND+'/bmns', formData, {
      headers: {
        'Authorization': 'Bearer '+authdata
      }}).then(res => {
        if(res.status===201){
          getBmn();
          setStatusUploading(false);
          showInfoDialog('Tambah Data BMN','Data Berhasil Disimpan.', 0);
        }else{
          setStatusUploading(false);
          let reskey = Object.keys(res.response.data.messages)[0];
          showInfoDialog('Error Menyimpan Data BMN dengan status '+res.response.status, res.response.messages[reskey], 1)
        }
      }).catch(err => {
        setStatusUploading(false);
        let errkey = Object.keys(err.response.data.messages)[0];
        showInfoDialog('Error Menyimpan Data BMN dengan status '+err.response.status, err.response.data.messages[errkey], 1)
      })
  }

  const exeDelete = async () =>{
    if(rolenya==='admin'){
      setStatusConfExeDialog(false);
      await axios.delete(URL_WEB_BACKEND+'/bmns/'+idSelectedData, {
        headers: {
          'Authorization': 'Bearer '+authdata
        }}).then(res => {
          if(res.status===200){
            getBmnwithSearch(currentPageNumber,'');
            showInfoDialog('Berhasil', 'Data Berhasil Dihapus', 0)
          }else{
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error Hapus dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error Hapus dengan status '+err.response.status, err.response.data.messages[errkey], 1)
      })
    }else{
      showInfoDialog('Error Hapus', 'Anda tidak memilii akses untuk ini.', 1)
    }
  }

  function showInfoDialog (title, desc, infoicon) {
    setTitleDialog(title);setDescDialog(desc);setInfoDialogIcon(infoicon);setStatusInfoDialog(true);
  }

  function showAddDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusAddDialog(true);
  }

  function showConfExeDialog (title, desc, action) {
    setTitleDialog(title);setDescDialog(desc);setStatusConfExeDialog(true);setActionConfExeDialog(action);
  }

  const viewInfoDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusInfoDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center>
                {infoDialogIcon
                  ? <InfoIcon w={16} h={16} color="blue.500" />
                  : <CheckIcon w={16} h={16} color="green.500" />
                }
              </Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
              <Button onClick = {() => {setStatusInfoDialog(false);}}>OK</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewAddDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusAddDialog} size='full'>
          <ModalContent>
            <ModalHeader>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                textAlign='start'
                fontSize='2xl'
                mt={{ base: "5px", "2xl": "5px" }}>
                {titleDialog}
              </Text>
              <Text
                color={textColorSecondary}
                fontSize='md'
                my={{ base: "auto", "2xl": "10px" }}
                textAlign='start'>
                {descDialog}
              </Text>
            </ModalHeader>
            <ModalBody>
              <FormControl id={"addbmn"+idSelectedData} isRequired>
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  <Box w='100%'>
                    <FormControl id={"addbmn"+idSelectedData+1} isRequired>
                    <FormLabel>Nama Barang</FormLabel>
                      <Input id={"addbmn"+idSelectedData+3} placeholder="Masukkan nama barang" borderRadius="16px" value={nama} onChange={ (e) => setNama(e.target.value) } />
                      <FormLabel>Nomor Urut Pendaftaran</FormLabel>
                      <Input id={"addbmn"+idSelectedData+2} placeholder="Masukkan no. urut pendaftaran" borderRadius="16px" value={noreg} onChange={ (e) => setNoreg(e.target.value) } />
                      <FormLabel>Tanggal Terima</FormLabel>
                      <SingleDatepicker
                        name="date-input"
                        date={tanggal}
                        onDateChange={setTanggal}
                      />
                      <FormLabel>Jumlah</FormLabel>
                      <Input type='number' id={"addbmn"+idSelectedData+4} placeholder="Masukkan jumlah barang" borderRadius="16px" value={jumlah} onChange={ (e) => setJumlah(e.target.value) } />
                      <FormLabel>Satuan</FormLabel>
                      <Input id={"addbmn"+idSelectedData+5} placeholder="Masukkan satuan perhitungan" borderRadius="16px" value={satuan} onChange={ (e) => setSatuan(e.target.value) } />
                      <FormLabel>Jenis Aset</FormLabel>
                      <Select id={"addbmn"+idSelectedData+6} aria-label="Default" value={ jenis } onChange={ (e) => setJenis(e.target.value) }>
                        {jenisOPt.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
                      </Select>
                      <FormLabel>Kondisi</FormLabel>
                      <Select id={"addbmn"+idSelectedData+7} aria-label="Default" value={ kondisi } onChange={ (e) => setKondisi(e.target.value) }>
                        {kondisiOPt.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
                      </Select>
                      <FormLabel>Asal Perolehan</FormLabel>
                      <Input id={"addbmn"+idSelectedData+8} placeholder="Masukkan asal aset" borderRadius="16px" value={sumber} onChange={ (e) => setSumber(e.target.value) } />
                    </FormControl>
                  </Box>  
                  <Box w='100%'>
                  <FormControl id={"addbmn"+idSelectedData+9} isRequired>
                    <FormLabel>Letak Aset (Tujuan Distribusi)</FormLabel>
                    <Select id={"addbmn"+idSelectedData+10} aria-label="Default" value={ in_id_user } onChange={ (e) => setIn_id_user(e.target.value) }>
                      {userList.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
                    </Select>
                    <FormLabel>Foto Barang</FormLabel>
                      <Input variant='main' h='100' mb='5' border='2px dashed' type='file' id={"uploadfoto"+0+5} placeholder="Pilih foto barang" borderRadius="16px" onChange={ (e) => setFotonya(e.target.files[0]) } />    
                      <FormLabel>File Administratif</FormLabel>
                      <Input variant='main' h='100' mb='5' border='2px dashed' type='file' id={"uploadfile"+0+6} placeholder="Pilih berkas" borderRadius="16px" onChange={ (e) => setFilenya(e.target.files[0]) } />
                  </FormControl>
                  <FormControl id={"addbmn"+0+2}>
                    <FormLabel>Keterangan Tambahan</FormLabel>
                    <Textarea minH='170' id={"addbmn"+idSelectedData+11} placeholder="Masukkan keterangan jika perlu" borderRadius="16px" value={keterangan} onChange={ (e) => setKeterangan(e.target.value) } />
                  </FormControl>
                  </Box>
                </Grid>
              </FormControl>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='red' mr={3} onClick = {() => {setNoreg('');setNama('');setTanggal(new Date());setJumlah(1);setSatuan('');setJenis('tetap');setKondisi('baik');setSumber('');setIn_id_user();setFotonya(null);setFilenya(null);setKeterangan('');}}>Reset Form</Button>
                <Button colorScheme='blue' mr={3} onClick = {() => {{
                      if(noreg===''||nama===''||tanggal===''||jumlah===''||satuan===''||jenis===''||kondisi===''||sumber===''||in_id_user===''||fotonya===null||filenya===null){
                        toast({
                          status: 'error',
                          title: 'Proses Gagal',
                          description: 'Masih ada kolom yang kosong',
                          position: 'bottom-right',
                          isClosable: true})
                      }else{
                        showConfExeDialog('Tambah Data Aset BMN','Simpan data BMN baru?',1)
                      }

                    }}}>
                    Simpan
                </Button>
              <Button onClick = {() => {setStatusAddDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewConfExeDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusConfExeDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center><QuestionIcon w={16} h={16} color="blue.500" /></Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='red' mr={3} onClick = {() => {{
                  if(actionConfExeDialog===1){
                    exeAdd();
                  }else{
                    exeDelete();
                  };
                  }}}>
                    Iya, Lanjutkan
                </Button>
              <Button onClick = {() => {setStatusConfExeDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewUploadingDialog = () => {
    return (
      <Modal closeOnOverlayClick={false} isCentered isOpen={statusUploading}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <Center pb={5} pt={5}>
              <Spinner
                thickness='4px'
                speed='0.95s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
            </Center>
            <Center pb={5} pt={5}>
            <Text>Mengunggah...</Text>
            </Center>
          </ModalContent>
      </Modal>
    )
  }

  return (
    <Card align='center' p='20px'>
      <Flex h='100%' direction={{ base: "column", "xl": "column" }}>
        <Flex direction='column' pe='44px'>
          <Flex justify='space-between' align='end'>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                textAlign='start'
                fontSize='2xl'
                mt={{ base: "5px", "2xl": "5px" }}>
                Daftar Aset BMN
              </Text>
              <Button
                variant='action'
                onClick={()=>{
                  setTimeout(() => {
                    showAddDialog('Tambah Data Aset BMN','isi seluruh kolom dan pastikan semuanya benar.')
                  }, 200);
                }}>
                <Text
                  fontSize='lg'
                  as='b'>
                  +  Tambah Data Aset
                </Text>
              </Button>
          </Flex>
          <Input mt='10px' variant='filled' id={"search"+101} placeholder="Cari berdasarkan nama aset" borderRadius="16px" onChange={ (e) => getBmnwithSearch(currentPageNumber, e.target.value) } />
          <Flex w='100%' mt={{ base: "20px", "2xl": "20px" }} >
          {viewAddDialog()}{viewConfExeDialog()}{viewInfoDialog()}{viewUploadingDialog()}
          <FormControl id="listbmn">
              <SimpleGrid
              mb='20px'
              columns={{ sm: 1, md: 2 }}
              spacing={{ base: "20px", xl: "20px" }}>
                    <Box w='100%' h='450'>
                      <Flex direction='column'>
                        <Badge fontSize="1em" colorScheme="green" mb='10px'>AKTIF</Badge>
                            <Table variant='unstyled'>
                              <Box overflowX="auto" maxH='350'>
                                <Tbody>
                                  {filterBmnDataAktif.length<1
                                  ? <Tr><Td><Center mb={6}>{statusLoading?<Spinner thickness='4px' speed='0.95s' emptyColor='gray.200' color='blue.500' size='xl'/>:<>Belum ada data BMN.</>}</Center></Td></Tr>
                                  : <>{filterBmnDataAktif.map((databmnaktif, index) => (
                                    <Tr key={index+1}>
                                        <Td>
                                          <Box minH='150' p="2" boxShadow="lg" borderRadius="lg">
                                            <Stack direction="row">
                                              <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={{ base: "10px", xl: "10px" }}>
                                                  <Image boxShadow="lg" borderRadius='10px' boxSize='150px' alt='Gambar Aset BMN' width='150px' height='150px' src={URL_WEB_BACKEND+"/uploads/bmns/foto/"+databmnaktif.verifid+'.'+databmnaktif.extfoto+'?t='+imageHashToRefresh} />
                                                  <Stack direction="column">
                                                    <Text fontSize={{base: 'xl'}} fontWeight="bold">{databmnaktif.nama}</Text>
                                                    <Text mt={2} ms={8} fontSize={{ base: 'md' }} textAlign={'start'} maxW={'4xl'}>
                                                      No. Urut Pendaftaran : {databmnaktif.noreg}
                                                    </Text>
                                                    <Text mt={2} ms={8} fontSize={{ base: 'md' }} textAlign={'start'} maxW={'4xl'}>
                                                      Jumlah : {databmnaktif.jumlah} {databmnaktif.satuan}
                                                    </Text>
                                                    <Text mt={2} ms={8} fontSize={{ base: 'md' }} textAlign={'start'} maxW={'4xl'}>
                                                      Kondisi : {databmnaktif.kondisi}
                                                    </Text>
                                                  </Stack>
                                                  <Stack mt='5'>
                                                    <Text mt={2} fontSize={{ base: 'md' }} textAlign={'start'} maxW={'4xl'}>
                                                      Letak : {databmnaktif.fullname}
                                                    </Text>
                                                    <Text mt={2} ms={8} fontSize={{ base: 'md' }} textAlign={'start'} maxW={'4xl'}>
                                                      Keterangan Lain : {databmnaktif.keterangan}
                                                    </Text>
                                                    <Text mt={2} ms={8} fontSize={{ base: 'md' }} textAlign={'start'} maxW={'4xl'}>
                                                      Tanggal : {databmnaktif.tanggal}
                                                    </Text>
                                                    <Stack direction='row'>
                                                      {databmnaktif.jenis==='tetap'?
                                                      <Badge maxH='30' fontSize="0.7em" colorScheme="purple">Aset Tetap</Badge>
                                                      :<Badge maxH='30' fontSize="0.7em" variant="outline" colorScheme="purple">Persediaan</Badge>
                                                      }
                                                      <Button size='sm' colorScheme="blue" onClick={()=>{
                                                        setIdSelectedData(databmnaktif.id);
                                                        setTimeout(() => {
                                                          onSetDetail(filterBmnDataAktif[index]);
                                                        }, 200);
                                                      }}>LIHAT DETAIL</Button>
                                                    </Stack>
                                                  </Stack>
                                              </SimpleGrid>
                                          </Stack>
                                          </Box>
                                        </Td>
                                    </Tr>
                                  ))}</>
                                  }
                                </Tbody>
                              </Box>
                            </Table>
                      </Flex>
                    </Box>
                    <Box w='100%' h='450'>
                      <Flex direction='column'>
                          <Badge fontSize="1em" colorScheme="red" mb='10px'>NON-AKTIF</Badge>
                            <Table variant='unstyled'>
                                <Tbody>
                                <Box overflowX="auto" maxH='350'>
                                  {filterBmnDataNonaktif.length<1
                                  ? <Tr><Td><Center mb={6}>{statusLoading?<Spinner thickness='4px' speed='0.95s' emptyColor='gray.200' color='blue.500' size='xl'/>:<>Belum ada data BMN.</>}</Center></Td></Tr>
                                  : <>{filterBmnDataNonaktif.map((databmnnonaktif, index) => (
                                    <Tr key={index+1}>
                                        <Td>
                                          <Box minH='150' p="2" boxShadow="lg" borderRadius="lg">
                                          <Stack direction="row">
                                            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={{ base: "10px", xl: "10px" }}>
                                                  <Image boxShadow="lg" borderRadius='10px' boxSize='150px' alt='Gambar Aset BMN' width='150px' height='150px' src={URL_WEB_BACKEND+"/uploads/bmns/foto/"+databmnnonaktif.verifid+'.'+databmnnonaktif.extfoto+'?t='+imageHashToRefresh} />
                                                  <Stack direction="column">
                                                    <Text fontSize={{base: 'xl'}} fontWeight="bold">{databmnnonaktif.nama}</Text>
                                                    <Text mt={2} ms={8} fontSize={{ base: 'md' }} textAlign={'start'} maxW={'4xl'}>
                                                      No. Urut Pendaftaran : {databmnnonaktif.noreg}
                                                    </Text>
                                                    <Text mt={2} ms={8} fontSize={{ base: 'md' }} textAlign={'start'} maxW={'4xl'}>
                                                      Jumlah : {databmnnonaktif.jumlah} {databmnnonaktif.satuan}
                                                    </Text>
                                                    <Text mt={2} ms={8} fontSize={{ base: 'md' }} textAlign={'start'} maxW={'4xl'}>
                                                      Kondisi : {databmnnonaktif.kondisi}
                                                    </Text>
                                                  </Stack>
                                                  <Stack mt='5'>
                                                    <Text mt={2} fontSize={{ base: 'md' }} textAlign={'start'} maxW={'4xl'}>
                                                      Letak : {databmnnonaktif.fullname}
                                                    </Text>
                                                    <Text mt={2} ms={8} fontSize={{ base: 'md' }} textAlign={'start'} maxW={'4xl'}>
                                                      Keterangan Lain : {databmnnonaktif.keterangan}
                                                    </Text>
                                                    <Text mt={2} ms={8} fontSize={{ base: 'md' }} textAlign={'start'} maxW={'4xl'}>
                                                      Tanggal : {databmnnonaktif.tanggal}
                                                    </Text>
                                                    {databmnnonaktif.jenis==='tetap'?
                                                      <Badge maxH='30' fontSize="0.7em" textAlign='center' colorScheme="purple">Aset Tetap</Badge>
                                                      :<Badge maxH='30' fontSize="0.7em" variant="outline" colorScheme="purple">Persediaan</Badge>
                                                    }
                                                    <Stack direction='row'>
                                                      <Button variant="outline" size='sm' colorScheme="red" onClick={()=>{
                                                        setIdSelectedData(databmnnonaktif.id);
                                                        setTimeout(() => {
                                                            showConfExeDialog('Hapus BMN Non-aktif','Yakin lakukan penghapusan BMN : '+databmnnonaktif.nama+' ?',0)
                                                        }, 200);
                                                      }}>HAPUS</Button>
                                                      <Button size='sm' colorScheme="blue" onClick={()=>{
                                                        setIdSelectedData(databmnnonaktif.id);
                                                        setTimeout(() => {
                                                          onSetDetail(filterBmnDataNonaktif[index]);
                                                        }, 200);
                                                      }}>LIHAT DETAIL</Button>
                                                    </Stack>
                                                  </Stack>
                                              </SimpleGrid>
                                          </Stack>
                                        </Box>
                                      </Td>
                                    </Tr>
                                  ))}</>
                                  }
                                  </Box>
                                </Tbody>
                            </Table>
                      </Flex>
                    </Box>          
                </SimpleGrid>
                {isPagination&&allPageNumber>=1?
                  <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                    {currentPageNumber===1?<Button disabled><ArrowBackIcon me='2'/>Sebelum</Button>:<Button onClick={()=>{setCurrentPageNumber(currentPageNumber - 1);getBmn(currentPageNumber - 1);}}><ArrowBackIcon me='2'/>Sebelum</Button>}
                    <Button>{currentPageNumber}</Button>
                    {currentPageNumber===allPageNumber?<Button disabled>Lanjut<ArrowForwardIcon ms='2'/></Button>:<Button onClick={()=>{setCurrentPageNumber(currentPageNumber + 1);getBmn(currentPageNumber + 1);}}>Lanjut<ArrowForwardIcon ms='2'/></Button>}
                  </Grid>:<Text as='b'>akhir dari tabel.</Text>
                }
          </FormControl>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
