// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalFooter,
  useColorModeValue,
  Center,
  useToast,
} from "@chakra-ui/react";
import { CheckIcon, InfoIcon } from '@chakra-ui/icons';
// Custom components
import Card from "components/card/Card.js";
import React, {useState, useEffect} from "react";
import { URL_WEB_BACKEND, decryptData } from "CustomUtil";
import axios from "axios";

export default function EditPassword(props) {
  const authdata = decryptData(localStorage.getItem("authinfositu"));
  const authid = decryptData(localStorage.getItem("authidsitu"));
  const { used, total, ...rest } = props;
  const [userid, setUserid] = useState();
  const [oldpass, setOldPass] = useState('');
  const [pass, setPass] = useState('');
  const [confpass, setConfPass] = useState('');
  const [statusConfUpdatePass, setStatusConfUpdatePass] = useState(false);
  const [titleDialog, setTitleDialog] = useState('title');
  const [descDialog, setDescDialog] = useState('desc');
  const [statusInfoDialog, setStatusInfoDialog] = useState(false);
  const [infoDialogIcon, setInfoDialogIcon] = useState(1);
  const toast = useToast();
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  useEffect(() => {
    getAdmindata();
  },[]);

  const getAdmindata = async () => {
      const profile = await axios.get(URL_WEB_BACKEND+'/profile', {
        headers: {
          'Authorization': 'Bearer '+authdata
        }
      });
      setUserid(profile.data.userid);
  }

  const exeSendnotif = async (title, desc) => {
    await axios.post(URL_WEB_BACKEND+'/notifs',{
      id_user : authid,
      judul: title,
      isi: desc,
      tanggal : new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' '),
      read : 0
    }, {
      headers: {
        'Authorization': 'Bearer '+authdata
      }}).then(res => {
        if(res.status===201){
          showInfoDialog('Berhasil','Password berhasil diperbarui', 0).then(()=>{
            setOldPass('');setPass('');setConfPass('');
          });
        }else{
          let reskey = Object.keys(res.response.data.messages)[0];
          showInfoDialog('Error Mengirim Notifikasi dengan status '+res.response.status, res.response.messages[reskey], 1)
        }
      }).catch(err => {
        let errkey = Object.keys(err.response.data.messages)[0];
        showInfoDialog('Error Mengirim Notifikasi dengan status '+err.response.status, err.response.data.messages[errkey], 1)
      })
  }

  const exeUpdatePass = async () => {
    setStatusConfUpdatePass(false);
    await axios.put(URL_WEB_BACKEND+'/profile/'+userid,{
      passwordlama : oldpass,
      password: pass,
      confpassword: confpass
    }, {
      headers: {
        'Authorization': 'Bearer '+authdata
      }}).then(res => {
        if(res.status===200){
          exeSendnotif('Berhasil Ganti Password','Penggantian password telah berhasil dilakukan');
        }else{
          let reskey = Object.keys(res.response.data.messages)[0];
          showInfoDialog('Error Ubah Password dengan status '+res.response.status, res.response.messages[reskey], 1)
        }
      }).catch(err => {
        let errkey = Object.keys(err.response.data.messages)[0];
        showInfoDialog('Error Ubah Password dengan status '+err.response.status, err.response.data.messages[errkey], 1)
      });
}

const confirmToEditPass = () => {
if(oldpass===''||pass===''||confpass===''){
  toast({
    status: 'error',
    title: 'Proses Gagal',
    description: 'Masih ada kolom yang kosong',
    position: 'bottom-right',
    isClosable: true,
  })
}else{
  if(pass===confpass){
    setStatusConfUpdatePass(true);
  }else{
    toast({
      status: 'error',
      title: 'Proses Gagal',
      description: 'Password baru tidak sama',
      position: 'bottom-right',
      isClosable: true,
    })
  }
}
}

function showInfoDialog (title, desc, infoicon) {
  setTitleDialog(title);setDescDialog(desc);setInfoDialogIcon(infoicon);setStatusInfoDialog(true);
}

const viewInfoDialog = () => {
  return (
  <Modal closeOnOverlayClick={true} isCentered isOpen={statusInfoDialog}>
        <ModalOverlay
          bg='blackAlpha.300'
          backdropFilter='blur(10px) hue-rotate(10deg)'
        />
        <ModalContent>
          <ModalHeader>{titleDialog}</ModalHeader>
          <ModalBody>
            <Center>
              {infoDialogIcon
                ? <InfoIcon w={16} h={16} color="blue.500" />
                : <CheckIcon w={16} h={16} color="green.500" />
              }
            </Center>
            <Center mt={8}>
              <Text fontSize='large'>{descDialog}</Text>
            </Center>
          </ModalBody>
          <ModalFooter>
            <Button onClick = {() => {setStatusInfoDialog(false)}}>OK</Button>
          </ModalFooter>
        </ModalContent>
    </Modal>
  )
}

  return (
    <Card {...rest} mb='20px' align='center' p='20px'>
      <Flex h='100%' direction={{ base: "column", "2xl": "row" }}>
        <Flex direction='column' pe='44px'>
        {viewInfoDialog()}
        <Modal closeOnOverlayClick={false} isCentered isOpen={statusConfUpdatePass}>
            <ModalOverlay
              bg='blackAlpha.300'
              backdropFilter='blur(10px) hue-rotate(10deg)'
            />
            <ModalContent>
              <ModalHeader>Ubah Password</ModalHeader>
              <ModalBody>
                <Text fontSize='large'>Yakin ingin mengubah password?</Text>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme='red' mr={3} onClick = {() => {exeUpdatePass()}}>
                  Iya, Lanjutkan
                </Button>
                <Button onClick = {() => {setStatusConfUpdatePass(false)}}>Batal</Button>
              </ModalFooter>
            </ModalContent>
        </Modal>
          <Text
            color={textColorPrimary}
            fontWeight='bold'
            textAlign='start'
            fontSize='2xl'
            mt={{ base: "5px", "2xl": "5px" }}>
            Ubah Password
          </Text>
          <Text
            color={textColorSecondary}
            fontSize='md'
            my={{ base: "auto", "2xl": "10px" }}
            mx='auto'
            textAlign='start'>
            Perbarui password Anda secara berkala untuk menjaga keamanan akun, dan selalu diingat okey.
          </Text>
          <Flex w='100%' mt={{ base: "20px", "2xl": "20px" }} >
          <FormControl id="pass" isRequired>
            <FormLabel>Password Lama</FormLabel>
            <Input id='pass1' type="password" placeholder="Password Lama" borderRadius="16px" onChange={ (e) => setOldPass(e.target.value) } />
            <FormLabel>Password baru</FormLabel>
            <Input id='pass2' type="password" placeholder="Password Baru" borderRadius="16px" onChange={ (e) => setPass(e.target.value) } />
            <FormLabel>Konfirmasi Password Baru</FormLabel>
            <Input id='pass3' type="password" placeholder="Konfirmasi Password Baru" borderRadius="16px" onChange={ (e) => setConfPass(e.target.value) } />
            <Button
              me='100%'
              mb='50px'
              w='140px'
              minW='140px'
              mt={{ base: "20px", "2xl": "10px" }}
              variant='brand'
              fontWeight='500'
              onClick={confirmToEditPass}>
              Simpan
            </Button>
          </FormControl>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
