import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  Button,
  Stack,
  Spacer,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalFooter,
  Center,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { CheckIcon, InfoIcon, QuestionIcon } from '@chakra-ui/icons';
import React, { useEffect, useState } from "react";
import { URL_WEB_BACKEND, decryptData } from "CustomUtil";
import axios from "axios";
import { convertDateTime } from "CustomUtil";

export default function InformationsTable({refreshing}) {
  const authdata = decryptData(localStorage.getItem("authinfositu"));
  const cekadmin = decryptData(localStorage.getItem("authrolesitu"));
  const [infoData, setInfoData] = useState([]);
  const [judul, setJudul] = useState('');
  const [isiInfo, setIsiInfo] = useState('');
  const [idSelectedData, setIdSelectedData] = useState(0);
  const [titleDialog, setTitleDialog] = useState('title');
  const [descDialog, setDescDialog] = useState('desc');
  const [statusInfoDialog, setStatusInfoDialog] = useState(false);
  const [infoDialogIcon, setInfoDialogIcon] = useState(1);
  const [statusConfExeDialog, setStatusConfExeDialog] = useState(false);
  const [actionConfExeDialog, setActionConfExeDialog] = useState(1);
  const [statusEditDialog, setStatusEditDialog] = useState(false);
  const toast = useToast();

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  useEffect(() => {
    if (infoData.length < 1 || infoData === undefined) {
      getInfos();
    }
  },[]);

  useEffect(() => {
    if (refreshing) {
      getInfos();
    }
  },[refreshing]);

  const getInfos = async () => {
      await axios.get(URL_WEB_BACKEND+'/infos', {
        headers: {
          'Authorization': 'Bearer '+authdata
        }
      }).then((response) => {
        setInfoData(response.data.filter(info => info.id != 0));
      }).catch(err => {
        toast({
          status: 'error',
          title: 'Error status '+err.response.status,
          description: 'Data informasi kosong.',
          position: 'bottom-right',
          isClosable: true,
        })
      });
  }

  const exeEdit = async () =>{
    setStatusEditDialog(false);setStatusConfExeDialog(false);
    if(cekadmin === 'admin'){
      await axios.put(URL_WEB_BACKEND+'/infos/'+idSelectedData,{
        judul: judul,
        isi: isiInfo,
        tanggal: new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' ')
      }, {
        headers: {
          'Authorization': 'Bearer '+authdata
        }}).then(res => {
          if(res.status===200){
            getInfos();
            showInfoDialog('Berhasil', 'Data Berhasil Diperbarui', 0)
          }else{
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error Perbarui dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error Perbarui dengan status '+err.response.status, err.response.data.messages[errkey], 1)
        })
    }else{
      showInfoDialog('Error Pembaruan','Anda tidak memiliki hak akses untuk ini.', 1)
    }
  }

  const exeDelete = async () =>{
    setStatusConfExeDialog(false);
    if(cekadmin === 'admin'){
      await axios.delete(URL_WEB_BACKEND+'/infos/'+idSelectedData, {
        headers: {
          'Authorization': 'Bearer '+authdata
        }}).then(res => {
          if(res.status===200){
            getInfos();
            showInfoDialog('Berhasil', 'Data Berhasil Dihapus', 0)
          }else{
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error Hapus dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error Hapus dengan status '+err.response.status, err.response.data.messages[errkey], 1)
        })
    }else{
      showInfoDialog('Error Hapus','Anda tidak memiliki hak akses untuk ini.', 1)
    }
  }

  function showInfoDialog (title, desc, infoicon) {
    setTitleDialog(title);setDescDialog(desc);setInfoDialogIcon(infoicon);setStatusInfoDialog(true);
  }

  function showConfExeDialog (title, desc, action) {
    setTitleDialog(title);setDescDialog(desc);setStatusConfExeDialog(true);setActionConfExeDialog(action);
  }

  function showEditDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusEditDialog(true);
  }
  
  const viewInfoDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusInfoDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center>
                {infoDialogIcon
                  ? <InfoIcon w={16} h={16} color="blue.500" />
                  : <CheckIcon w={16} h={16} color="green.500" />
                }
              </Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
              <Button onClick = {() => {setStatusInfoDialog(false)}}>OK</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewConfExeDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusConfExeDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center><QuestionIcon w={16} h={16} color="blue.500" /></Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='red' mr={3} onClick = {() => {{
                  if(actionConfExeDialog===1){
                    exeEdit();
                  }else{
                    exeDelete();
                  }
                  }}}>
                    Iya, Lanjutkan
                </Button>
              <Button onClick = {() => {setStatusConfExeDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewEditDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusEditDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                textAlign='start'
                fontSize='2xl'
                mt={{ base: "5px", "2xl": "5px" }}>
                {titleDialog}
              </Text>
              <Text
                color={textColorSecondary}
                fontSize='md'
                my={{ base: "auto", "2xl": "10px" }}
                textAlign='start'>
                {descDialog}
              </Text>  
            </ModalHeader>
            <ModalBody>
              <FormControl id={"info"+idSelectedData} isRequired>
                <FormLabel>Judul</FormLabel>
                <Input id={"info"+idSelectedData+1} placeholder="Masukkan judul informasi" borderRadius="16px" value={judul} onChange={ (e) => setJudul(e.target.value) } />
                <FormLabel>Isi informasi</FormLabel>
                <Textarea minH='200' id={"info"+idSelectedData+2} placeholder="Masukkan isi informasi" borderRadius="16px" value={isiInfo} onChange={ (e) => setIsiInfo(e.target.value) } />
              </FormControl>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick = {() => {{if(judul === '' || isiInfo === ''){toast({
                    status: 'error',
                    title: 'Proses Gagal',
                    description: 'Masih ada kolom yang kosong',
                    position: 'bottom-right',
                    isClosable: true,
                    })}else{showConfExeDialog('Edit Informasi','Simpan pembaruan informasi ini?',1)}}}}>
                    Simpan
                </Button>
              <Button onClick = {() => {setStatusEditDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  return (
    <>
      <Flex
        direction='column'
        w='100%'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex
          align={{ sm: "flex-start", lg: "center" }}
          justify='space-between'
          w='100%'
          px='22px'
          pb='20px'
          mb='10px'
          boxShadow='0px 40px 58px -20px rgba(112, 144, 176, 0.26)'>
        </Flex>
        {viewEditDialog()}
        {viewInfoDialog()}
        {viewConfExeDialog()}
        <Table>
          <Tbody>
            {infoData.length<1
            ? <Tr><Td><Center mb={6}>Belum ada daftar informasi.</Center></Td></Tr>
            : <>{infoData.map((datainfo, index) => (
              <Tr key={index+1}>
                  <Td>
                    <Stack direction="row" p="4" boxShadow="lg" m="2" borderRadius="lg">
                      <Stack direction="row" >
                        <Stack direction="column">
                          <Text fontSize={{base: 'xl'}} fontWeight="bold">{datainfo.judul}</Text>
                          <Text mt={4} ms={8} fontSize={{ base: 'md' }} textAlign={'start'} maxW={'4xl'}>
                            {datainfo.isi}
                          </Text>
                          <Text mt={4} fontSize={14} as='b'>
                            {convertDateTime(datainfo.tanggal)}
                          </Text>
                        </Stack>
                      </Stack>
                      <Spacer />
                      <Stack direction={{ base: 'column', md: 'row' }}>
                        <Button variant="outline" colorScheme="red" onClick={()=>{
                          setIdSelectedData(datainfo.id);
                          setTimeout(() => {
                            showConfExeDialog('Hapus Informasi','Yakin ingin menghapus informasi '+datainfo.judul+' ?',2)
                          }, 200);
                          }}>HAPUS</Button>
                        <Button colorScheme="blue" onClick={()=>{
                          setIdSelectedData(datainfo.id);setJudul(datainfo.judul);setIsiInfo(datainfo.isi);
                          setTimeout(() => {
                            showEditDialog('Edit Informasi','Perbarui informasi agar sesuai.')
                          }, 200);
                          }}>EDIT</Button>
                      </Stack>
                    </Stack>
                  </Td>
              </Tr>
            ))}</>
            }
          </Tbody>
        </Table>
      </Flex>
    </>
  );
};
