// Chakra imports
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import LineChart from "components/charts/LineChart";
import React from "react";

export default function SukelStatistik(props) {
  const { key, dataSukel, kategoriSukel } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  
  return (
    <Card
      justifyContent='center'
      align='center'
      direction='column'
      w='100%'
      mb='0px'>
      <Flex justify='space-between' ps='0px' pe='20px' pt='5px'>
        <Flex align='center' w='100%'>
          <Text color={textColor} fontSize='md' fontWeight='600' mt='4px'>
            Surat Keluar Tahun Ini
          </Text>
        </Flex>
      </Flex>
      <Flex w='100%' flexDirection={{ base: "column", lg: "row" }}>
        <Box minH='260px' w='100%' mt='auto'>
          {key}
          <LineChart
          chartData={[
            {
              name: "Surat Keluar",
              data: dataSukel,
            }
          ]}

          chartOptions={{
            chart: {
              toolbar: {
                show: false,
              },
              dropShadow: {
                enabled: true,
                top: 13,
                left: 0,
                blur: 10,
                opacity: 0.1,
                color: "#4318FF",
              },
            },
            colors: ["#4318FF", "#39B8FF"],
            markers: {
              size: 0,
              colors: "white",
              strokeColors: "#7551FF",
              strokeWidth: 3,
              strokeOpacity: 0.9,
              strokeDashArray: 0,
              fillOpacity: 1,
              discrete: [],
              shape: "circle",
              radius: 2,
              offsetX: 0,
              offsetY: 0,
              showNullDataPoints: true,
            },
            tooltip: {
              theme: "dark",
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "smooth",
              type: "line",
            },
            xaxis: {
              type: "numeric",
              categories: kategoriSukel,
              labels: {
                style: {
                  colors: "#A3AED0",
                  fontSize: "12px",
                  fontWeight: "500",
                },
              },
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
            },
            yaxis: {
              show: false,
            },
            legend: {
              show: false,
            },
            grid: {
              show: false,
              column: {
                color: ["#7551FF", "#39B8FF"],
                opacity: 0.5,
              },
            },
            color: ["#7551FF", "#39B8FF"],
          }}
        />
        </Box>
      </Flex>
    </Card>
  );
}
