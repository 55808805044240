/* eslint-disable */
import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  TableCaption,
  Center,
  Stack,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Spinner,
  Grid,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { HSeparator } from "components/separator/Separator";
import { CheckIcon, InfoIcon, QuestionIcon, ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import React, { useEffect, useState } from "react";
import { URL_WEB_BACKEND, decryptData } from "CustomUtil";
import { saveAs } from "file-saver";
import axios from "axios";
import { convertDateTime } from "CustomUtil";

export default function DocsTable() {
  const authdata = decryptData(localStorage.getItem("authinfositu"));
  const namanya = decryptData(localStorage.getItem("authfullnamesitu"));
  const userid = decryptData(localStorage.getItem("authidsitu"));
  const [docs, setDocs] = useState([]);
  const [idSelectedData, setIdSelectedData] = useState(0);
  const [titleDialog, setTitleDialog] = useState('title');
  const [descDialog, setDescDialog] = useState('desc');
  const [statusInfoDialog, setStatusInfoDialog] = useState(false);
  const [infoDialogIcon, setInfoDialogIcon] = useState(1);
  const [statusConfExeDialog, setStatusConfExeDialog] = useState(false);
  const [actionConfExeDialog, setActionConfExeDialog] = useState(1);
  const [statusAddDialog, setStatusAddDialog] = useState(false);
  const [statusUploading, setStatusUploading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const toast = useToast();

  const [namafilenya, setNamafilenya] = useState('');
  const [filenya, setFilenya] = useState(null);

  // pagination
  const [isPagination, setIsPagination] = useState(true);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [allPageNumber, setAllPageNumber] = useState(0);

  const [searchClue, setSearchClue] = useState('');

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  const textColor = useColorModeValue("secondaryGray.900", "white");

  useEffect(() => {
    getDocs(1);
  },[searchClue]);

  const getDocs = async (pagenya) => {
    setStatusLoading(true);
    if(searchClue===''){
      setDocs([]);setIsPagination(true);
      await axios.get(URL_WEB_BACKEND+'/berbagidoks?page='+pagenya, {
        headers: {
          'Authorization': 'Bearer '+authdata
        }
      }).then((response) => {
        setDocs(response.data.doks);
        setAllPageNumber(response.data.pages);
        setStatusLoading(false);
      }).catch(err => {
        setStatusLoading(false);
        toast({
          status: 'error',
          title: 'Error status '+err.response.status,
          description: 'Data kosong.',
          position: 'bottom-right',
          isClosable: true,
        })
      });
    }else{
      setDocs([]);setIsPagination(false);
      await axios.get(URL_WEB_BACKEND+'/cari/berbagidoks/namafile/'+searchClue.replace(/\s+/g,"_")+'?page='+pagenya, {
        headers: {
          'Authorization': 'Bearer '+authdata
        }
      }).then((response) => {
        setIsPagination(true);
        setDocs(response.data.doks);
        setAllPageNumber(response.data.pages);
        setStatusLoading(false);
      }).catch(err => {
        setStatusLoading(false);
        toast({
          status: 'error',
          title: 'Error status '+err.response.status,
          description: 'Tak ada data yang dicari.',
          position: 'bottom-right',
          isClosable: true,
        })
      });
    }
  }

  const exeNotifBeforeDownload = async (idowner, namafile, title, desc) => {
    await axios.post(URL_WEB_BACKEND+'/notifs',{
      id_user : idowner,
      judul: title,
      isi: desc,
      tanggal : new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' '),
      read : 0
    }, {
      headers: {
        'Authorization': 'Bearer '+authdata
      }}).then(res => {
        if(res.status===201){
          saveAs(URL_WEB_BACKEND+"/uploads/berbagidoks/"+idowner+"/"+namafile, namafile);
        }else{
          let reskey = Object.keys(res.response.data.messages)[0];
          showInfoDialog('Error Mengirim Notifikasi dengan status '+res.response.status, res.response.messages[reskey], 1)
        }
      }).catch(err => {
        let errkey = Object.keys(err.response.data.messages)[0];
        showInfoDialog('Error Mengirim Notifikasi dengan status '+err.response.status, err.response.data.messages[errkey], 1)
      })
  }

  const exeAdd = async () => {
    setStatusUploading(true);setStatusAddDialog(false);setStatusConfExeDialog(false);
    const formData = new FormData();
    formData.append('namafile', namafilenya)
    formData.append('id_user', userid)
    formData.append('tanggal', new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' '))
    formData.append('filenya', filenya)
    await axios.post(URL_WEB_BACKEND+'/berbagidoks', formData, {
      headers: {
        'Authorization': 'Bearer '+authdata
      }}).then(res => {
          setStatusUploading(false);
        if(res.status===201){
          getDocs(1);
          showInfoDialog('Berhasil','Dokumen berhasil diunggah', 0);
        }else{
          let reskey = Object.keys(res.response.data.messages)[0];
          showInfoDialog('Error Unggah Dokumen dengan status '+res.response.status, res.response.messages[reskey], 1)
        }
      }).catch(err => {
        setStatusUploading(false);
        let errkey = Object.keys(err.response.data.messages)[0];
        showInfoDialog('Error Unggah Dokumen dengan status '+err.response.status, err.response.data.messages[errkey], 1)
      })
  }
  
  const exeDelete = async () =>{
    setStatusConfExeDialog(false);
      await axios.delete(URL_WEB_BACKEND+'/berbagidoks/'+idSelectedData, {
        headers: {
          'Authorization': 'Bearer '+authdata
        }}).then(res => {
          if(res.status===200){
            getDocs(currentPageNumber);
            showInfoDialog('Berhasil', 'Data Berhasil Dihapus', 0)
          }else{
            let reskey = Object.keys(res.response.data.messages)[0];
            showInfoDialog('Error Hapus dengan status '+res.response.status, res.response.messages[reskey], 1)
          }
        }).catch(err => {
          let errkey = Object.keys(err.response.data.messages)[0];
          showInfoDialog('Error Hapus dengan status '+err.response.status, err.response.data.messages[errkey], 1)
        })
  }

  function showInfoDialog (title, desc, infoicon) {
    setTitleDialog(title);setDescDialog(desc);setInfoDialogIcon(infoicon);setStatusInfoDialog(true);
  }

  function showConfExeDialog (title, desc, action) {
    setTitleDialog(title);setDescDialog(desc);setStatusConfExeDialog(true);setActionConfExeDialog(action);
  }

  function showAddDialog (title, desc) {
    setTitleDialog(title);setDescDialog(desc);setStatusAddDialog(true);
  }

  const viewInfoDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusInfoDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center>
                {infoDialogIcon
                  ? <InfoIcon w={16} h={16} color="blue.500" />
                  : <CheckIcon w={16} h={16} color="green.500" />
                }
              </Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
              <Button onClick = {() => {setStatusInfoDialog(false)}}>OK</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewConfExeDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusConfExeDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>{titleDialog}</ModalHeader>
            <ModalBody>
              <Center><QuestionIcon w={16} h={16} color="blue.500" /></Center>
              <Center mt={8}>
                <Text fontSize='large'>{descDialog}</Text>
              </Center>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='red' mr={3} onClick = {() => {{
                  if(actionConfExeDialog===1){
                    exeAdd();
                  }else{
                    exeDelete();
                  }
                  }}}>
                    Iya, Lanjutkan
                </Button>
              <Button onClick = {() => {setStatusConfExeDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewAddDialog = () => {
    return (
    <Modal closeOnOverlayClick={true} isCentered isOpen={statusAddDialog}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <ModalHeader>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                textAlign='start'
                fontSize='2xl'
                mt={{ base: "5px", "2xl": "5px" }}>
                {titleDialog}
              </Text>
              <Text
                color={textColorSecondary}
                fontSize='md'
                my={{ base: "auto", "2xl": "10px" }}
                textAlign='start'>
                {descDialog}
              </Text>  
            </ModalHeader>
            <ModalBody>
              <FormControl id={"uploadfile"+idSelectedData} isRequired>
                <FormLabel>Pilih Dokumen (Maks 25 Mb)</FormLabel>
                <Input variant='main' h='100px' mb='5' border='2px dashed' type='file' id={"uploadfile"+idSelectedData+1} placeholder="Pilih file" borderRadius="16px" onChange={ (e) => setFilenya(e.target.files[0]) } /><HSeparator/>
                <FormLabel mt='5'>Beri Nama Dokumennya</FormLabel>
                <Input id={"uploadfile"+idSelectedData+2} placeholder="Masukkan nama dokumen" borderRadius="16px" value={namafilenya} onChange={ (e) => setNamafilenya(e.target.value) } />
              </FormControl>
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick = {() => {{if(namafilenya === '' || filenya === null || userid === null || userid === ''){toast({
                    status: 'error',
                    title: 'Proses Gagal',
                    description: 'Masih ada kolom yang kosong',
                    position: 'bottom-right',
                    isClosable: true,
                    });}else{
                      showConfExeDialog('Unggah Dokumen','Yakin unggah dokumen ini?',1);
                    }}}}>
                    Unggah
                </Button>
              <Button onClick = {() => {setNamafilenya('');setFilenya(null);setStatusAddDialog(false)}}>Batal</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
  }

  const viewUploadingDialog = () => {
    return (
      <Modal closeOnOverlayClick={false} isCentered isOpen={statusUploading}>
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(10deg)'
          />
          <ModalContent>
            <Center pb={5} pt={5}>
              <Spinner
                thickness='4px'
                speed='0.95s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
            </Center>
            <Center pb={5} pt={5}>
            <Text>Mengunggah...</Text>
            </Center>
          </ModalContent>
      </Modal>
    )
  }

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        {viewInfoDialog()}{viewConfExeDialog()}{viewAddDialog()}{viewUploadingDialog()}
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Daftar Dokumen
        </Text>
        <Button
          variant='action'
          onClick={()=>{
            setIdSelectedData('');setNamafilenya('');setFilenya(null);
            setTimeout(() => {
              showAddDialog('Unggah Dokumen Anda','Bagikan dokumen pdf, word, excel, powepoint, dan zip Anda dengan pengguna lain.')
            }, 200);
          }}>
          <Text
            fontSize='lg'
            as='b'>
            +  Unggah Dokumen
          </Text>
        </Button>
      </Flex>
      <Table minH='600' variant='unstyled'>
        <TableCaption>
        Pemilik dokumen yang Anda download akan dikirimi notifikasi<br/><br/><br/>
        {isPagination&&allPageNumber>=1?
            <Grid templateColumns="repeat(3, 1fr)" gap={2}>
              {currentPageNumber===1?<Button disabled><ArrowBackIcon me='2'/>Sebelum</Button>:<Button onClick={()=>{setCurrentPageNumber(currentPageNumber-1);getDocs(currentPageNumber-1);}}><ArrowBackIcon me='2'/>Sebelum</Button>}
              <Button>{currentPageNumber}</Button>
              {currentPageNumber===allPageNumber?<Button disabled>Lanjut<ArrowForwardIcon ms='2'/></Button>:<Button onClick={()=>{setCurrentPageNumber(currentPageNumber+1);getDocs(currentPageNumber+1);}}>Lanjut<ArrowForwardIcon ms='2'/></Button>}
            </Grid>
            :<Text as='b'>akhir dari tabel.</Text>
        }
        </TableCaption>
        <Thead><Tr>
        <Td><Input variant='filled' id={"search1"+searchClue} placeholder="Cari Berdasarkan Nama Dokumen" borderRadius="16px" value={searchClue} onChange={ (e) => setSearchClue(e.target.value) } /></Td>
        </Tr></Thead>
        <Tbody>
        {docs.length<1
          ? <Tr><Td><Center mb={6}>{statusLoading?<Spinner thickness='4px' speed='0.95s' emptyColor='gray.200' color='blue.500' size='xl'/>:<>Belum ada dokumen diunggah.</>}</Center></Td></Tr>
          : <>
              {docs.map((datadokumen, index) => (
                <Tr key={index}>
                  <Td>
                  <Card direction="row" p="4" boxShadow="md" m="2" borderRadius="lg">
                    <Text as='b'>Nama Dokumen</Text><HSeparator/>{datadokumen.namafile}<br/><br/>
                    <Text as='b'>Diunggah oleh</Text><HSeparator/>{datadokumen.fullname}<br/><br/>
                    <Text as='b'>Diunggah pada</Text><HSeparator/>{convertDateTime(datadokumen.tanggal)}<br/><br/>
                    <Stack direction={{ base: 'column', md: 'row' }}>
                        {datadokumen.id_user===userid ?
                          <Button variant="outline" colorScheme="red" onClick={()=>{
                            setIdSelectedData(datadokumen.id);
                            setTimeout(() => {
                              showConfExeDialog('Hapus Dokumen','Yakin ingin menghapus '+datadokumen.namafile+' ?',0)
                            }, 200);
                            }}>HAPUS</Button>:<Button variant="outline" colorScheme="red" onClick={()=>{showInfoDialog('Error Hapus','Ow, tidak bisa. JANGAN MAIN MAIN!', 1)}} disabled>HAPUS</Button>}
                        <Button colorScheme="blue" onClick={()=>{
                            if(datadokumen.id_user===userid){
                              saveAs(URL_WEB_BACKEND+"/uploads/berbagidoks/"+datadokumen.id_user+"/"+datadokumen.namafile, datadokumen.namafile);
                            }else{
                              exeNotifBeforeDownload(datadokumen.id_user, datadokumen.namafile, 'Dokumen Anda Diunduh', 'Dokumen Anda '+datadokumen.namafile+' telah diunduh oleh '+namanya);
                            }
                        }}>UNDUH</Button>
                      </Stack>
                      </Card>
                  </Td>
                </Tr>
              ))}
            </>
        }
        </Tbody>
      </Table>
    </Card>
  );
}
